import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import {useParams} from "react-router-dom";
import StyledModal from "../../StyledComponents/StyledModal";
import StyledSelect from "../../StyledComponents/StyledSelect";
import dbDate from "../../Utilities/dbDate";
import DateInput from "../../StyledComponents/DateInput";
import {Stack} from "@mui/material";


function AddMemberMealBlockade(props) {
    const title = 'Dodaj Blokadę Posiłku';
    const description = 'Tutaj możesz zablokować generowanie posiłku dla uczestnika.';

    const [user] = useContext(AuthContext);
    const params = useParams();

    const [facilityMeal, setFacilityMeal] = useState('');
    const [facilityMeals, setFacilityMeals] = useState([]);
    const [date, setDate] = useState(null);

    function _send() {
        const formData = {
            facility_meal_id: facilityMeal.id,
            date: dbDate(date)
        };
        fetch(`/api/member/${props.data}/meal/blockade`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchData() {
            await fetch(`/api/facility/${params.id}/meal`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setFacilityMeals(data.meals.map((i) => {return {id: i.id, name: i.meal_type}}));
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user]);

    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss}>
            <Stack spacing={2}>
                <StyledSelect
                    name='meal_type'
                    label='Posiłek'
                    value={facilityMeal}
                    onChange={(e, v) => setFacilityMeal(v)}
                    items={facilityMeals}
                    sx={''}
                />
                <DateInput label={'Data'} value={date} onChange={(e) => setDate(e)} sx={''}/>
            </Stack>
        </StyledModal>
    );
}

export default AddMemberMealBlockade;
import React, {useContext, useEffect, useState} from "react";
import {Alert, Button, Grid} from "@mui/material";
import Title from "../../Component/Title";
import dbMonth from "../../Utilities/dbMonth";
import {useParams} from "react-router-dom";
import MonthPicker from "../../Component/MonthPicker";
import AuthContext from "../../services/AuthContext";
import {DetailsList, SelectionMode} from "@fluentui/react";
import MDButton from "../../components/MDButton";
import GenerateCalendar from "../../Panels/Calendar/GenerateCalendar";
import {Celebration, Check, Close, History, Schedule} from "@mui/icons-material";
import dbDate from "../../Utilities/dbDate";
import LinearProgress from "@mui/material/LinearProgress";

function Calendar() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [month, setMonth] = useState(dbMonth(new Date()));
    const [dates, setDates] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [generate, setGenerate] = useState(false);
    const [isRecalculating, setIsRecalculating] = useState(false);
    const [flash, setFlash] = useState('');

    function _recalculate(item) {
        setIsRecalculating(true);
        setFlash(<Alert severity="info">Liczę {item.date}...</Alert>);
        fetch(`/api/facility/${params.id}/calendar/${dbDate(item.date)}/regenerate`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                setIsRecalculating(false);
                setFlash(<Alert severity="success">Przeliczono ponownie {item.date}</Alert>);
            })
            .catch(err => {
                setFlash(<Alert severity="error">Nie udało się przeliczyć {item.date}</Alert>);
                setIsRecalculating(false);
                console.log(err)
            })
    }

    function _switchDay(id, method) {
        fetch(`/api/facility/${params.id}/calendar/${id}/${method}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                setRefresh(refresh + 1);
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/calendar/${dbMonth(month)}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => { setDates(data) })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, month, params.id, refresh]);

    const subtitle = (<Grid container justifyContent={'space-between'} mb={3}>
        <Button onClick={() => setGenerate(true)}><Schedule /> Generuj Kalendarz</Button>
        <Grid item lg={2}>
            <MonthPicker value={month} onChange={(e) => setMonth(e)}/>
        </Grid>
    </Grid>);

    const columns = [
        {
            key: 'date',
            name: 'Data',
            ariaLabel: 'Data',
            fieldName: 'date',
            minWidth: 100,
            isRowHeader: true
        },
        {
            key: 'recalculate',
            name: 'Przelicz',
            ariaLabel: 'Przelicz',
            fieldName: 'recalculate',
            minWidth: 80,
            onRender: item => (<MDButton variant={'text'} color={'warning'} disabled={isRecalculating} onClick={() => _recalculate(item)}><History /></MDButton>)
        },
        {
            key: 'holiday',
            name: 'Święto',
            ariaLabel: 'Święto',
            fieldName: 'holiday',
            minWidth: 80,
            onRender: item => item.holiday ? (<MDButton variant={'contained'} color={'warning'}><Celebration /></MDButton>) : ''
        },
        {
            key: 'blocked',
            name: 'Otwarte',
            ariaLabel: 'Otwarte',
            fieldName: 'blokada',
            minWidth: 80,
            onRender: item => item.blocked ? (<MDButton variant={'contained'} color={'error'} onDoubleClick={() => _switchDay(item.id, 'open')}> <Close /></MDButton>) : <MDButton variant={'text'} color={'success'} onClick={() => _switchDay(item.id, 'close')}> <Check /></MDButton>
        }
    ];

    return (<>
            <Title title={'Kalendarz'} subtitle={subtitle}/>
            {flash}
            {isRecalculating && <LinearProgress />}
            <DetailsList selectionMode={SelectionMode.none} items={dates} columns={columns} />
            <GenerateCalendar isOpen={generate} dismiss={() => setGenerate(false)} onSuccess={() => setRefresh(refresh + 1)} />
        </>
    )
}
export default Calendar;
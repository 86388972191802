import React, {useContext, useEffect, useState} from "react";
import MDButton from "../../../../components/MDButton";
import MDBox from "../../../../components/MDBox";
import {Link, useOutletContext, useParams} from "react-router-dom";
import MDTypography from "../../../../components/MDTypography";
import {DetailsList, SelectionMode} from "@fluentui/react";
import ActionButton from "../../../../StyledComponents/ActionButton";
import {Add, Delete, Edit, Female, Male, Man, Phonelink, Woman} from "@mui/icons-material";
import AuthContext from "../../../../services/AuthContext";
import AddAllowed from "../../../../Panels/Member/AddAllowed";
import EditAllowed from "../../../../Panels/Member/EditAllowed";
import Icon from "@mui/material/Icon";
import EditMember from "../../../../Panels/Member/EditMember";

function MemberData() {
    const {data, setSummary, refreshMember} = useOutletContext();
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [addAllowed, setAddAllowed] = useState(false);
    const [editAllowed, setEditAllowed] = useState(false);
    const [editMember, setEditMember] = useState(false);
    const allowed = data?.allowed !== undefined ? data.allowed : [];
    const meals = data?.meals !== undefined ? data.meals : [];

    useEffect(() => {
        setSummary(null);
    }, [])
    function _delete(id) {
        fetch(`/api/member/allowed/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                refreshMember();
            })
            .catch(err => { console.log(err) })
    }

    function _addMeal(facilityMeal) {
        const formData = {
            facility_meal_id: facilityMeal,
        };
        fetch(`/api/member/${params.member}/meal`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                refreshMember();
            })
            .catch(err => { console.log(err) })
    }

    function _deleteMeal(id) {
        fetch(`/api/member/${params.member}/meal/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                refreshMember();
            })
            .catch(err => { console.log(err) })
    }

    function _makeButtons(item) {
        return (<div>
            <ActionButton color='success' icon={<Edit />} onClick={() => setEditAllowed(item)} />
            <ActionButton color='error' icon={<Delete />} onDoubleClick={() => _delete(item.id)} />
        </div>);
    }

    function _mealButton(item) {
        if (item.blocked) {
            return (<MDButton fullWidth color='error' disabled>{item.name}</MDButton>);
        }
        return item.id ?
            (<MDButton fullWidth color='success' onDoubleClick={() => _deleteMeal(item.id)}>{item.name}</MDButton>) :
            (<MDButton fullWidth color='secondary' onClick={() => _addMeal(item.facility_meal_id)}>{item.name}</MDButton>);
    }

    const columnsMeals = [
        {
            key: 'meals',
            name: 'Posiłek',
            ariaLabel: 'Posiłek',
            fieldName: 'meals',
            minWidth: 200,
            onRender: _mealButton
        }
    ];

    const columnsAllowed = [
        {
            key: 'fullname',
            name: 'Imię i Nazwisko',
            ariaLabel: 'Imię i Nazwisko',
            fieldName: 'fullname',
            minWidth: 150,
            isResizable: true,
            onRender: item => item.active == 1 ? <><Phonelink /> {item.fullname}</> : item.fullname
        },
        {
            key: 'document',
            name: 'Dokument',
            ariaLabel: 'Dokument',
            fieldName: 'document',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'kinship',
            name: 'Pokrewieństwo',
            ariaLabel: 'Pokrewieństwo',
            fieldName: 'kinship',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'contact',
            name: 'Kontakt',
            ariaLabel: 'Kontakt',
            fieldName: 'contact',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'valid',
            name: 'Czasowe do',
            ariaLabel: 'Czasowe do',
            fieldName: 'valid',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 80,
            onRender: _makeButtons
        }
    ];
    const iconBox = (color, icon) => (<MDBox
        display="grid"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="4rem"
        height="4rem"
        shadow="md"
        borderRadius="lg"
        variant="gradient"
    >
        <Icon fontSize="large">{icon}</Icon>
    </MDBox>);
    function _calculateAge(birthday) { // birthday is a date
        var ageDifMs = Date.now() - birthday.getTime();
        var ageDate = new Date(ageDifMs); // miliseconds from epoch
        return Math.abs(ageDate.getUTCFullYear() - 1970);
    }
    const dList = (title, desc, target = '') => {
        if (desc !== undefined && desc !== null && desc !== '') {
            target = target !== null && target !== '' ? ' -> ' + target : '';
            return (<>
                    <dt>{title}</dt>
                    <dd>{desc}{target}</dd>
                </>
            );
        }
        return '';
    }
    const member = data?.member[0];
    const avatar = member?.gender === 'F' ? iconBox('primary', <Woman />) : iconBox('info', <Man />);
    const age = _calculateAge(new Date(member?.birthday));
    const localiseAge = age > 4 ? age + ' lat' : age + ' lata';
    const department = member?.department !== null ? <Link to={`/facility/${member?.facility_id}/department/${member?.department_id}`}><MDButton color={'info'} variant={'text'} fullWidth>Grupa {member?.department}</MDButton></Link> : 'Archiwalny';
    return (<MDBox display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'}>
        <MDBox>
            <MDBox justifyContent={'space-between'} display={'flex'} flexWrap={'wrap'}>
                {avatar}

                <div style={{textAlign: 'right'}}>
                <MDTypography fontWeight={'bold'}>{department}</MDTypography>
                <MDTypography fontWeight={'bold'}>{localiseAge}</MDTypography>
                </div>
                <MDButton color={'success'} startIcon={<Edit/>} onClick={() => setEditMember(member)}> Edytuj</MDButton>
            </MDBox>
            <MDBox justifyContent={'space-around'} display={'flex'} flexWrap={'wrap'}>
                <dl>
                    {dList('Deklarowany Pobyt', member?.attend_from, member?.attend_to)}
                    {dList('Narodowość', member?.nationality)}
                    {dList('Dieta', member?.diet)}
                    {dList('Urodziny', member?.birthday)}
                    {dList('Pesel', member?.personal_id)}
                    {dList('Rabat', member?.discount + '%')}
                    {dList('Konto Indywidualne', member?.personal_account)}
                    {dList('Rekrutacja', member?.active_from, member?.active_to)}
                    {dList('Notatka', member?.note)}
                </dl>
                <DetailsList selectionMode={SelectionMode.none} items={meals} columns={columnsMeals} />
            </MDBox>
        </MDBox>
        <MDBox>
            <MDTypography variant={'h5'} fontWeight={'light'}>Upoważnienia Odbioru</MDTypography>
            <MDButton color={'info'} variant={'text'} onClick={() => setAddAllowed(params.member)} ><Add /> Dodaj Upoważnienie</MDButton>
            <DetailsList selectionMode={SelectionMode.none} items={allowed} columns={columnsAllowed} />
        </MDBox>
        <AddAllowed data={addAllowed} dismiss={() => setAddAllowed(false)} onSuccess={refreshMember} />
        <EditAllowed data={editAllowed} dismiss={() => setEditAllowed(false)} onSuccess={refreshMember} />
        <EditMember data={editMember} dismiss={() => setEditMember(false)}
                    onSuccess={refreshMember}/>
    </MDBox>);
}

export default MemberData;
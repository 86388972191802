import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import MDInput from "../../components/MDInput";
import {FormControlLabel, InputAdornment, Switch} from "@mui/material";
import StyledSelect from "../../StyledComponents/StyledSelect";
import {useParams} from "react-router-dom";


function AddBillingSplitMeal(props) {
    const params = useParams();
    const title = 'Podziel rozliczenia po posiłku';
    const description = 'Tutaj możesz dodać regułę podzialu rozliczenia rachunku dziecka po konkretnym posiłku';

    const [user] = useContext(AuthContext);
    const [facilityMeal, setFacilityMeal] = useState(null);
    const [discount, setDiscount] = useState(0);
    const [meals, setMeals] = useState([]);

    useEffect(() => {

        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/meal`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setMeals(data.meals.map((item) => { return {id: item.id, name: item.meal_type}; }));
                })
                .catch(err => {
                    console.log(err)
                })
        }
        fetchData();
        return () => abortController.abort();
    }, [user]);
    function _send() {
        const formData = {
            facility_meal_id: facilityMeal.id,
            discount: discount
        };
        fetch(`/api/member/billing/split/${props.data.id}/meal`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess(data);
                props.dismiss();
            })
            .catch(err => { console.log(err) })
    }

    return (<StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss}>
                <StyledSelect
                    name='meal_type'
                    label='Posiłek'
                    value={facilityMeal}
                    onChange={(e, v) => setFacilityMeal(v)}
                    items={meals}
                />
                <MDInput
                    onChange={(e) => setDiscount(e.target.value)}
                    label='Rabat'
                    fullWidth
                    value={discount}
                    type={'number'}
                    inputProps={{min: 0, max: 100}}
                    InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
                    disabled
                />
        </StyledModal>);
}
export default AddBillingSplitMeal;
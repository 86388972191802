import * as React from "react";
import {useContext, useState} from "react";
import AuthContext from "../../../services/AuthContext";
import Title from "../../../Component/Title";
import {Alert} from "@mui/material";
import LinearProgress from "@mui/material/LinearProgress";
import MDButton from "../../../components/MDButton";

function ImportPayment() {
    const [user] = useContext(AuthContext);
    const [zip, setZip] = useState(null);
    const [result, setResult] = useState(null);

    function _submitZip(e) {
        e.preventDefault();
        if (zip !== null) {
            setResult('loading');
            const data = new FormData();
            data.append('file', zip);
            fetch("/api/import/income/mt940zip", {
                method: 'POST',
                body: data,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => { setResult(data) })
                .catch(err => { console.log(err) })
        }
    }

    function messageBar(result) {

        if (result === null) {
            return '';
        }
        else if (typeof result === 'string') {
            return result === 'loading' ? (<div><LinearProgress /></div>) : <Alert severity="info">{result}</Alert>;
        }
        const failLog = result.failLog.map( (item, idx) =>
            (<Alert severity="error">{item}</Alert>));
        return (<div>
            {result.imported > 0 && <Alert severity="success">Zaimportowano {result.imported} transakcji</Alert>}
            {result.partial > 0 && <Alert severity="warning">Zaimportowano {result.imported} transakcji bez przypisania</Alert>}
            {result.skipped > 0 && <Alert severity="info">Pominięto {result.skipped} transakcji</Alert>}
            {result.failed > 0 &&
                <Alert severity="error">
                    Wystąpił błąd w {result.failed} transakcjach
                </Alert>
            }
            {failLog}
        </div>)
    }

    return (
        <>
            <Title title={'Import masowych transakcji z banku'} />
            {messageBar(result)}
            <form>
                <input type="file" name="zip" onChange={(e) => setZip(e.target.files[0])}/>
                <MDButton variant={'contained'} color={"info"} type={"submit"} onClick={_submitZip}>Importuj Raport ZIP Txt (CSV)</MDButton>
            </form>
        </>
    );
}

export default ImportPayment;
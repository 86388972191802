import React, {useContext, useEffect, useState} from "react";
import Title from "../../Component/Title";
import {useParams} from "react-router-dom";
import AuthContext from "../../services/AuthContext";
import TVScreen from "../../Layout/TVScreen";

function TV() {
    const params = useParams();
    const [user] = useContext(AuthContext);

    useEffect(() => {
        var timer = setInterval(() => window.location.reload(true), 30*60000 )
        return function cleanup() {
            clearInterval(timer)
        }

    });

    return (<TVScreen />)
}

export default TV;
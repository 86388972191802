import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import MDInput from "../../components/MDInput";
import {FormControlLabel, Stack, Switch} from "@mui/material";

function EditDepartment(props) {
    const title = 'Edytuj Wydział';
    const description = 'Tutaj możesz edytować wydział swojej placówki.';

    const [user] = useContext(AuthContext);
    const [name, setName] = useState('');
    const [year, setYear] = useState(new Date().getFullYear());
    const [sorting, setSorting] = useState(0);
    const [location, setLocation] = useState(1);
    const [active, setActive] = useState(1);
    const [billingSwap, setBillingSwap] = useState(0);
    const [billingName, setBillingName] = useState('');
    const [billingAddress, setBillingAddress] = useState('');
    const [billingAccount, setBillingAccount] = useState('');
    const [billingVat, setBillingVat] = useState('');

    useEffect(() => {
        if (!billingSwap) {
            setBillingVat('');
            setBillingAddress('');
            setBillingAccount('');
            setBillingName('');
        }
    }, [billingSwap]);
    function _send() {
        const formData = {
            name: name,
            year: year,
            sorting: sorting,
            location: location,
            active: active,
            billing_name: billingName,
            billing_address: billingAddress,
            billing_account: billingAccount,
            billing_vat: billingVat
        };
        fetch(`/api/facility/${props.data.facility_id}/department/${props.data.id}`, {
            method: 'PUT',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        setName(props.data.name);
        setYear(props.data.year);
        setSorting(props.data.sorting);
        setLocation(props.data.location);
        setActive(props.data.active);
        setBillingSwap(props.data.billing_vat !== null ? 1 : 0);
        setBillingName(props.data.billing_name);
        setBillingAddress(props.data.billing_address);
        setBillingAccount(props.data.billing_account);
        setBillingVat(props.data.billing_vat);
    }, [props.data]);

    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss}
                     actionLabel='Edytuj'>
            <MDInput
                onChange={(e) => setName(e.target.value)}
                value={name}
                label='Nazwa'
                fullWidth
                sx={{marginBottom: 2}}
            />
            <MDInput
                onChange={(e) => setYear(e.target.value)}
                value={year}
                label='Rocznik'
                fullWidth
                sx={{marginBottom: 2}}
            />
            <MDInput
                onChange={(e) => setSorting(e.target.value)}
                value={sorting}
                label='Kolejność'
                type={'number'}
                fullWidth
                sx={{marginBottom: 2}}
            />
            <MDInput
                onChange={(e) => setLocation(e.target.value)}
                value={location}
                label='Szatnia'
                type={'number'}
                fullWidth
                sx={{marginBottom: 2}}
            />
            <Stack direction={'row'} spacing={1}>
            <FormControlLabel
                control={
                    <Switch
                        checked={active}
                        onChange={(event) => setActive(event.target.checked)}
                        value="1"
                    />
                }
                label="Aktywna"
            />
            <FormControlLabel
                control={
                    <Switch
                        checked={billingSwap}
                        onChange={(event) => setBillingSwap(event.target.checked)}
                        value="1"
                    />
                }
                label="Inny Sprzedawca"
            />
            </Stack>
            {billingSwap && <Stack spacing={2}>
                <MDInput
                    onChange={(e) => setBillingName(e.target.value)}
                    value={billingName}
                    label='Sprzedawca'
                    fullWidth
                />
                <MDInput
                    onChange={(e) => setBillingAddress(e.target.value)}
                    value={billingAddress}
                    label='Adres'
                    fullWidth
                />
                <MDInput
                    onChange={(e) => setBillingAccount(e.target.value)}
                    value={billingAccount}
                    label='Numer Konta'
                    fullWidth
                />
                <MDInput
                    onChange={(e) => setBillingVat(e.target.value)}
                    value={billingVat}
                    label='NIP'
                    fullWidth
                />
            </Stack>
        }
        </StyledModal>
    );
}

export default EditDepartment;
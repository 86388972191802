import React from "react";
import {Grid} from "@mui/material";
import Title from "../../Component/Title";
import {Outlet} from "react-router-dom";
import Subnavigation from "../../Component/Subnavigation";

function Settings() {

    const subtitle = (<Grid container justifyContent={'space-between'} mb={3}>
        <Subnavigation items={[
            {to: 'general', name: 'Ogólne', color: 'info'},
            {to: 'meals', name: 'Posiłki', color: 'success'},
            {to: 'templates', name: 'Dokumenty', color: 'primary'},
            {to: 'accounts', name: 'Masowe Płatności', color: 'warning'}
        ]} defaultActive={'general'} />
    </Grid>);

    return (<>
            <Title title={'Ustawienia'} subtitle={subtitle}/>
            <Outlet />
        </>
    )
}

export default Settings;
import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import {useParams} from "react-router-dom";
import StyledModal from "../../StyledComponents/StyledModal";
import StyledSelect from "../../StyledComponents/StyledSelect";

function GenerateDocument(props) {
    const title = 'Generuj Dokument';
    const description = 'Tutaj możesz wygenerować dokumenty z załączonych wzorów.';

    const [user] = useContext(AuthContext);
    const params = useParams();
    const [template, setTemplate] = useState(null);
    const [templates, setTemplates] = useState([]);

    function _send() {
        if (template !== null) {
            fetch(`/api/facility/member/${props.add}/template/${template.id}`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(() => {
                    props.onSuccess();
                    props.dismiss();
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }

    useEffect(() => {
        setTemplate(null);
        if (props.add) {
            const abortController = new AbortController();
            async function fetchData() {
                await fetch(`/api/facility/${params.id}/template`, {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                        'Authorization': 'Bearer ' + user.token
                    }
                }).then(res => res.json())
                    .then(data => setTemplates(data.templates.map((item) => {return {id: item.id, name: item.type}})))
                    .catch(err => {
                        console.log(err)
                    })
            }

            fetchData();
            return () => abortController.abort();
        }
    }, [props.add])

    return (
        <StyledModal isOpen={props.add} title={title} description={description} send={_send} dismiss={props.dismiss}>
            <StyledSelect
                name='type'
                label='Wzór Dokumentu'
                value={template}
                onChange={(e, v) => setTemplate(v)}
                items={templates}
                required
            />
        </StyledModal>
    );
}

export default GenerateDocument;
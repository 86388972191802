import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import StyledSelect from "../../StyledComponents/StyledSelect";
import MDInput from "../../components/MDInput";


function AddTag(props) {
    const title = 'Dodaj Tag';
    const description = 'Tutaj możesz dodać oznaczenie kosztu, które będzie dostępne dla wszystkich twoich placówek. Użycie przychodu pozwala by koszty oznaczone tym tagiem wykorzystywały wskazane źródło przychodu.';

    const [user] = useContext(AuthContext);
    const [tag, setTag] = useState('');
    const [incomeType, setIncomeType] = useState(null);

    function _send() {
        const formData = {
            tag: tag,
            income_type_target: incomeType.id
        };
        fetch(`/api/tag`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess(data);
            })
            .catch(err => { console.log(err) })
    }

    const [incomeTypeList, setIncomeTypeList] = useState([]);
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/income_type`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => { setIncomeTypeList(data) })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user]);


    return (<StyledModal isOpen={props.isOpen} title={title} description={description} send={_send} dismiss={props.dismiss}>
                <MDInput
                    onChange={(e) => setTag(e.target.value)}
                    label='Tag'
                    value={tag}
                    variant="standard"
                    fullWidth
                    sx={{marginBottom: 2}}
                />
                <StyledSelect
                    name='income_type'
                    label='Użycie przychdu'
                    value={incomeType}
                    onChange={(e, v) => setIncomeType(v)}
                    items={incomeTypeList}
                    fullWidth
                    sx={{marginBottom: 2}}
                />
        </StyledModal>);
}
export default AddTag;
import React from "react";
import {Link} from "react-router-dom";
import MDBox from "../MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "../MDTypography";
import Card from "@mui/material/Card";
import InlineValueCard from "../../Component/Card/InlineValueCard";
import {AccountBalance, EscalatorWarning, Euro, ExpandMore, FoodBank, Group, Receipt, Store} from "@mui/icons-material";
import GroupMail from "../../Component/GroupMail";
import currency from "currency.js";
import {Skeleton} from "@mui/material";

function Facility(props) {
  const color = props.color;
  const url = `/facility/${props.id}`;
  const children = props.members !== undefined
      ? <InlineValueCard title={`${props.departments} oddziałów`} icon={<Group />} count={`${props.members} dzieci`} color={'info'} />
      : <InlineValueCard title={<Skeleton sx={{marginLeft: 'auto'}} variant="text" width={100} height={24} />}
                         icon={<Group />}
                         count={<Skeleton variant="text" width={140} height={48} />} color={'info'} />;

    let unpaid = {color: 'secondary', icon: (<Receipt />), count: 'Brak', title: 'Zaległości'};
    if (props.unpaid !== undefined && props.unpaid) {
        unpaid.color = 'error';
        unpaid.count = currency(props.unpaid, {
            pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
        }).format({decimal: ','});
    }
  return (
      <Card sx={{margin: '20px 0', paddingTop: '20px'}}>
          <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
              <MDBox
                  variant="gradient"
                  bgColor={color}
                  color={color === "light" ? "dark" : "white"}
                  coloredShadow={color}
                  borderRadius="md"
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  width="4rem"
                  height="4rem"
                  mt={-3}

              >
                  <Link to={`${url}/dashboard`}><Icon fontSize="medium" style={{color: 'white'}}>
                      <Store />
                  </Icon></Link>
              </MDBox>
              <MDBox lineHeight={1.25} flexGrow={2} px={2}>
                  <Link to={`${url}/dashboard`}>
                      <MDTypography variant="button" fontWeight="light" fontSize="medium" color="text">
                          {props.title}
                      </MDTypography>
                      <MDTypography component="p" variant="button" color="text">
                          {props.address}
                      </MDTypography>
                  </Link>
                  </MDBox>
              <GroupMail people={[props.emails]} />

          </MDBox>
        <MDBox display="flex" justifyContent="space-between" alignItems={'center'} flexWrap={'wrap'} pt={1} px={2}>
            {children}
            {props.attendance != null && <InlineValueCard title={'Pobyt'} icon={<EscalatorWarning />} count={currency(props.attendance, {
                pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
            }).format({decimal: ','})} color={'success'}
                                 percentage={{
                                     color: 'info',
                                     weight: 'light',
                                     label: 'W poprzednim miesiącu'
                                 }}/>}
            {props.meals != null && <InlineValueCard
                    title={'Posiłki'}
                    icon={<FoodBank />}
                    count={currency(props.meals, {
                        pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
                    }).format({decimal: ','})}
                    color={'warning'}
                    percentage={{
                        color: 'info',
                        weight: 'light',
                        label: 'W poprzednim miesiącu'
                    }}
                />}
            {props.unpaid != null && <InlineValueCard {...unpaid}/>}
        </MDBox>
      </Card>
  )
}

export default Facility;

import React, {useEffect, useState} from "react";
import {Close, Save, Send} from "@mui/icons-material";
import {CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import MDTypography from "../components/MDTypography";
import MDButton from "../components/MDButton";


export default function StyledModal({
                                        children,
                                        send,
                                        dismiss,
                                        title,
                                        description,
                                        isOpen = false,
                                        actionLabel = 'Dodaj',
                                        actionColor = 'success',
                                        fullWidth = false,
                                        maxWidth = 'sm',
                                        additionalActions = '',
                                        successIcon = <Save color="light" size={'1rem'}/>
                                    }) {
    const closeCss = {
        position: 'absolute',
        right: 8,
        top: 8
    };
    const [sending, setSending] = useState(false);

    useEffect(() => {
        setSending(false);
    }, [isOpen]);

    return (<Dialog open={isOpen} onClose={dismiss} fullWidth={fullWidth} maxWidth={maxWidth}>
        <IconButton variant={'text'} onClick={dismiss} sx={closeCss}><Close/></IconButton>
        <DialogTitle variant={'h5'} fontWeight={'light'}>
            {title}
        </DialogTitle>
        {description !== undefined && <MDTypography variant={'p'} fontSize={'small'} fontWeight={'regular'}
                                                    sx={{padding: '0 16px'}}>{description}</MDTypography>}
        <DialogContent>

            {children}
        </DialogContent>
        <DialogActions sx={{display: 'flex', justifyContent: 'space-between'}}>
            <MDButton onClick={dismiss} color={'secondary'}>Anuluj</MDButton>
            {additionalActions}
            <MDButton startIcon={sending === true ? <CircularProgress color="light" size={'1rem'}/> : successIcon} onClick={() => {setSending(true); send();}} color={actionColor} disabled={sending}>{actionLabel}</MDButton>
        </DialogActions>
    </Dialog>);
}
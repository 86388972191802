import MDBox from "../components/MDBox";
import React from "react";
import MDTypography from "../components/MDTypography";
import {Grid} from "@mui/material";
import Footer from "./Footer";
import logo from 'logo.png';

function SplashScreen({ background, title , children }) {

    return (
        <MDBox
            width="100vw"
            height="100%"
            minHeight="100vh"
            bgColor={background}
            sx={{ overflowX: "hidden" }}
        >
            <MDBox
                position="absolute"
                width="100%"
                minHeight="100vh"
                sx={{
                    backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                        background &&
                        `${linearGradient(
                            rgba(gradients.dark.main, 0.6),
                            rgba(gradients.dark.state, 0.6)
                        )}, url(${background})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                    zIndex: -1000,
                }}
            />
            <MDBox px={1} width="100%" height="100vh" mx="auto">
                <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%">
                    <Grid item xs={11} sm={9} md={5} lg={4} xl={3} sx={{zIndex: 100, textAlign: 'center'}}>
                        <MDBox component="img" src={logo} alt={title} width="40%" sx={{marginBottom: '30px'}}/>
                        {children}
                    </Grid>
                </Grid>
            </MDBox>
            <Footer light />
        </MDBox>

    );
}

export default SplashScreen;
import React, {useContext, useEffect, useState} from "react";
// core components
import AuthContext from "../../../services/AuthContext";
import {AccountTree, Clear, ContentPaste, Delete, Edit, Remove} from "@mui/icons-material";
import {Button} from "@mui/material";
import {DetailsList, PrimaryButton, SelectionMode} from "@fluentui/react";
import IconButton from "@mui/material/IconButton";
import {useParams} from "react-router-dom";
import GenerateMassPayments from "../../../Panels/Facility/GenerateMassPayments";
import AddTemplate from "../../../Panels/Template/AddTemplate";
import addTemplate from "../../../Panels/Template/AddTemplate";
import MassPayments from "./MassPayments";

function Templates() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [documents, setDocuments] = useState([]);
    const [canModify, setCanModify] = useState(false);
    const [addTemplate, setAddTemplate] = useState(false);
    const [refresh, setRefresh] = useState(0);

    function _delete(id) {
        fetch(`/api/facility/${params.id}/template/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1)
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchData() {
            await fetch(`/api/facility/${params.id}/template`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setCanModify(data.canModify);
                    setDocuments(data.templates)
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user, refresh, params.id]);

    const _downloadFile = (id) => {
        let form = document.createElement('form')
        form.method = 'post'
        form.target = '_blank'
        form.action = `/file/facility/template/${id}`
        form.innerHTML = '<input type="hidden" name="bearer" value="' + user.token + '">'
        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
    }


    const columns = [
        {
            key: 'type',
            name: 'Wzór',
            ariaLabel: 'Wzór dokumentu',
            fieldName: 'type',
            minWidth: 200,
            isRowHeader: true,
            isResizable: true,
            onRender: (item) => (<PrimaryButton text={item.type} onClick={() => _downloadFile(item.id)}/>)
        },
        {
            key: 'processor',
            name: 'Processor',
            ariaLabel: 'Sposób translacji',
            fieldName: 'processor',
            minWidth: 200,
            isResizable: false,
        },
        {
            key: 'uploaded',
            name: 'Dodano',
            ariaLabel: 'Data dodania wzoru dokumentu',
            fieldName: 'uploaded',
            minWidth: 120,
            isResizable: false
        },
        {
            key: 'id',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            minWidth: 120,
            onRender: (item) => (item.active && canModify ? <IconButton color={'error'} title='Usuń' onDoubleClick={() => _delete(item.id)}><Delete /></IconButton> : <span>Nieaktywny</span>)
        }
    ];

    return (
        <div>
            {canModify && <Button onClick={() => setAddTemplate(true)}><ContentPaste /> Dodaj Wzór</Button>}
            <AddTemplate add={addTemplate} dismiss={() => setAddTemplate(false)}
                                  onSuccess={() => setRefresh(refresh + 1)}/>
            <DetailsList items={documents} columns={columns} selectionMode={SelectionMode.none}/>
        </div>
    );
}

export default Templates;
import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import MDButton from "../../components/MDButton";
import {useParams} from "react-router-dom";
import MDTypography from "../../components/MDTypography";


function ChangeRoom(props) {
    const title = 'Zmień Salę';
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [rooms, setRooms] = useState([]);

    function _send(target) {
        const url = props.department !== false ? `/api/facility/department/${props.department}/room/${target}`
            : `/api/facility/entrance/${props.participant}/room/${target}`
        fetch(url, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => { console.log(err) })
    }

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/room`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => { setRooms(data) })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user]);

    const closeCss = {
        position: 'absolute',
        right: 8,
        top: 8
    };

    return (<Dialog open={props.isOpen} onClose={props.dismiss}>
        <IconButton variant={'text'} onClick={props.dismiss} sx={closeCss}><Close/></IconButton>
        <DialogTitle variant={'h5'} fontWeight={'light'}>
            {title}
        </DialogTitle>
        <MDTypography variant={'p'} fontSize={'small'} fontWeight={'regular'}
                      sx={{padding: '0 16px'}}>Tutaj możesz zmienić salę dziecka lub całej grupy</MDTypography>
        <DialogContent>
            {rooms.map(room => {return <MDButton variant={'contained'} color={'dark'} sx={{color: 'white', background: room.color}} onClick={() => _send(room.id)}>{room.number}</MDButton>})}
        </DialogContent>
    </Dialog>);
}
export default ChangeRoom;
import MDTypography from "../../../../../components/MDTypography";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AuthContext from "../../../../../services/AuthContext";
import {DetailsList, SelectionMode, StackItem} from "@fluentui/react";
import {Button, Stack} from "@mui/material";
import {AccountBalance, Add, Delete} from "@mui/icons-material";
import MDButton from "../../../../../components/MDButton";
import AddMemberMealBlockade from "../../../../../Panels/Member/AddMemberMealBlockade";
import AddBillingSplit from "../../../../../Panels/Member/AddBillingSplit";
import AddBillingSplitTag from "../../../../../Panels/Member/AddBillingSplitTag";
import AddBillingSplitMeal from "../../../../../Panels/Member/AddBillingSplitMeal";


function BillingSplit() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [splits, setSplits] = useState([]);
    const [refresh, setRefresh] = useState(0);

    const [split, setSplit] = useState(null);
    const [splitMeals, setSplitMeals] = useState([]);
    const [splitTags, setSplitTags] = useState([]);

    const [addSplit, setAddSplit] = useState(false)
    const [addSplitMeal, setAddSplitMeal] = useState(false)
    const [addSplitTag, setAddSplitTag] = useState(false)

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchData() {
            await fetch(`/api/member/${params.member}/billing/split`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setSplits(data);
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user, params.id, params.member, refresh]);

    useEffect(() => {
        const abortController = new AbortController();
        if (split !== null) {
            async function fetchData() {
                await fetch(`/api/member/billing/split/${split.id}`, {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                        'Authorization': 'Bearer ' + user.token
                    }
                }).then(res => res.json())
                    .then(data => {
                        setSplitMeals(data.meals);
                        setSplitTags(data.tags);
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            fetchData();
        }
        return () => abortController.abort();
    }, [user, split, refresh]);

    function _delete(item) {
        fetch(`/api/member/billing/split/${item.id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1);
            })
            .catch(err => {
                console.log(err)
            })
    }

    function _deleteMeal(item) {
        fetch(`/api/member/billing/split/meal/${item.id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1);
            })
            .catch(err => {
                console.log(err)
            })
    }

    function _deleteTag(item) {
        fetch(`/api/member/billing/split/tag/${item.id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1);
            })
            .catch(err => {
                console.log(err)
            })
    }


    function _makeButtons(item, callback) {
        return (<div>
            <MDButton iconOnly color="error" onDoubleClick={() => callback(item)}>
                <Delete/>
            </MDButton>
        </div>);
    }

    const columnsSplit = [
        {
            key: 'name',
            name: 'Nazwa',
            ariaLabel: 'Nazwa',
            fieldName: 'name',
            minWidth: 100,
            isResizable: true,
            onRender: (item) => {
                return <MDButton color={'info'} variant={split?.id === item.id ? "contained" : "outlined"} onClick={() => {
                    setSplit(item);
                    document.activeElement.blur();
                }}>
                    {item.name}</MDButton>
            }
        },
        {
            key: 'account',
            name: 'Konto',
            ariaLabel: 'Konto',
            fieldName: 'account',
            minWidth: 50,
            isResizable: false,
            onRender: (item) => item.account !== null && (<AccountBalance title={item.account}/>)
        },
        {
            key: 'action',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'action',
            minWidth: 45,
            onRender: (item) => _makeButtons(item, _delete)
        }
    ];

    const columnsSplitTag = [
        {
            key: 'tag',
            name: 'Tag',
            ariaLabel: 'Tag',
            fieldName: 'tag',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'discount',
            name: 'Rabat',
            ariaLabel: 'Rabat',
            fieldName: 'discount',
            minWidth: 100,
            isResizable: true,
            onRender: (item) => `${item.discount}%`
        },
        {
            key: 'action',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'action',
            minWidth: 45,
            onRender: (item) => _makeButtons(item, _deleteTag)
        }
    ];

    const columnsSplitMeal = [
        {
            key: 'meal',
            name: 'Posiłek',
            ariaLabel: 'Posiłek',
            fieldName: 'meal',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'discount',
            name: 'Rabat',
            ariaLabel: 'Rabat',
            fieldName: 'discount',
            minWidth: 100,
            isResizable: true,
            onRender: (item) => `${item.discount}%`
        },
        {
            key: 'action',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'action',
            minWidth: 45,
            onRender: (item) => _makeButtons(item, _deleteMeal)
        }
    ];

    return (<>
        <StackItem>
            <Button onClick={() => setAddSplit(params.member)}><Add /> Dodaj Podział</Button>
            <DetailsList selectionMode={SelectionMode.none} items={splits} columns={columnsSplit}/>
            <AddBillingSplit data={addSplit} dismiss={() => setAddSplit(false)} onSuccess={() => setRefresh(refresh + 1)} />
        </StackItem>
        <StackItem grow={1}>
            {split !== null && <>
                <MDTypography variant={'h5'} fontWeight={'light'}>{split?.name}</MDTypography>
                <StackItem>
                    <Button onClick={() => setAddSplitTag(split)}><Add /> Przypisz Tag</Button>
                    <DetailsList selectionMode={SelectionMode.none} items={splitTags} columns={columnsSplitTag}/>
                </StackItem>
                <StackItem>
                    <Button onClick={() => setAddSplitMeal(split)}><Add /> Przypisz Posiłek</Button>
                    <DetailsList selectionMode={SelectionMode.none} items={splitMeals} columns={columnsSplitMeal}/>
                </StackItem>
                </>
            }
            <AddBillingSplitTag data={addSplitTag} dismiss={() => setAddSplitTag(false)} onSuccess={() => setRefresh(refresh + 1)} />
            <AddBillingSplitMeal data={addSplitMeal} dismiss={() => setAddSplitMeal(false)} onSuccess={() => setRefresh(refresh + 1)} />
        </StackItem>
    </>);
}

export default BillingSplit;
import React from "react";
import {DetailsList, SelectionMode} from "@fluentui/react";
import {useOutletContext} from "react-router-dom";
import currency from "currency.js";

function UserMemberPayments() {
    const {payments} = useOutletContext();

    const columns = [
        {
            key: 'date',
            name: 'Data',
            ariaLabel: 'Data',
            fieldName: 'date',
            minWidth: 150,
            isResizable: true,
            isRowHeader: true
        },
        {
            key: 'value',
            name: 'Wpłata',
            ariaLabel: 'Wpłata',
            fieldName: 'value',
            minWidth: 120,
            isResizable: true,
            onRender: item => currency(item.value, {
                pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
            }).format({decimal: ','})
        }
    ];

    return (
            <DetailsList selectionMode={SelectionMode.none} items={payments} columns={columns} />
    );
}

export default UserMemberPayments;
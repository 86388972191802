import React, {useContext, useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import MDBox from "../../components/MDBox";
import AuthContext from "../../services/AuthContext";
import {CircularProgress} from "@mui/material";
import logo from "../../logo.png";
import {Done, Error} from "@mui/icons-material";
import MDButton from "../../components/MDButton";

function BillingCallback() {
    const [user, setUser] = useContext(AuthContext);
    const navigate = useNavigate();
    const [callbackMember, setCallbackMember] = useState({member_id: null, status: 'PENDING'});
    const [refresh, setRefresh] = useState(0);
    const callbackURL = callbackMember.member_id !== null ? `/member/${callbackMember.member_id}/finances` : `/dashboard`;

    useEffect(() => {
        let timer;
        if (callbackMember.status === 'PENDING') {
            timer = setInterval(() => {
                setRefresh(prev => prev + 1);
                clearInterval(timer);
            }, 5 * 1000);
        }
        return () => clearInterval(timer)
    }, [callbackMember]);

    useEffect(() => {
        if (refresh >= 3) {
            localStorage.removeItem('auth');
            navigate(callbackURL);
        }
    }, [callbackURL, refresh]);

    useEffect(() => {
        const abortController = new AbortController();
        if (callbackMember.status === 'PENDING') {
            const session = JSON.parse(localStorage.getItem('auth'));
            if (session !== null) {
                setUser(session);
                localStorage.removeItem('auth');
            }
            const token = session?.token ?? user?.token;
            const params = new URL(window.location.href).searchParams;
            const orderId = params.get("OrderID");
            const serviceID = params.get("ServiceID");
            const hash = params.get("Hash");

            async function fetchData() {
                await fetch(`/api/autopay/${serviceID}/check/${orderId}/${hash}`, {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                        'Authorization': 'Bearer ' + token
                    }
                }).then(res => res.json())
                    .then(data => {
                        setCallbackMember(data);
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }

            console.log(token);
            if (token !== null) {
                fetchData();
            }
        }
        return () => abortController.abort();
    }, [refresh]);


    function GradientCircularProgress() {
        return (
            <React.Fragment>
                <svg width={0} height={0}>
                    <defs>
                        <linearGradient id="my_gradient" x1="0%" y1="0%" x2="0%" y2="100%">
                            <stop offset="0%" stopColor="#e85124"/>
                            <stop offset="100%" stopColor="#f69f1c"/>
                        </linearGradient>
                    </defs>
                </svg>
                <CircularProgress size='120px' sx={{'svg circle': {stroke: 'url(#my_gradient)'}}}/>
            </React.Fragment>
        );
    }

    const slide = callbackMember.status === 'SUCCESS' ? <>
            <span style={{fontSize: '120px'}}><Done color={'success'}/></span>
            Dziękujemy za płatność
        </> : callbackMember.status === 'FAILURE' ?
        <>
            <span style={{fontSize: '120px'}}><Error color={'error'}/></span>
            Błąd płatności
        </>
        : <>
            <GradientCircularProgress/>
            Przetwarzamy twoją płatność
        </>

    return (
        <MDBox height='100vh' display="flex" flexDirection="column" justifyContent="space-around" alignItems="center">
            <MDBox component="img" src={logo} alt={'Kidplace'} width="250px"/>
            <MDBox display="flex" flexDirection="column" justifyContent="space-around" alignItems="center">
                {slide}
            </MDBox>
            <MDBox display="flex" flexDirection="column" alignItems="center">Za 15 sekund przekierujemy Cię do
                aplikacji.
                {callbackMember.member_id !== null &&
                    <Link to={callbackURL}><MDButton color={'info'} onClick={() => localStorage.removeItem('auth')}>Przejdź
                        teraz</MDButton></Link>}
            </MDBox>

        </MDBox>);
}

export default BillingCallback;
import React, {useContext, useEffect, useState} from "react";
// core components
import AuthContext from "../../services/AuthContext";
import {CalendarMonth, Kitchen} from "@mui/icons-material";
import {Button, Grid, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {DetailsList, SelectionMode} from "@fluentui/react";
import Title from "../../Component/Title";
import {useParams} from "react-router-dom";
import DatePickerPL from "../../Component/DatePickerPL";
import MDBox from "../../components/MDBox";
import dbDate from "../../Utilities/dbDate";
import dbMonth from "../../Utilities/dbMonth";
import Link from "@mui/material/Link";
import MDTypography from "../../components/MDTypography";

function MealRecord() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [date, setDate] = useState(new Date());
    const [meal, setMeal] = useState([]);
    const [canModify, setCanModify] = useState(false);
    const [filter, setFilter] = useState('all');
    const [locationFilters, setLocationFilters] = useState([1]);
    const [refresh, setRefresh] = useState(0);

    function _generate() {
        fetch(`/api/facility/${params.id}/meal/${dbDate(date)}`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1);
            })
            .catch(err => {
                console.log(err)
            })
    }


    useEffect(() => {
        const abortController = new AbortController();
        const postfix = filter !== 'all' ? '/' + filter : '';
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/meal/${dbDate(date)}${postfix}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setCanModify(data.canModify);
                    setMeal(data.meals)
                    setLocationFilters(data.locations);
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user, refresh, params.id, date, filter]);

    const columns = [
        {
            key: 'meal_type',
            name: 'Posiłek',
            ariaLabel: 'Posiłek',
            fieldName: 'meal_type',
            minWidth: 120,
            isResizable: true,
        },
        {
            key: 'diet',
            name: 'Dieta',
            ariaLabel: 'Dieta',
            fieldName: 'diet',
            minWidth: 180,
            isResizable: true,
        },
        {
            key: 'count',
            name: 'Ilość',
            ariaLabel: 'Ilość',
            fieldName: 'count',
            minWidth: 120,
            isResizable: true,
        }
    ];
    const subtitle = (<Grid container justifyContent={'space-between'} mb={3}>
        <Grid item>
            {canModify && <Button onClick={_generate}><Kitchen/> Generuj Posiłki</Button>}
            <Link target={'_blank'} href={`/api/facility/${params.id}/meal/${dbMonth(date)}?auth=${user.token}`}><Button><CalendarMonth /> Raport Miesięczny</Button></Link>
        </Grid>
        {locationFilters.length > 1 &&
            <MDTypography variant={'h5'} fontWeight={'light'}>Szatnia <ToggleButtonGroup
                color="info"
                value={filter}
                exclusive
                onChange={(e, v) => setFilter(v)}
                style={{marginLeft: '20px'}}
            >
                <ToggleButton value="all">Wszystkie</ToggleButton>
                {locationFilters.map(item => (<ToggleButton value={item} key={item}>{item}</ToggleButton>))}
            </ToggleButtonGroup></MDTypography>
        }
        <Grid item lg={2}>
            <MDBox sx={{borderBottom: '1px solid rgb(210, 214, 218)'}} flexGrow={1}>
                <DatePickerPL borderless value={date} onSelectDate={(e) => setDate(e)}/>
            </MDBox>
        </Grid>
    </Grid>);
    return (
        <>
            <Title title={'Posiłki'} subtitle={subtitle}/>
            <DetailsList items={meal} columns={columns} selectionMode={SelectionMode.none}/>
        </>
    );
}

export default MealRecord;
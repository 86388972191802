import {Card, Grid} from "@mui/material";
import MDBox from "../../components/MDBox";
import React, {useEffect, useState} from "react";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import getTextColor from "../../Utilities/getTextColor";

function Rooms({outgoing, slides, dynamic}) {

    const [rooms, setRooms] = useState([]);

    useEffect(() => {
        let byRoom = [];
        let byDepartment = [];
        outgoing.forEach(item => {
            if (byRoom[item.room] === undefined) {
                byRoom[item.room] = [];
            }
            if (byDepartment[item.department] === undefined) {
                byDepartment[item.department] = [];
            }
            if (byDepartment[item.department][item.room] === undefined) {
                byDepartment[item.department][item.room] = 0;
            }
            byRoom[item.room].push(item);
            byDepartment[item.department][item.room]++;
        });


        Object.keys(byDepartment).forEach((department) => {
            let maxIdx = 0;
            let max = 0;
            let color = department[0];
            byDepartment[department].forEach((key, idx) => {
                if (max <= byDepartment[department][idx]) {
                    delete byDepartment[department][maxIdx];
                    max = byDepartment[department][idx];
                    maxIdx = idx;
                } else {
                    delete byDepartment[department][idx];
                }
            });
            let example = byRoom[maxIdx].filter((item) => item.department === department)[0];
            console.log(department, example);
            byRoom[maxIdx] = byRoom[maxIdx].filter(majority => majority.department !== department);
            byRoom[maxIdx].unshift({id: 0, department: department, member: department, room: maxIdx, default_color: example.default_color, color: example.color, number: example.number});
        })
        setRooms(byRoom.filter(Boolean));
        dynamic(Math.ceil(Object.keys(byRoom).length / 3));
    }, [outgoing]);


const roomList = rooms.map((item, idx) => {
    const members = item.map((iG) => {return (<MDButton key={iG.id} sx={{margin: '5px', backgroundColor: iG.default_color}} variant={'contained'} style={{color: getTextColor(iG.default_color)}} >
            {iG.member}
        </MDButton>);
    });
    return (
        <Grid item key={idx} xs={12} sm={6} md={4} lg={4} xl={3} m={2} mt={4} flexGrow={1}>
            <Card>
                <MDBox sx={{backgroundColor: item[0].color}} mt={-3} justifyContent="center"
                       alignItems="center" borderRadius="md"
                       display="flex" coloredShadow='light'>
                    <MDTypography style={{color: getTextColor(item[0].color)}} >Sala {item[0].number}</MDTypography>
                </MDBox>
                {members}
            </Card>
        </Grid>
    );
})
const dynamicSlides =  Math.ceil(roomList.length / 3);
const slide = (page) => {
    return (<div className={'slider'} style={{width: `calc(100%/${slides + dynamicSlides})`}} key={page}>
        <Grid container height="100%" justifyContent="center" alignItems="center">
            <Grid container justifyContent="center" alignItems="center" sx={{zoom: '1.75'}}>
                <Grid container justifyContent="center" alignItems="center">
                    <h1 style={{height: '10vh', textAlign: 'center'}}>Odbiory</h1>
                </Grid>
                <Grid container justifyContent="center" alignItems="space-between">
                    {roomList.filter((i, idx) => {
                        return idx >= (page*3)
                            && idx < ((page+1)*3);
                    })}
                </Grid>
            </Grid>
        </Grid>
    </div>);
}
    let slideshow = [];
    for (let i = 0; i < dynamicSlides; i++) {
        slideshow[i] = slide(i);
    }
    return slideshow.reverse();
}

export default Rooms;
import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import dbDate from "../../Utilities/dbDate";
import StyledModal from "../../StyledComponents/StyledModal";
import DateInput from "../../StyledComponents/DateInput";


function ChangeDeadline(props) {
    const title = 'Edytuj Termin Płatności';
    const description = 'Możesz tutaj zedytować termin płatności dla rachunków w wybranym miesiącu.';

    const [user] = useContext(AuthContext);

    const [date, setDate] = useState('');

    function _send() {
        const formData = {
            deadline: dbDate(date)
        };
        fetch(`/api/facility/${props.data.facility}/billing/${props.data.month}/deadline`, {
            method: 'PUT',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }


    useEffect(() => {
        setDate(null);
    }, [props.data])

    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss} actionLabel={'Edytuj'}>
            <DateInput value={date} onChange={(e) => setDate(e)} label='Termin Płatności'/>
        </StyledModal>
    );
}

export default ChangeDeadline;
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../services/AuthContext";
import {useParams} from "react-router-dom";
import AddIncome from "../../../Panels/Settlement/AddIncome";
import ActionButton from "../../../StyledComponents/ActionButton";
import {Add, Delete, Edit, FileDownload, Moving, OpenWith} from "@mui/icons-material";
import { useOutletContext } from "react-router-dom";
import {DetailsList, SelectionMode} from "@fluentui/react";
import {Button} from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import currency from "currency.js";
import IconButton from "@mui/material/IconButton";
import {CSVDownloader} from "react-papaparse";
import MoveIncome from "../../../Panels/Settlement/MoveIncome";

function Incomes() {
    const month = useOutletContext();
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [incomes, setIncomes] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [summary, setSummary] = useState('');
    const [addIncome, setAddIncome] = useState(false);
    const [moveIncome, setMoveIncome] = useState(false);

    function _delete(id) {
        fetch(`/api/facility/income/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setIncomes( incomes.filter(item => item.id !== id));
            })
            .catch(err => { console.log(err) })
    }
    function _makeButtons(item) {
        if (item.hash === null) {
            return (<div>
                <ActionButton disabled icon={<Edit/>}/>
                <ActionButton color='error' icon={<Delete/>} onDoubleClick={() => _delete(item.id)}/>
            </div>);
        } else {
            return (<div>
                <ActionButton color='warning' icon={<OpenWith/>} onClick={() => setMoveIncome(item.id)}/>
            </div>);
        }
    }

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/income/${month}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setIncomes(data);
                    const saldo = data.reduce((c,v) => c.add(currency(v.value)), currency(0, {
                        pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
                    }));
                    setSummary(<MDTypography textAlign={'right'} fontWeight={'light'} variant={'h4'}>Razem: {saldo.format()} <CSVDownloader data={data} filename={`przychody_${month}`} bom={true} config={{delimiter: ';'}}><IconButton variant={'text'}><FileDownload /></IconButton></CSVDownloader></MDTypography>);
                })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, month, params.id, refresh]);

    const columns = [
        {
            key: 'date',
            name: 'Data',
            ariaLabel: 'Data',
            fieldName: 'date',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'value',
            name: 'Przychód',
            ariaLabel: 'Przychód',
            fieldName: 'value',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'note',
            name: 'Notatka',
            ariaLabel: 'Notatka',
            fieldName: 'note',
            minWidth: 250,
            isResizable: true,
        },
        {
            key: 'income_type',
            name: 'Typ',
            ariaLabel: 'Typ przychodu',
            fieldName: 'income_type',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'member',
            name: 'Opłata na',
            ariaLabel: 'Opłata na',
            fieldName: 'member',
            minWidth: 200,
            isResizable: true
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 120,
            onRender: (item) => _makeButtons(item)
        }
    ];
    return (
        <div>
            <Button onClick={() => setAddIncome(true)}><Add /> Dodaj Przychód</Button>
            {summary}
            <AddIncome isOpen={addIncome} dismiss={() => setAddIncome(false)} onSuccess={() => setRefresh(refresh + 1)} />
            <MoveIncome isOpen={moveIncome} dismiss={() => setMoveIncome(false)} onSuccess={() => setRefresh(refresh + 1)} />
            <DetailsList selectionMode={SelectionMode.none} items={incomes} columns={columns} />
        </div>
    );
}

export default Incomes;
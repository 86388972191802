import React, {useContext} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";

function DeleteForeverMember(props) {
    const title = 'Usuń uczestnika';
    const description = 'Czy na pewno chcesz usunąć na zawsze dane uczestnika? ' +
        'Ta operacja jest nieodwracalna.';

    const [user] = useContext(AuthContext);

    function _send() {
        fetch(`/api/member/${props.data}/forever`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss} actionLabel={'Usuń'} actionColor={'error'}/>
    );
}

export default DeleteForeverMember;
import React, {useContext, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import MDButton from "../../components/MDButton";


function ImportFacility(props) {
    const title = 'Importuj dane przedszkola';
    const description = 'Tutaj możesz zaimportować dane swojego przedszkola. Ponowny import zduplikuje dane.';

    const [user] = useContext(AuthContext);
    const [file, setFile] = useState(null);

    function _send() {
        const data = new FormData();
        data.append('import', file);

        fetch(`/api/facility/${props.data}/import`, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    function _clear() {
        fetch(`/api/facility/${props.data}/import/clear`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss}>
            <MDButton fullWidth variant={'text'} color={'error'} onDoubleClick={_clear}>Wyczyść Dane</MDButton>
            <input type="file" name="file" onChange={(e) => setFile(e.target.files[0])}/>
        </StyledModal>
    );
}

export default ImportFacility;
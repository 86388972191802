import React, {useState} from "react";
import {Check, NoMeetingRoom} from "@mui/icons-material";
import MDButton from "../../../components/MDButton";
import {Card, Grid} from "@mui/material";
import MDBox from "../../../components/MDBox";
import MDTypography from "../../../components/MDTypography";
import MemberOut from "../../../Panels/Member/MemberOut";

export default function OutListing(props) {
  const [memberOut, setMemberOut] = useState(false);

  const members = props.department.map((item) => {
    let today = new Date();
    let outDate = new Date(item.out);
    outDate.setHours(0,0,0,0);
    today.setHours(0,0,0,0);
    if (item.in !== null && item.out === null) {
      return (
        <MDButton key={item.id} color="info" sx={{margin: '5px'}}
                  variant={'contained'}  onClick={() => setMemberOut(item)}>
          <NoMeetingRoom  /> {item.surname} {item.name}
        </MDButton>
      );
    } else if (props.showAll && today.getTime() === outDate.getTime()) {
      const out = new Date(item.out);
      return (
        <MDButton sx={{margin: '5px'}}
                  key={item.id}
          color="success"
                  variant={'contained'}
          title="Opuszczono placówkę"
          onDoubleClick={() => props.cancel(item.participation_id, props.name, item.id)}
        >
          <Check /> {out.getHours()}:{String(out.getMinutes()).padStart(2, '0')} {item.surname} {item.name}
        </MDButton>
      );
    }
    return ('');
  }).filter((i) => i !== '');
  if (!members.length) {
    return null;
  }
  return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={2} m={1} mt={4} flexGrow={1}>
        <Card>
          <MDBox bgColor="warning" variant={'gradient'} mt={-3} justifyContent="center"
                 alignItems="center" borderRadius="md"
                 display="flex" coloredShadow='warning'>
            <MDTypography color={'white'}>{props.name}</MDTypography>
          </MDBox>
          {members}
        </Card>
          <MemberOut data={memberOut} dismiss={() => setMemberOut(false)} onSuccess={props.out} />
      </Grid>
  );
}

import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import {useParams} from "react-router-dom";
import dbDate from "../../Utilities/dbDate";
import StyledModal from "../../StyledComponents/StyledModal";
import StyledSelect from "../../StyledComponents/StyledSelect";
import DateInput from "../../StyledComponents/DateInput";
import MDInput from "../../components/MDInput";
import MDButton from "../../components/MDButton";
import {Add} from "@mui/icons-material";
import AddContractor from "../Contractor/AddContractor";


function AddFacilityCost(props) {
    const title = 'Dodaj Fakturę Kosztową';
    const description = 'Tutaj możesz wprowadzić fakturę kosztową. Po utworzeniu faktury można ją rozbić na różne tagi i źródła dochodu.';

    const [user] = useContext(AuthContext);
    const params = useParams();
    const [file, setFile] = useState(null);
    const [date, setDate] = useState('');
    const [value, setValue] = useState('');
    const [note, setNote] = useState('');
    const [invoiceNumber, setInvoiceNumber] = useState('');
    const [contractor, setContractor] = useState('');
    const [refresh, setRefresh] = useState(0);

    function _send() {
        const data = new FormData();
        data.append('date', dbDate(date));
        data.append('value', value);
        data.append('invoice_number', invoiceNumber);
        data.append('note', note);
        data.append('contractor_id', contractor.id);
        data.append('file', file);

        fetch(`/api/facility/${params.id}/cost`, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    const [addContractor, setAddContractor] = useState(false);
    const [contractorList, setContractorList] = useState([]);
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/contractor/${params.id}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setContractorList(data.contractors)
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user, refresh]);

    return (
        <StyledModal isOpen={props.add} title={title} description={description} send={_send} dismiss={props.dismiss}>
            <MDButton color={'info'} variant={'text'} size={'small'} onClick={() => setAddContractor(true)}><Add/> Nowy Kontrahent</MDButton>
            <AddContractor isOpen={addContractor} dismiss={() => setAddContractor(false)} onSuccess={(data) => {setRefresh(refresh + 1); setContractor({id: data.id, label: data.name})}} />
            <StyledSelect
                name='contractor'
                label='Kontrahent'
                value={contractor}
                onChange={(e, v) => setContractor(v)}
                items={contractorList}
            />
            <MDInput
                onChange={(e) => setInvoiceNumber(e.target.value)}
                label='Numer Faktury'
                fullWidth
                sx={{marginBottom: 2}}
                value={invoiceNumber}
            />
            <MDInput
                onChange={(e) => setValue(e.target.value)}
                type={'number'}
                min={0}
                label='Wartość'
                fullWidth
                sx={{marginBottom: 2}}
                value={value}
            />
            <DateInput
                label='Data'
                id='date'
                value={date}
                onChange={(e) => setDate(e)}
            />
            <MDInput
                onChange={(e) => setNote(e.target.value)}
                label='Opis'
                fullWidth
                sx={{marginBottom: 2}}
                value={note}
            />
            <input type="file" name="file" onChange={(e) => setFile(e.target.files[0])}/>
        </StyledModal>
    );
}

export default AddFacilityCost;
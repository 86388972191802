import MDBox from "../../../../components/MDBox";
import React, {useContext, useEffect, useState} from "react";
import MDButton from "../../../../components/MDButton";
import {Add, Delete} from "@mui/icons-material";
import ActionButton from "../../../../StyledComponents/ActionButton";
import MDTypography from "../../../../components/MDTypography";
import {useOutletContext, useParams} from "react-router-dom";
import MonthPicker from "../../../../Component/MonthPicker";
import dbMonth from "../../../../Utilities/dbMonth";
import {DetailsList, SelectionMode} from "@fluentui/react";
import AuthContext from "../../../../services/AuthContext";
import AddAbsence from "../../../../Panels/Member/AddAbsence";

function MemberPresence() {
    const {setSummary} = useOutletContext();
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [absences, setAbsences] = useState([]);
    const [presences, setPresences] = useState([]);
    const [addAbsence, setAddAbsence] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [month, setMonth] = useState(new Date());

    useEffect(() => {
        setSummary(<MonthPicker value={month} onChange={(e) => setMonth(e)}/>);
    }, [])

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/member/${params.member}/presence/${dbMonth(month)}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setAbsences(data.absence);
                    setPresences(data.presence);
                })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, params.id, params.member, month, refresh]);

    function _delete(id) {
        fetch(`/api/member/absence/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1);
            })
            .catch(err => { console.log(err) })
    }

    function _makeButtons(item) {
        return (<ActionButton color='error' icon={<Delete />} onDoubleClick={() => _delete(item.id)} />);
    }

    const columnsPresence = [
        {
            key: 'in',
            name: 'Od',
            ariaLabel: 'Od',
            fieldName: 'in',
            minWidth: 120,
            isResizable: true,
        },
        {
            key: 'out',
            name: 'Do',
            ariaLabel: 'Do',
            fieldName: 'out',
            minWidth: 120,
            isResizable: true,
        },
        {
            key: 'fullname',
            name: 'Odbierający',
            ariaLabel: 'Odbierający',
            fieldName: 'fullname',
            minWidth: 120,
            isResizable: true,
        },
        {
            key: 'cost',
            name: 'Koszt',
            ariaLabel: 'Koszt',
            fieldName: 'cost',
            minWidth: 120,
            isResizable: true,
        }
    ];

    const columnsAbsence = [
        {
            key: 'date',
            name: 'Data',
            ariaLabel: 'Data',
            fieldName: 'date',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 80,
            onRender: _makeButtons
        }
    ];

    return (<MDBox display={'flex'} justifyContent={'space-around'}>
            <MDBox>
                <MDTypography variant={'h5'} fontWeight={'light'}>Obecności</MDTypography>
                <DetailsList selectionMode={SelectionMode.none} items={presences} columns={columnsPresence} />
            </MDBox>
            <MDBox>
                <MDTypography variant={'h5'} fontWeight={'light'}>Nieobecności</MDTypography>
                <MDButton color={'info'} variant={'text'} onClick={() => setAddAbsence(params.member)} ><Add /> Dodaj Nieobecność</MDButton>
                <DetailsList selectionMode={SelectionMode.none} items={absences} columns={columnsAbsence} />
            </MDBox>
            <AddAbsence data={addAbsence} dismiss={() => setAddAbsence(false)} onSuccess={() => setRefresh(refresh +1)} />
    </MDBox>);
}

export default MemberPresence;
import React, { useState } from "react";

const AuthContext = React.createContext({
  user: {
    username: "",
    token: "",
  },
  setUser: () => {},
});

export const AuthProvider = (props) => {
  const [user, setUser] = useState({ username: "", token: "", facilities: [], members: [] });

  return (
    <AuthContext.Provider value={[user, setUser]}>
      {props.children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;

export default AuthContext;

import React, {useContext, useEffect, useState} from "react";
// core components
import AuthContext from "../../../../services/AuthContext";
import {PendingActions} from "@mui/icons-material";
import {Button} from "@mui/material";
import {DetailsList, PrimaryButton, SelectionMode} from "@fluentui/react";
import {useParams} from "react-router-dom";
import GenerateDocument from "../../../../Panels/Member/GenerateDocument";

function MemberDocuments() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [documents, setDocuments] = useState([]);
    const [canModify, setCanModify] = useState(false);
    const [generate, setGenerate] = useState(false);
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchData() {
            await fetch(`/api/facility/${params.id}/member/${params.member}/document`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setCanModify(data.canModify);
                    setDocuments(data.documents)
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user, refresh, params.id]);

    const _downloadFile = (id) => {
        let form = document.createElement('form')
        form.method = 'post'
        form.target = '_blank'
        form.action = `/file/member/document/${id}`
        form.innerHTML = '<input type="hidden" name="bearer" value="' + user.token + '">'
        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
    }


    const columns = [
        {
            key: 'type',
            name: 'Dokument',
            ariaLabel: 'Dokument',
            fieldName: 'type',
            minWidth: 200,
            isRowHeader: true,
            isResizable: true,
            onRender: (item) => (<PrimaryButton text={item.type} onClick={() => _downloadFile(item.id)}/>)
        },
        {
            key: 'created',
            name: 'Dodano',
            ariaLabel: 'Data utworzenia',
            fieldName: 'created',
            minWidth: 120,
            isResizable: false
        }
    ];

    return (
        <div>
            {canModify && <Button onClick={() => setGenerate(params.member)}><PendingActions /> Generuj Dokument</Button>}
            <GenerateDocument add={generate} dismiss={() => setGenerate(false)}
                                  onSuccess={() => setRefresh(refresh + 1)}/>
            <DetailsList items={documents} columns={columns} selectionMode={SelectionMode.none}/>
        </div>
    );
}

export default MemberDocuments;
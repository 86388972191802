import React, {useContext} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";

function GenerateBilling(props) {
    const title = 'Generowanie rachunków';
    const description = 'Czy na pewno chcesz wygenerować rachunki dla wybranego miesiąca? ' +
        'Ponowne wygenerowanie spowoduje ponowne przeliczenie wszystkich rachunków z danego miesiąca.';

    const [user] = useContext(AuthContext);

    function _send() {
        fetch(`/api/facility/${props.data.facility}/billing/${props.data.month}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss} actionLabel={'Generuj'}/>
    );
}

export default GenerateBilling;
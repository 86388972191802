import Card from "@mui/material/Card";
import MDBox from "../../components/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "../../components/MDTypography";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";

function InlineValueCard({ color, title, count, percentage, icon }) {
    return (
        <Card sx={{boxShadow: 'none', flexGrow: 1}}>
            <MDBox display="flex" justifyContent="space-between" pt={2} px={2}>
                <MDBox
                    variant="gradient"
                    bgColor={color}
                    color={color === "light" ? "dark" : "white"}
                    coloredShadow={color}
                    borderRadius="sm"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="6rem"
                    height="6rem"
                >
                    <Icon fontSize="large" color="inherit">
                        {icon}
                    </Icon>
                </MDBox>
                <MDBox textAlign="right" lineHeight={1.25}>
                    <MDTypography variant="button" fontWeight="light" color="text">
                        {title}
                    </MDTypography>
                    <MDTypography variant="h4" fontWeight="light" color={color}>{count}</MDTypography>
                </MDBox>
            </MDBox>
            <Divider />
            <MDBox pb={2} px={2}>
                <MDTypography component="p" variant="button" color="text" display="flex">
                    <MDTypography
                        component="span"
                        variant="button"
                        fontWeight={percentage.weight}
                        color={percentage.color}
                    >
                        {percentage.amount}
                    </MDTypography>
                    &nbsp;{percentage.label}
                </MDTypography>
            </MDBox>
        </Card>
    );
}

// Setting default values for the props of ComplexStatisticsCard
InlineValueCard.defaultProps = {
    title: '',
    count: '',
    icon: '',
    color: "info",
    percentage: {
        color: "success",
        amount: "",
        label: "",
        weight: "bold"
    },
};

// Typechecking props for the ComplexStatisticsCard
InlineValueCard.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
    count: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]).isRequired,
    percentage: PropTypes.shape({
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "white",
        ]),
        amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
        label: PropTypes.string,
        weight: PropTypes.string
    }),
    icon: PropTypes.node.isRequired,
};

export default InlineValueCard;
/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import {Link} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import bgImage from "splash3.jpg";
import React, {useState} from "react";
import SplashScreen from "../../Layout/SplashScreen";
import {Alert} from "@mui/material";

export default function ResetPage() {
  const [username, setUsername] = useState("");
  const [flash, setFlash] = useState("");
  const registerUser = (e) => {
    e.preventDefault();
    const sentData = {username: username};

    fetch("/api/user/reset", {
      cache: "no-cache",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sentData),
    })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          response.json().then(function (data) {
            setFlash(<Alert severity="success">Wysłano email pozwalający na zmianę hasła.</Alert>);
          });
        })
        .catch((error) => {
          console.log(error.message);
        });
  };


  return (
    <SplashScreen background={bgImage} title={'Kidplace'}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h5" fontWeight="light" color="white">
            Zapomniałeś hasła?
          </MDTypography>
        </MDBox>
        {flash}
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={registerUser}>
            <MDBox mb={2}>
              <MDInput type="email" label="Email" variant="standard" fullWidth autoComplete={'email'}
                       value={username} onChange={(e) => setUsername(e.target.value)}/>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type={'submit'}>
                Zresetuj hasło
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Już pamiętasz?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Zaloguj się
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </SplashScreen>
  );
}

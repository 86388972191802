/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import {Link, useParams} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import bgImage from "splash2.jpg";
import React, {useEffect, useState} from "react";
import SplashScreen from "../../Layout/SplashScreen";
import {Alert} from "@mui/material";


export default function NewPasswordPage() {
  const params = useParams();
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [flashMessage, setFlashMessage] = useState("");
  const [flash, setFlash] = useState("");

  useEffect(() => {
    if (flashMessage !== '') {
      if(flashMessage === 'success') {
        setFlash(<Alert severity="success">Zresetowano hasło. <Link to={'/login'}>Zaloguj się</Link></Alert>);
      } else {
        setFlash(<Alert severity={'error'}>Nieaktywny token resetujący.</Alert>);
      }
    } else {
      setFlash('');
    }
  }, [flashMessage]);
  const newPasswordUser = (e) => {
    e.preventDefault();
    const sentData = {password: password};
    if (password !== passwordAgain) {
      setFlash(<Alert severity={'warning'}>Podane hasła się nie zgadzają.</Alert>);
      return null;
    }
    fetch(`/api/user/reset/${params.token}`, {
      cache: "no-cache",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sentData),
    })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          response.json().then(function (data) {
            setFlashMessage(data);
          });
        })
        .catch((error) => {
          console.log(error.message);
        });
  };

  return (
    <SplashScreen background={bgImage} title={'Kidplace'}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h5" fontWeight="light" color="white">
            Ustaw nowe hasło
          </MDTypography>
        </MDBox>
        {flash}
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={newPasswordUser}>
            <MDBox mb={2}>
              <MDInput type="password" required label="Hasło" variant="standard" fullWidth autoComplete={'new-password'}
                       value={password} onChange={(e) => setPassword(e.target.value)}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" required label="Powtórz Hasło" variant="standard" fullWidth autoComplete={'off'}
                       value={passwordAgain} onChange={(e) => setPasswordAgain(e.target.value)}/>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type={'submit'}>
                Ustaw hasło
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </SplashScreen>
  );
}

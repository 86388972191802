import React, {useContext} from "react";
import AuthContext from "../../../services/AuthContext";
import {Download, ReceiptLong} from "@mui/icons-material";
import {DetailsList, SelectionMode} from "@fluentui/react";
import {useOutletContext} from "react-router-dom";
import MDTypography from "../../../components/MDTypography";
import currency from "currency.js";

function UserMemberBilling() {
    const [user] = useContext(AuthContext);
    const {billings} = useOutletContext();
    const months = ['', 'Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'];

    function _makeBillingsButtons(item) {
        const pdf = item.real_value !== null
            ? <a href={`/api/user/member/billing/${item.id}?auth=${user.token}`} target={'_blank'} ><ReceiptLong color='success' fontSize={'medium'}/></a>
            : <a href={`/api/user/member/billing/${item.id}/short?auth=${user.token}`} target={'_blank'} ><ReceiptLong color='info' fontSize={'medium'}/></a>
            /*? <ActionButton color='success' title='Rachunek' icon={<RequestPage fontSize={'large'} />} href={`/api/user/member/billing/${item.id}?auth=${user.token}`} target={'_blank'} />
            : <ActionButton color='info' title='Prognoza' icon={<RequestPage fontSize={'large'} />} href={`/api/user/member/billing/${item.id}/short?auth=${user.token}`} target={'_blank'} />;*/
        return (<div>
            {pdf}
        </div>);
    }

    const _readableDate = (date) => {
        return `${months[parseInt(date.substring(5, 7))]} ${date.substring(0, 4)}`;
    };
    const _valueField = (item) => {
        if (item.real_value === item.value) return (<MDTypography>{item.real_value}</MDTypography>);
        const value = currency(item.value, {
            pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
        }).format({decimal: ','});
        if (item.real_value == null) return (<><MDTypography color={'info'}>{value}</MDTypography>Nieobecności: {item.absence}</>);
        const real = currency(item.real_value, {
            pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
        }).format({decimal: ','});
        const totalAbsences = parseInt(item.absences) - parseInt(item.absence);
        const sign = totalAbsences < 0 ? ' - ' : ' + ';
        return (<><MDTypography>{real}</MDTypography>Prognoza: {value}<br/>Nieobecności: {item.absence}{sign}{totalAbsences} </>);
    };

    const columns = [
        {
            key: 'actions',
            name: (<Download />),
            ariaLabel: 'Rachunek',
            fieldName: 'actions',
            minWidth: 15,
            maxWidth: 15,
            isResizable: false,
            onRender: _makeBillingsButtons
        },
        {
            key: 'date',
            name: 'Rachunek',
            ariaLabel: 'Rachunek',
            fieldName: 'date',
            minWidth: 150,
            isResizable: true,
            isRowHeader: true,
            onRender: (item) => (<>{item.split}<MDTypography>{_readableDate(item.date)}</MDTypography>Termin: {item.due}</>)
        },
        {
            key: 'value',
            name: 'Wartość',
            ariaLabel: 'Wartość',
            fieldName: 'value',
            minWidth: 120,
            isResizable: true,
            onRender: _valueField
        }
    ];

    return (
            <DetailsList selectionMode={SelectionMode.none} items={billings} columns={columns} />
    );
}

export default UserMemberBilling;
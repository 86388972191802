import {Grid} from "@mui/material";
import {NavLink, useParams} from "react-router-dom";
import MDBox from "../../components/MDBox";
import logo from "../../logo.png";
import React, {useEffect, useState} from "react";

function Welcome({slides}) {
    const params = useParams();
    const [date, setDate] = useState(new Date());

    const dni = new Array("niedziela", "poniedziałek", "wtorek", "środa",
        "czwartek", "piątek", "sobota");
    const miesiace = new Array("stycznia", "lutego", "marca", "kwietnia", "maja",
        "czerwca", "lipca", "sierpnia", "września", "października", "listopada",
        "grudnia");

    useEffect(() => {
        var timer = setInterval(()=>setDate(new Date()), 1000 )
        return function cleanup() {
            clearInterval(timer)
        }

    });

    return (<div className={'slider'} style={{width: `calc(100%/${slides})`}}>
        <Grid container height="100%" justifyContent="center" alignItems="center">
            <Grid container justifyContent="center" alignItems="center" sx={{zoom: '1.6'}}>
                <Grid container justifyContent="center" alignItems="center">
                    <h1 style={{height: '10vh', textAlign: 'center'}}>Dziś
                        jest {dni[date.getDay()]}, {date.getDate()} {miesiace[date.getMonth()]} {date.getFullYear()}</h1>
                </Grid>
                <Grid container justifyContent="center" alignItems="center">
                    <h1 style={{height: '10vh'}}>{date.toLocaleTimeString('pl-PL')}</h1>
                </Grid>
                <Grid item component={NavLink} to={`/tv/${params.auth}`} xs={11} sm={9} md={5} lg={4} xl={3}
                      sx={{zIndex: 100, textAlign: 'center'}}>
                    <MDBox component="img" src={logo} alt={'Kidplace'} width="100%" sx={{marginBottom: '30px'}}/>
                </Grid>
            </Grid>
        </Grid>
    </div>);
}

export default Welcome;
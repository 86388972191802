import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import DateRangeFlatpicker from "../../Component/DateRangeFlatpicker";
import dbDate from "../../Utilities/dbDate";
import {useParams} from "react-router-dom";
import StyledSelect from "../../StyledComponents/StyledSelect";
import {Alert} from "@mui/material";

function AddAbsence(props) {
    const title = 'Zgłoś nieobecność';
    const description = 'Tutaj możesz zgłosić nieobecność.';
    const [today, setToday] = useState(new Date());
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [dateRange, setDateRange] = useState([new Date(), new Date()]);
    const [member, setMember] = useState(null);
    const [members, setMembers] = useState([]);
    const [flash, setFlash] = useState(null);


    useEffect(() => {
        const abortController = new AbortController();
        if (props.isSelf) {
            let date = new Date();
            date.setMinutes(date.getMinutes() - props.data.timeframe);
            setToday(date);
            let next = new Date(date);
            next.setDate(next.getDate() + 1);
            setDateRange([next, next]);
        } else {
            setToday(new Date());
            let tomorrow = new Date();
            tomorrow.setDate(tomorrow.getDate() + 1);
            setDateRange([tomorrow, tomorrow]);
        }
        if (props.data === true) {
            setMember(null);
            async function fetchData() {
                await fetch(`/api/facility/${params.id}/members`, {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                        'Authorization': 'Bearer ' + user.token
                    }
                }).then(res => res.json())
                    .then(setMembers)
                    .catch(err => {
                        console.log(err)
                    })
            }
            fetchData();
            return () => abortController.abort();
        } else {
            setMember(props.data);
        }
    }, [props.data, user]);

    function _send() {
        setFlash(null);
        let id = member?.id;
        if (id === undefined) {
            id = member;
        }
        const url = props.isSelf ? `/api/user/member/${id}/absence` : `/api/member/${id}/absence`;
            const formData = {
                from: dbDate(dateRange[0]),
                to: dbDate(dateRange[1])
            };
            if (formData.from === 'NaN-NaN-NaN' || formData.to === 'NaN-NaN-NaN') {
                setFlash(<Alert severity="error">Podaj zakres od -> do.</Alert>);
            } else {
                fetch(url, {
                    method: 'POST',
                    body: JSON.stringify(formData),
                    headers: {
                        'Authorization': 'Bearer ' + user.token
                    }
                }).then(res => res.json())
                    .then(data => {
                        props.onSuccess();
                        props.dismiss();
                    })
                    .catch(err => {
                        console.log(err)
                        setFlash(<Alert severity="error">Podaj prawidłowy zakres od -> do.</Alert>);
                    })
            }
    }
    const pickerOptions = props.isSelf ? {minDate: today} : {};
    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss}
                     actionLabel='Dodaj'>
            {flash}
            {props.data === true && <StyledSelect
                name='member'
                label='Dziecko'
                value={member}
                onChange={(e, v) => setMember(v)}
                items={members.map(item => ({id: item.id, name: `${item.name} ${item.surname} - ${item.department}`}))}
                />}
            <DateRangeFlatpicker label={'Zakres'} value={dateRange} pickerOptions={pickerOptions} onChange={(e) => setDateRange(e)} />
        </StyledModal>
    );
}

export default AddAbsence;
import MDTypography from "../../../../components/MDTypography";
import React, {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import AuthContext from "../../../../services/AuthContext";
import {DetailsList, SelectionMode, StackItem} from "@fluentui/react";
import {Button, Stack} from "@mui/material";
import {Add, Delete} from "@mui/icons-material";
import AddMemberMealBlockade from "../../../../Panels/Member/AddMemberMealBlockade";
import MDButton from "../../../../components/MDButton";

function MemberMeal() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [blockades, setBlockades] = useState([]);
    const [refresh, setRefresh] = useState(0)
    const [addBlockade, setAddBlockade] = useState(false)

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchData() {
            await fetch(`/api/member/${params.member}/meal/blockade`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setBlockades(data);
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user, params.id, params.member, refresh]);

    function _delete(item) {
        fetch(`/api/member/${params.member}/meal/blockade/${item.id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1);
            })
            .catch(err => {
                console.log(err)
            })
    }


    function _makeButtons(item) {
        return (<div>
            <MDButton iconOnly color="error" onDoubleClick={() => _delete(item)}>
                <Delete/>
            </MDButton>
        </div>);
    }

    const columnsBlockades = [
        {
            key: 'date',
            name: 'Data',
            ariaLabel: 'Data',
            fieldName: 'date',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'meal',
            name: 'Posiłek',
            ariaLabel: 'Posiłek',
            fieldName: 'meal',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'action',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'action',
            minWidth: 45,
            onRender: _makeButtons
        }
    ];

    return (<Stack horizontal justifyContent={'space-around'}>
        <StackItem>
            <MDTypography variant={'h5'} fontWeight={'light'}>Blokady</MDTypography>
            <Button onClick={() => setAddBlockade(params.member)}><Add /> Dodaj Blokadę</Button>
            <DetailsList selectionMode={SelectionMode.none} items={blockades} columns={columnsBlockades}/>
            <AddMemberMealBlockade data={addBlockade} dismiss={() => setAddBlockade(false)} onSuccess={() => setRefresh(refresh + 1)} />
        </StackItem>
    </Stack>);
}

export default MemberMeal;
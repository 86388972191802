import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import {useParams} from "react-router-dom";
import StyledModal from "../../StyledComponents/StyledModal";
import StyledSelect from "../../StyledComponents/StyledSelect";


function MoveIncome(props) {
    const title = 'Przenieś Przychód';
    const description = 'Możesz tutaj przenieść masową płatność pomiędzy uczestnikami w przypadku pomyłki.';

    const [user] = useContext(AuthContext);
    const params = useParams();

    const [member, setMember] = useState(null);
    const [members, setMembers] = useState([]);

    function _send() {
        fetch(`/api/facility/income/${props.isOpen}/${member.id}`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }



    useEffect(() => {
        const abortController = new AbortController();
        async function fetchMembers() {
            await fetch(`/api/facility/${params.id}/members`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(setMembers)
                .catch(err => {
                    console.log(err)
                })
        }
        fetchMembers();
        return () => abortController.abort();
    }, [user]);

    return (
        <StyledModal isOpen={props.isOpen} title={title} description={description} send={_send} dismiss={props.dismiss} actionLabel={'Przenieś'}>
            <StyledSelect
                name='member'
                label='Opłata za'
                value={member}
                onChange={(e, v) => setMember(v)}
                items={members.map(item => ({id: item.id, name: `${item.name} ${item.surname} - ${item.department}`}))}
            />
        </StyledModal>
    );
}

export default MoveIncome;
import React, {useContext, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import MDInput from "../../components/MDInput";
import {useParams} from "react-router-dom";

function GenerateCalendar(props) {
    const title = 'Generuj Kalendarz';
    const description = 'Tutaj możesz wygenerować kalendarz swojej placówki.';


    const params = useParams();
    const [user] = useContext(AuthContext);
    const [from, setFrom] = useState((new Date()).getFullYear());
    const [to, setTo] = useState((new Date()).getFullYear() + 1);


    function _send() {
        fetch(`/api/facility/${params.id}/calendar/${from}/${to}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess(data);
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <StyledModal isOpen={props.isOpen} title={title} description={description} send={_send} dismiss={props.dismiss} actionLabel={'Generuj'} actionColor={'primary'}>
            <MDInput
                onChange={(e) => setFrom(e.target.value)}
                label='Od'
                fullWidth
                sx={{marginBottom: 2}}
                inputProps={{min: 1900, max: 3099}}
                value={from}
                type={'number'}
            />
            <MDInput
                value={to}
                onChange={(e) => setTo(e.target.value)}
                label='Do'
                fullWidth
                sx={{marginBottom: 2}}
                inputProps={{min: 1900, max: 3099}}
                type={'number'}
            />
        </StyledModal>
    );
}

export default GenerateCalendar;
import React from "react";
import Flatpickr from "react-flatpickr";
import MDInput from "../components/MDInput";
import dbTime from "../Utilities/dbTime";


export default function TimeFlatpicker({label, ...props}) {
    const sx = props.sx !== undefined ? props.sx : {marginTop: '8px', marginBottom: '8px'};
    return (
        <Flatpickr
            options={{
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: true
            }}
            {...props}
            render={({ defaultValue, value }, ref) => (
                <MDInput fullWidth={true} label={label} sx={sx} value={dbTime(value)} inputRef={ref} />
            )}
        />
    );
}
import React from "react";
import {Autocomplete, TextField} from "@mui/material";

export default function StyledSelect(props) {
    const options = props.items.map((item) => ({id: item.id, label: item.name}));
    const { value, ...rest } = props;
    let val = value;
    if (props.value !== undefined && typeof props.value !== 'object') {
        val = options.filter((item) => parseInt(item.id) === parseInt(props.value));
        val = val.length ? val[0] : value;
    }
    const sx = props.sx !== undefined ? props.sx : {marginBottom: '16px'};
    return (
        <Autocomplete
            sx={sx}
            {...rest}
            value={val}
            options={options}
            fullWidth
            renderInput={(params) => <TextField variant="standard" {...params} label={props.label} />}
        />
);
}
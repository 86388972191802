import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import TimeFlatpicker from "../../Component/TimeFlatpicker";
import dbDate from "../../Utilities/dbDate";
import dbTime from "../../Utilities/dbTime";
import MDButton from "../../components/MDButton";
import {TimerOff} from "@mui/icons-material";

function ForceEntrance(props) {
    const title = 'Zmiana obecności';
    const description = 'Tutaj możesz ręcznie ustawić godziny obecności dziecka.';

    const [user] = useContext(AuthContext);
    const [entrance, setEntrance] = useState(null);
    const [out, setOut] = useState(null);

    useEffect(() => {
            setEntrance(props.data?.in !== null ? new Date(props.data.in) : new Date(`${dbDate(props.date)} ${props.data.attend_from}`));
            setOut(props.data?.out !== null ? new Date(props.data.out) :
                props.data?.in !== null ? null : new Date(`${dbDate(props.date)} ${props.data.attend_to}`));
    }, [props.data, user]);


    function _send() {
            const formData = {
                in: `${dbDate(props.date)} ${dbTime(entrance)}`,
                out: out !== null ? `${dbDate(props.date)} ${dbTime(out)}` : null
            };
            fetch(`/api/facility/entrance/${props.data.member_id}/force`, {
                method: 'PUT',
                body: JSON.stringify(formData),
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    props.onSuccess();
                    props.dismiss();
                })
                .catch(err => {
                    console.log(err)
                })
    }

    function _delete() {
        fetch(`/api/facility/entrance/${props.data.member_participation}/force`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }
    const deleteButton = props.data.member_participation !== null ? (<MDButton color={'error'} variant={'contained'} onDoubleClick={_delete}><TimerOff/> Usuń obecność</MDButton>) : '';
    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss}
                     actionLabel='Ustaw' additionalActions={deleteButton}>

            <TimeFlatpicker label={'Od'} value={entrance} onChange={(e) => setEntrance(e)} />
            <TimeFlatpicker label={'Do'} value={out} onChange={(e) => setOut(e)} />
        </StyledModal>
    );
}

export default ForceEntrance;
import React, {useContext, useEffect, useState} from "react";
import Welcome from "../views/TVSlide/Welcome";
import Food from "../views/TVSlide/Food";
import Rooms from "../views/TVSlide/Rooms";
import {useParams} from "react-router-dom";
import AuthContext from "../services/AuthContext";

function SplashScreen({ background, title , children }) {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [outgoing, setOutgoing] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const activeSlides = 2;
    const [dynamicSlides, setDynamicSlides] = useState(0);
    const [duration, setDuration] = useState(5);
    const [currentSlide, setCurrentSlide] = useState(0);

    useEffect(() => {
        var timer = setInterval(() => setRefresh(refresh + 1), 2*60000 )
        return () => clearInterval(timer)
    });

    useEffect(() => {
        const durations = [
            5,
            5,
            20,
            20,
            20,
            20,
            20,
            20,
            20,
        ];
        var timer = setInterval(() => {
            setCurrentSlide((currentSlide + 1)%(activeSlides + dynamicSlides));
            clearInterval(timer);
        }, durations[currentSlide]*1000 );
        return () => clearInterval(timer)
    }, [currentSlide]);

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/tv/${params.auth}`, {
                method: 'GET',
                signal: abortController.signal,
            }).then(res => res.json())
                .then(data => {
                    setOutgoing(data['rooms']);
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [params.auth, refresh]);

    return (
        <div id='slider'>
            <div className='slides' style={{width: `calc(100% * ${activeSlides + dynamicSlides})`, right: `calc(100% * ${activeSlides + dynamicSlides - 1}`, marginLeft: `${currentSlide * 100}%`}}>
                <Rooms outgoing={outgoing} slides={activeSlides} dynamic={setDynamicSlides}/>
                <Food slides={activeSlides + dynamicSlides}/>
                <Welcome slides={activeSlides + dynamicSlides}/>
            </div>
        </div>
    )
        ;
}

export default SplashScreen;
import React, {useState} from "react";
import MDButton from "../../../components/MDButton";
import {Check, DoorBack, Home, RoomPreferences, Warning} from "@mui/icons-material";
import {Card, CardHeader, Grid} from "@mui/material";
import MDTypography from "../../../components/MDTypography";
import MDBox from "../../../components/MDBox";
import ChangeRoom from "../../../Panels/Room/ChangeRoom";
import Badge from "@mui/material/Badge";

export default function Listing(props) {

  const members = props.department.map((item) => {
    if (item.out === null && item.in !== null) {
      if (props.showIn) {
        return (
            <div>
            <MDButton key={item.id} sx={{margin: '5px', width: 'calc(100% - 54px)'}} color="success" onDoubleClick={() => props.cancel(item.participation_id, props.name, item.id)}>
              <Check /> {item.surname} {item.name}
            </MDButton>
          <MDButton key={item.id} sx={{padding: '0', minWidth: '44px', width: '44px', background: item.color} } color={'dark'} variant={'contained'}
                    onClick={() => props.setMemberRoomChange(item.id)}>
              <RoomPreferences />
          </MDButton>
            </div>
        );
      }
    } else if (item.absence !== null) {
      if (props.showAbsence) {
        return (
          <MDButton key={item.id} sx={{margin: '5px'}} onClick={() => props.in(item.id, props.name)} color={'warning'} variant={'contained'} title={'Planowana nieobecnosć'}>
            <Warning /> {item.name} {item.surname}
          </MDButton>
        );
      }
    } else if (item.out !== null
            && item.in !== null
            && (new Date(item.in)).setHours(0,0,0,0) === (new Date()).setHours(0,0,0,0)) {
        if (props.showIn) {
            const out = new Date(item.out);
            return (
                <MDButton key={item.id} sx={{margin: '5px', cursor: 'default'}} color={'dark'} variant={'contained'} title={'Wyszedł'}>
                    <Home /> {out.getHours()}:{String(out.getMinutes()).padStart(2, '0')} {item.name} {item.surname}
                </MDButton>
            );
        }
    } else {
      return (
          <MDButton key={item.id} sx={{margin: '5px'}} onClick={() => props.in(item.id, props.name)} variant={'contained'} color={'secondary'}>
            {item.surname} {item.name}
          </MDButton>
      );
    }
    return ('');
  });
  const color = 1 ? [{sx: {backgroundColor: 'blue'}}] : [];
  return (
    <Grid item xs={12} sm={6} md={4} lg={3} xl={2} m={1} mt={4} flexGrow={1}>
      <Card>
        <MDBox bgColor="warning" variant={'gradient'} mt={-3} justifyContent="space-between"
               alignItems="center" borderRadius="md"
               display="flex" coloredShadow='light' >
          <MDTypography color={'white'} sx={{margin: '0 10px'}}>{props.name}</MDTypography>

            <Badge badgeContent={props.inPlace} color="primary">
            <MDButton key={props.id} sx={{margin: '5px'}} variant={'contained'} onClick={() => props.setDepartmentRoomChange(props.id)}>
                <RoomPreferences />
            </MDButton>
            </Badge>

        </MDBox>
        {members}
      </Card>
    </Grid>
  );
}

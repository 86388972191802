import React, {useContext, useEffect, useState} from "react";
// core components
import AuthContext from "../../services/AuthContext";
import {Add, Clear} from "@mui/icons-material";
import {Button} from "@mui/material";
import {DetailsList, SelectionMode} from "@fluentui/react";
import IconButton from "@mui/material/IconButton";
import Title from "../../Component/Title";
import AddMealType from "../../Panels/Meal/AddMealType";

function Meal() {
    const [user] = useContext(AuthContext);
    const [meal, setMeal] = useState([]);
    const [canModify, setCanModify] = useState(false);
    const [addMeal, setAddMeal] = useState(false);
    const [refresh, setRefresh] = useState(0);

    function _delete(id) {
        fetch(`/api/meal/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1)
            })
            .catch(err => { console.log(err) })
    }
    function _makeButtons({id}) {
        return canModify ? (<IconButton color={'error'} onDoubleClick={() => _delete(id)}><Clear /></IconButton>) : null;
    }

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/meal`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => { setCanModify(data.canModify); setMeal(data.meals) })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, refresh]);

    const columns = [
        {
            key: 'name',
            name: 'Posiłek',
            ariaLabel: 'Posiłek',
            fieldName: 'name',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 80,
            onRender: _makeButtons
        }
    ];

  return (
      <>
          <Title title={'Posiłki'} />
        {canModify && <Button onClick={() => setAddMeal(true)}><Add /> Dodaj Posiłek</Button>}
        <AddMealType isOpen={addMeal} dismiss={() => setAddMeal(false)} onSuccess={() => setRefresh(refresh + 1)} />
        <DetailsList items={meal} columns={columns} selectionMode={SelectionMode.none}/>
      </>
    );
}
export default Meal;
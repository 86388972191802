/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter, Route, Routes} from "react-router-dom";

// Material Dashboard 2 PRO React Context Provider
import {MaterialUIControllerProvider} from "context";
import {AuthProvider} from "./services/AuthContext";
import theme from "./assets/theme";
import LoginPage from "./views/Pages/LoginPage";
import Dashboard from "./views/Dashboard/Dashboard";
import mainRoutes from "./routes";
import facilityRoutes from "./facilityRoutes";
import memberRoutes from "./MemberRoutes";
import './index.css';
import './slider-ltr.css';
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./Layout/App";
import MemberLayout from "./Layout/MemberLayout";
import Tags from "./views/Dashboard/Tags";
import Contractor from "./views/Dashboard/Contractor";
import FacilityDashboard from "./views/Facility/FacilityDashboard";
import Department from "./views/Facility/Department";
import In from "./views/Facility/In";
import Out from "./views/Facility/Out";
import Settlement from "./views/Facility/Settlement";
import FacilityCost from "./views/Facility/Settlement/FacilityCost";
import MemberDetails from "./views/Facility/Member/MemberDetails";
import MemberData from "./views/Facility/Member/Details/MemberData";
import MemberFinances from "./views/Facility/Member/Details/MemberFinances";
import MemberPresence from "./views/Facility/Member/Details/MemberPresence";
import Costs from "./views/Facility/Settlement/Costs";
import Incomes from "./views/Facility/Settlement/Incomes";
import Diet from "./views/Dashboard/Diet";
import Meal from "./views/Dashboard/Meal";
import Billings from "./views/Facility/Settlement/Billings";
import Member from "./views/Facility/Member";
import Active from "./views/Facility/Member/Active";
import Archive from "./views/Facility/Member/Archive";
import Calendar from "./views/Facility/Calendar";
import Settings from "./views/Facility/Settings";
/*import {BrowserTracing} from "@sentry/tracing";
import * as Sentry from "@sentry/react";*/
import {initializeIcons} from "@fluentui/react/lib/Icons";
import General from "./views/Facility/Settings/General";
import FacilityMeal from "./views/Facility/Settings/FacilityMeal";
import MealRecord from "./views/Facility/MealRecord";
import MemberMeal from "./views/Facility/Member/Details/MemberMeal";
import Report from "./views/Facility/Report";
import ParticipationChart from "./views/Facility/Report/ParticipationChart";
import RegisterPage from "./views/Pages/RegisterPage";
import ResetPage from "./views/Pages/ResetPage";
import Presence from "./views/Member/Presence";
import MassPayments from "./views/Facility/Settings/MassPayments";
import Templates from "./views/Facility/Settings/Templates";
import MemberDocuments from "./views/Facility/Member/Details/MemberDocuments";
import NewPasswordPage from "./views/Pages/NewPasswordPage";
import ImportPayment from "./views/Dashboard/Import/ImportPayment";
import UserMemberBilling from "./views/Member/Finance/UserMemberBilling";
import TV from "./views/Facility/TV";
import Finance from "./views/Member/Finance";
import UserMemberPayments from "./views/Member/Finance/UserMemberPayments";
import BillingCallback from "./views/Member/BillingCallback";

/*Sentry.init({
    dsn: "https://195f5351f28046ed82664b6d731777e9@o4022.ingest.sentry.io/6765229",
    integrations: [new BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});*/

initializeIcons();

ReactDOM.render(
    <BrowserRouter>
        <MaterialUIControllerProvider>
            <AuthProvider>
                <ThemeProvider theme={theme}>
                    <CssBaseline />
                    <Routes>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/index.html" element={<LoginPage />} />
                        <Route path="/login/confirmed" element={<LoginPage />} />
                        <Route path="/login/expired" element={<LoginPage />} />
                        <Route path="/login/reseted" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/reset" element={<ResetPage />} />
                        <Route path="/new_password/:token" element={<NewPasswordPage />} />
                        <Route path="/" element={<App routes={mainRoutes}/>}>
                            <Route index element={<Dashboard />} />
                            <Route path="dashboard" element={<Dashboard />} />
                            <Route path="diet" element={<Diet />} />
                            <Route path="meal" element={<Meal />} />
                            <Route path="tags" element={<Tags />} />
                            <Route path="contractor" element={<Contractor />} />
                            <Route path="import" element={<ImportPayment />} />
                        </Route>
                        <Route path="/member/billing/callback" element={<BillingCallback/>} />
                        <Route path="/member/:id" element={<MemberLayout routes={memberRoutes}/>}>
                            <Route index element={<Presence />} />
                            <Route path="dashboard" element={<Presence />} />
                            <Route path="finances" element={<Finance />}>
                                <Route index element={<UserMemberBilling />} />
                                <Route path="billings" element={<UserMemberBilling />} />
                                <Route path="payments" element={<UserMemberPayments />} />
                            </Route>
                        </Route>
                        <Route path="/facility/:id" element={<App routes={facilityRoutes}/>}>
                            <Route index path="dashboard" element={<FacilityDashboard />} />
                            <Route path="department/:department" element={<Department />} />
                            <Route path="meal" element={<MealRecord />} />
                            <Route path="department/:department/member/:member" element={<MemberDetails />}>
                                <Route index element={<MemberData />} />
                                <Route path="data" element={<MemberData />} />
                                <Route path="finances" element={<MemberFinances />} />
                                <Route path="presence" element={<MemberPresence />} />
                                <Route path="meals" element={<MemberMeal />} />
                                <Route path="documents" element={<MemberDocuments />} />
                            </Route>
                            <Route path="member" element={<Member />}>
                                <Route index element={<Active />} />
                                <Route path="active" element={<Active />} />
                                <Route path="archive" element={<Archive />} />
                            </Route>
                            <Route path="in" element={<In />} />
                            <Route path="out" element={<Out />} />
                            <Route path="settlement" element={<Settlement />}>
                                <Route path="costs" element={<Costs />} />
                                <Route path="incomes" element={<Incomes />} />
                                <Route path="billings" element={<Billings />} />
                            </Route>
                            <Route path="calendar" element={<Calendar />} />
                            <Route path="report" element={<Report />} >
                                <Route index element={<ParticipationChart />} />
                                <Route path="participation" element={<ParticipationChart />} />
                            </Route>
                            <Route path="settings" element={<Settings />} >
                                <Route index element={<General />} />
                                <Route path="general" element={<General />} />
                                <Route path="meals" element={<FacilityMeal />} />
                                <Route path="templates" element={<Templates />} />
                                <Route path="accounts" element={<MassPayments />} />
                            </Route>

                            <Route path="settlement/costs/:cost" element={<FacilityCost />} />
                        </Route>
                        <Route path="/tv/:auth" element={<TV />} />
                    </Routes>
                </ThemeProvider>
            </AuthProvider>
        </MaterialUIControllerProvider>
    </BrowserRouter>,
    document.getElementById("root")
);
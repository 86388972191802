import React, {useMemo, useState} from "react";
import {Grid} from "@mui/material";
import Title from "../../Component/Title";
import Subnavigation from "../../Component/Subnavigation";
import {Outlet} from "react-router-dom";
import Divider from "@mui/material/Divider";
import SearchBox from "../../Component/SearchBox";

function Member() {
    const [query, setQuery] = useState("");
    const subNav = useMemo(() => <Subnavigation items={[
        {to: 'active', name: 'Aktywni', color: 'info'},
        {to: 'archive', name: 'Archiwum', color: 'secondary'}
    ]}/>, []);
    return (<>
            <Title title={'Uczestnicy'}/>
            <Grid container justifyContent={'space-between'} mb={3}>
                {subNav}
                <SearchBox query={query} setQuery={setQuery}/>
            </Grid>
            <Divider/>
            <Outlet context={query}/>
        </>
    )
}

export default Member;
import React, {useState} from "react";
import {Grid} from "@mui/material";
import Title from "../../Component/Title";
import dbMonth from "../../Utilities/dbMonth";
import Subnavigation from "../../Component/Subnavigation";
import {Outlet} from "react-router-dom";
import MonthPicker from "../../Component/MonthPicker";

function Settlement() {
    const [month, setMonth] = useState(new Date());
    const subtitle = (<Grid container justifyContent={'space-between'} mb={3}>
        <Subnavigation items={[
            {to: 'incomes', name: 'Przychody', color: 'success'},
            {to: 'billings', name: 'Rachunki', color: 'info'},
            {to: 'costs', name: 'Koszty', color: 'warning'}
        ]} />
        <Grid item lg={2}>
            <MonthPicker value={month} onChange={(e) => setMonth(e)}/>
        </Grid>
    </Grid>);

    return (<>
            <Title title={'Rozliczenia'} subtitle={subtitle}/>
            <Outlet context={dbMonth(month)} />
        </>
    )
}
export default Settlement;
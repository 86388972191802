import {
    CalendarViewMonth,
    Euro,
    Insights,
    MeetingRoom,
    NoMeetingRoom,
    People,
    Restaurant,
    Settings,
    Store, Tv,
} from "@mui/icons-material";

let facilityRoutes = [
    {
        type: "header",
        route: "dashboard",
        key: "dashboard",
        name: "Placówka",
        icon: <Store />
    },
    {
        type: "header",
        route: "member/active",
        level: 2,
        key: "member",
        name: "Uczestnicy",
        icon: <People />
    },
    {
        type: "header",
        route: "in",
        key: "in",
        name: "Wejścia",
        icon: <MeetingRoom />,
    },
    {
        type: "header",
        route: "out",
        key: "out",
        name: "Wyjścia",
        icon: <NoMeetingRoom />,
    },
    {
        type: "header",
        route: "meal",
        key: "meal",
        name: "Posiłki",
        icon: <Restaurant />,
    },
    {
        type: "header",
        route: "settlement/costs",
        level: 2,
        key: "settlement",
        name: "Rozliczenia",
        icon: <Euro />
    },
    {
        type: "header",
        route: "calendar",
        level: 2,
        key: "calendar",
        name: "Kalendarz",
        icon: <CalendarViewMonth />
    },
    {
        type: "header",
        route: "report",
        level: 2,
        key: "report",
        name: "Raporty",
        icon: <Insights />
    },
    {
        type: "header",
        route: "settings",
        level: 2,
        key: "settings",
        name: "Ustawienia",
        icon: <Settings />
    }
];
export default facilityRoutes;

import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import {useParams} from "react-router-dom";
import dbDate from "../../Utilities/dbDate";
import StyledModal from "../../StyledComponents/StyledModal";
import StyledSelect from "../../StyledComponents/StyledSelect";
import DateInput from "../../StyledComponents/DateInput";
import MDInput from "../../components/MDInput";


function AddIncome(props) {
    const title = 'Dodaj Przychód';
    const description = 'Możesz tutaj zdefiniować przychód i oznaczyć jego typ - dzięki czemu koszty będą mogły wykorzystywać wartości tego przychodu.';

    const [user] = useContext(AuthContext);
    const params = useParams();

    const [date, setDate] = useState('');
    const [value, setValue] = useState('');
    const [note, setNote] = useState('');
    const [incomeType, setIncomeType] = useState(null);
    const [member, setMember] = useState(null);
    const [incomeTypeList, setIncomeTypeList] = useState([]);
    const [members, setMembers] = useState([]);
    const [showMembers, setShowMembers] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);

    function _send() {
        const formData = {
            date: dbDate(date),
            value: value,
            income_type_id: incomeType.id ?? incomeType,
            member: member?.id ?? member,
            note: note
        };
        fetch(`/api/facility/${params.id}/income`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }



    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/income_type`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(setIncomeTypeList)
                .catch(err => {
                    console.log(err)
                })
        }
        async function fetchMembers() {
            await fetch(`/api/facility/${params.id}/members`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(setMembers)
                .catch(err => {
                    console.log(err)
                })
        }
        fetchData();
        fetchMembers();
        return () => abortController.abort();
    }, [user]);

    useEffect(() => {
        if (typeof props.isOpen !== 'boolean' && !isDisabled) {
            setShowMembers(true);
            setIncomeType(incomeTypeList.filter((item) => item?.from_member)[0].id);
            setMember(props.isOpen);
            setIsDisabled(true);
        } else if (!isDisabled) {
            setMember(null);
            if (incomeType !== null) {
                const income = incomeTypeList.filter((item) => item?.id === incomeType.id)[0];
                setShowMembers(Boolean(income?.from_member));
            } else {
                setShowMembers(false);
            }
        } else if (typeof props.isOpen === 'boolean')  {
            setIsDisabled(false);
        }
    }, [incomeType, props.isOpen])

    return (
        <StyledModal isOpen={props.isOpen} title={title} description={description} send={_send} dismiss={props.dismiss}>
            <MDInput
                onChange={(e) => setValue(e.target.value)}
                label='Wartość'
                fullWidth
                sx={{marginBottom: 2}}
                value={value}

            />
            <DateInput value={date} onChange={(e) => setDate(e)} label='Data'/>
            <StyledSelect
                name='income_type'
                label='Typ Przychodu'
                value={incomeType}
                onChange={(e, v) => setIncomeType(v)}
                items={incomeTypeList}
                disabled={isDisabled}
            />
            {showMembers && <StyledSelect
                name='member'
                label='Opłata za'
                value={member}
                onChange={(e, v) => setMember(v)}
                items={members.map(item => ({id: item.id, name: `${item.name} ${item.surname} - ${item.department}`}))}
                disabled={isDisabled}
            />}
            <MDInput
                onChange={(e) => setNote(e.target.value)}
                label='Notatka'
                fullWidth
                sx={{marginBottom: 2}}
                value={note}
                multiline
                rows={3}
            />
        </StyledModal>
    );
}

export default AddIncome;
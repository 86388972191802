import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import MDInput from "../../components/MDInput";


function EditExtras(props) {
    const title = 'Edytuj Opłatę';
    const description = 'Tutaj możesz edytować opłatę uczestnika naliczoną przez system.';

    const [user] = useContext(AuthContext);

    const [cost, setCost] = useState('');
    const [note, setNote] = useState('');

    function _send() {
        const formData = {
            cost: cost,
            note: note,
        };
        fetch(`/api/member/extras/${props.data.id}`, {
            method: 'PUT',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        setNote(props.data.note);
        setCost(props.data.cost);
    }, [props.data])

    return (
        <StyledModal isOpen={props.data !== false} title={title} description={description} send={_send} dismiss={props.dismiss} actionLabel={'Edytuj'}>
            <MDInput
                value={note}
                onChange={(e) => setNote(e.target.value)}
                label='Notatka'
                fullWidth
                sx={{marginBottom: 2}}
            />
            <MDInput
                onChange={(e) => setCost(e.target.value)}
                label='Opłata'
                fullWidth
                sx={{marginBottom: 2}}
                value={cost}
                type={'number'}
                min={0}
            />
        </StyledModal>
    );
}

export default EditExtras;
import React, {useContext, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import MDInput from "../../components/MDInput";
import {FormControlLabel, Switch} from "@mui/material";


function AddBillingSplit(props) {
    const title = 'Dodaj Podział Rozliczeń';
    const description = 'Tutaj możesz dodać regułę podzialu rozliczenia rachunku dziecka, np. dla MOPS lub GOPS';

    const [user] = useContext(AuthContext);
    const [name, setName] = useState('');
    const [account, setAccount] = useState(false);

    function _send() {
        const formData = {
            name: name,
            account: account ? account : null,
            member_id: props.data
        };
        fetch(`/api/member/${props.data}/billing/split`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess(data);
                props.dismiss();
            })
            .catch(err => { console.log(err) })
    }

    return (<StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss}>
                <MDInput
                    onChange={(e) => setName(e.target.value)}
                    label='Nazwa'
                    value={name}
                    variant="standard"
                    fullWidth
                    sx={{marginBottom: 2}}
                />
                <FormControlLabel
                    control={
                        <Switch
                            checked={account}
                            onChange={(event) => setAccount(event.target.checked)}
                            value=""
                        />
                    }
                    label="Konto Indywidualne"
                />
                {account && <MDInput
                    onChange={(e) => setAccount(e.target.value)}
                    label='Indywidualne Konto'
                    value={name}
                    variant="standard"
                    fullWidth
                    sx={{marginBottom: 2}}
                />}
        </StyledModal>);
}
export default AddBillingSplit;
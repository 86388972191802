/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// Material Dashboard 2 PRO React components
import MDInput from "components/MDInput";
import dbDate from "../../Utilities/dbDate";
import { Polish } from "flatpickr/dist/l10n/pl.js"

function MDDatePicker({ input, ...rest }) {
  if (rest.options !== undefined ) {
    rest.options['locale'] = Polish;
  } else {
    rest['options'] = {locale: Polish};
  }
  return (
    <Flatpickr
        {...rest}
        render={({ defaultValue, value }, ref) => (
          <MDInput {...input} value={rest.options['mode'] === 'range' ? defaultValue : dbDate(value)} inputRef={ref} />
      )}
    />
  );
}

// Setting default values for the props of MDDatePicker
MDDatePicker.defaultProps = {
  input: {},
};

// Typechecking props for the MDDatePicker
MDDatePicker.propTypes = {
  input: PropTypes.objectOf(PropTypes.any),
};

export default MDDatePicker;

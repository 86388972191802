import React, {useContext, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import dbDate from "../../Utilities/dbDate";
import DateInput from "../../StyledComponents/DateInput";
import MDInput from "../../components/MDInput";
import {FormControlLabel, Stack, Switch} from "@mui/material";

function AddAllowed(props) {
    const title = 'Dodaj Upoważnienie';
    const description = 'Tutaj możesz dodać osobe upoważnioną do odbioru.';

    const [user] = useContext(AuthContext);
    const [fullname, setFullname] = useState('');
    const [document, setDocument] = useState('');
    const [kinship, setKinship] = useState('');
    const [contact, setContact] = useState('');
    const [email, setEmail] = useState('');
    const [billing, setBilling] = useState(false);
    const [collect, setCollect] = useState(true);
    const [agreement, setAgreement] = useState(false);
    const [personalId, setPersonalId] = useState('');
    const [address, setAddress] = useState('');
    const [valid, setValid] = useState(null);

    function _send() {
        const formData = {
            fullname: fullname,
            document: document,
            kinship: kinship,
            contact: contact,
            email: email,
            billing: billing,
            collect: collect,
            agreement: agreement,
            personal_id: personalId,
            address: address,
            valid: dbDate(valid)
        };
        fetch(`/api/member/${props.data}/allowed`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss}
                     actionLabel='Dodaj' fullWidth={true} maxWidth={'md'}>
            <Stack direction={'row'} spacing={2}>
                <Stack flexGrow={2} spacing={2}>
                    <MDInput
                        onChange={(e, v) => setFullname(e.target.value)}
                        value={fullname}
                        label='Imię i Nazwisko'
                        fullWidth
                    />
                    <MDInput
                        onChange={(e, v) => setDocument(e.target.value)}
                        value={document}
                        label='Dokument'
                        fullWidth
                    />
                    <MDInput
                        onChange={(e, v) => setKinship(e.target.value)}
                        value={kinship}
                        label='Pokrewieństwo'
                        fullWidth

                    />
                    <FormControlLabel sx={{height: '40px'}}
                        control={
                            <Switch
                                checked={collect}
                                onChange={(event) => setCollect(event.target.checked)}
                                value="collect"
                            />
                        }
                        label="Odbierający"
                    />
                    <DateInput label={'Ograniczenie czasowe'} value={valid} onChange={(e) => setValid(e)} sx={''}/>

                </Stack>
                <Stack flexGrow={1} spacing={2}>
                    <MDInput
                        onChange={(e, v) => setContact(e.target.value)}
                        value={contact}
                        label='Telefon'
                        fullWidth
                    />
                    <MDInput
                        onChange={(e, v) => setEmail(e.target.value)}
                        value={email}
                        label='Email'
                        fullWidth
                    />
                    <Stack direction={'row'}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={billing}
                                onChange={(event) => setBilling(event.target.checked)}
                                value="billing"
                            />
                        }
                        label="Przesyłaj Billing"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={agreement}
                                onChange={(event) => setAgreement(event.target.checked)}
                                value="agreement"
                            />
                        }
                        label="Umowa"
                    />
                    </Stack>
                    {agreement > 0 &&
                        <>
                            <MDInput
                            onChange={(e, v) => setPersonalId(e.target.value)}
                            value={personalId}
                            label='Pesel'
                            fullWidth
                            /><MDInput
                                onChange={(e, v) => setAddress(e.target.value)}
                            value={address}
                            label='Adres'
                            fullWidth
                            />
                        </>
                    }

                </Stack>
            </Stack>
        </StyledModal>
    );
}

export default AddAllowed;
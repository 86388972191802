import subtractDays from "./subtractDays";

function getRGB(c) {
    return parseInt(c, 16) || c
}

function getsRGB(c) {
    return getRGB(c) / 255 <= 0.03928
        ? getRGB(c) / 255 / 12.92
        : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4)
}

function getLuminance(hexColor) {
    return (
        0.2126 * getsRGB(hexColor.substr(1, 2)) +
        0.7152 * getsRGB(hexColor.substr(3, 2)) +
        0.0722 * getsRGB(hexColor.substr(-2))
    )
}

function getContrast(f, b) {
    const L1 = getLuminance(f)
    const L2 = getLuminance(b)
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05)
}

function getTextColor(bgColor) {
    const r = getRGB(bgColor.substr(1, 2));
    const g = getRGB(bgColor.substr(3, 2));
    const b = getRGB(bgColor.substr(-2));
/*    const whiteContrast = getContrast(bgColor, '#ffffff')
    const blackContrast = getContrast(bgColor, '#000000')*/
    return ((r*0.299 + g*0.587 + b*0.114) > 150) ? '#000000' : '#ffffff';
    //return whiteContrast > blackContrast ? '#ffffff' : '#000000'
}

export default getTextColor;
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../services/AuthContext";
import {useParams, Outlet} from "react-router-dom";
import {Grid} from "@mui/material";
import Title from "../../../Component/Title";
import Subnavigation from "../../../Component/Subnavigation";

function MemberDetails(props) {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [member, setMember] = useState(null);
    const [summary, setSummary] = useState('');
    const [refresh, setRefresh] = useState(0);

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/member/${params.member}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setMember(data);
                })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, params.id, params.member, refresh]);

    const subtitle = (<Grid container justifyContent={'space-between'} mb={3}>
        <Subnavigation items={[
            {to: 'data', name: 'Dane', color: 'info'},
            {to: 'finances', name: 'Finanse', color: 'info', disabled: !member?.finances},
            {to: 'presence', name: 'Obecność', color: 'info'},
            {to: 'meals', name: 'Posiłki', color: 'info'},
            {to: 'documents', name: 'Dokumenty', color: 'info'},
        ]} defaultActive={'data'}/>
        <Grid item lg={2}>
            {summary}
        </Grid>
    </Grid>);

    const title = member ? `${member.member[0].name} ${member.member[0].surname}` : 'Dziecko';

    return (
        <>
            <Title title={title} subtitle={subtitle} />
            <Outlet context={{refreshMember: () => setRefresh(refresh + 1), setSummary: setSummary, data: member}}/>
        </>
    );
}

export default MemberDetails;
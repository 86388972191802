import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import {useParams} from "react-router-dom";
import StyledModal from "../../StyledComponents/StyledModal";
import StyledSelect from "../../StyledComponents/StyledSelect";
import MDInput from "../../components/MDInput";


function AddCost(props) {
    const title = 'Rozlicz Koszt';
    const description = 'Tutaj możesz rozliczyć koszt. Tag definiuje podział kosztów i ich wykorzystanie typu przychodu. Po utworzeniu koszt można rozbić na różne tagi.';

    const [user] = useContext(AuthContext);
    const params = useParams();

    const [value, setValue] = useState('');
    const [tag, setTag] = useState('');
    const [incomeTypeTarget, setIncomeTypeTarget] = useState('');

    function _send() {
        const formData = {
            value: value,
            tag_id: tag.id,
            income_type_target: incomeTypeTarget.id,
        };
        fetch(`/api/facility/cost/${params.cost}`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    const [tagList, setTagList] = useState([]);
    const [incomeTargets, setIncomeTargets] = useState([]);
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/tag`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setIncomeTargets(data.incomes);
                    setTagList(data.tags.map(item => {
                        item.name = `${item.tag}`;
                        return item;
                    }))
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user]);

    return (
        <StyledModal isOpen={props.add} title={title} description={description} send={_send} dismiss={props.dismiss}>
            <MDInput
                onChange={(e) => setValue(e.target.value)}
                label='Wartość'
                fullWidth
                sx={{marginBottom: 2}}
                value={value}
                type={'number'}
                max={props.add.value}
                min={0}
            />
            <StyledSelect
                name='income_type_target'
                label='Kategoria dochodu'
                value={incomeTypeTarget}
                onChange={(e, v) => setIncomeTypeTarget(v)}
                items={incomeTargets}
            />
            <StyledSelect
                name='tag'
                label='Tag'
                value={tag}
                onChange={(e, v) => setTag(v)}
                items={tagList}
            />
        </StyledModal>
    );
}

export default AddCost;
import React, {useContext, useEffect, useMemo, useState} from "react";
import AuthContext from "../../services/AuthContext";
import {Link, useLocation} from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import NavLink from "react-router-dom";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import bgImage from "splash.jpg";
import bgImage2 from "splash2.jpg";
import bgImage3 from "splash3.jpg";
import {useNavigate} from "react-router-dom";
import SplashScreen from "../../Layout/SplashScreen";
import {Toggle} from "@fluentui/react";
import {Alert} from "@mui/material";
import jwtDecode from "jwt-decode";

export default function LoginPage() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [flash, setFlash] = useState('');
    const [user, setUser] = useContext(AuthContext);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    useEffect(() => {
        if (user.token !== "") {
            if (user.facilities.length === 1 && user.members.length === 0) {
                if (user.facilities[0].roles === 'ENTRANCE') {
                    navigate("/facility/" + user.facilities[0].id + '/in');
                } else {
                    navigate("/facility/" + user.facilities[0].id + '/dashboard');
                }
            } else if (user.facilities.length === 0 && user.members.length === 1) {
                navigate("/member/" + user.members[0].id);
            } else {
                navigate("/dashboard");
            }
        }
    }, [user.token])

    useEffect(() => {
        if (location.pathname.endsWith('confirmed')) {
            setFlash(<Alert severity="success">Potwierdzono rejestrację konta w systemie.</Alert>);
        } else if (location.pathname.endsWith('expired')) {
            setFlash(<Alert severity="error">Link potwierdzający wygasł. Prosimy o ponowną rejestrację konta.</Alert>);
        } else if (location.pathname.endsWith('reseted')) {
            setFlash(<Alert severity="error">Zresetowano hasło. Zaloguj się na nowe dane.</Alert>);
        }
    }, [location])

    const authenticate = (e) => {
        if (!loading) {
            setLoading(true);
            e.preventDefault();
            const sentData = {username: username, password: password};

            fetch("/api/login_check", {
                cache: "no-cache",
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(sentData),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw Error(response.statusText);
                    }
                    response.json().then(function (data) {
                        const jwt  = jwtDecode(data.token);
                        fetch("/api/actions", {
                            cache: "no-cache",
                            method: "get",
                            headers: {
                                'Authorization': 'Bearer ' + data.token
                            },
                        }).then((response) => {
                            if (!response.ok) {
                                throw Error(response.statusText);
                            }
                            response.json().then(function (actions) {
                                const user = {username: sentData.username, token: data.token, facilities: actions.facilities, members: actions.members};
                                setUser(user);
                                setLoading(false);
                            }).catch((error) => {
                                setFlash(<Alert severity="error">Aplikacja niedostępna, proszę spróbować później.</Alert>);
                                console.log(error.message);
                                setLoading(false);
                            });
                    });
                })})
                .catch((error) => {
                    setFlash(<Alert severity="error">Niepoprawny login lub hasło.</Alert>);
                    console.log(error.message);
                    setLoading(false);
                });
        }

    };

    function getRandomIntInclusive(min, max) {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const images = [bgImage, bgImage2, bgImage3];

    const bg = useMemo(
        () => images[getRandomIntInclusive(0, 0)],
        [bgImage, bgImage2, bgImage3],
    );

    return (
        <SplashScreen background={bg} title={'Kidplace'}>
            <Card>
                <MDBox
                    variant="gradient"
                    bgColor="dark"
                    borderRadius="md"
                    coloredShadow="dark"
                    mx={2}
                    mt={-3}
                    p={2}
                    mb={1}
                    textAlign="center"

                >
                    <MDTypography variant="h5" fontWeight="light" color="white">
                        Cześć!
                    </MDTypography>
                </MDBox>
                {flash}
                <MDBox pt={4} pb={2} px={3}>
                    <MDBox component="form" role="form" onSubmit={authenticate}>
                        <MDBox mb={2}>
                            <MDInput type="email" autoComplete="username" label="Email" value={username}
                                     onChange={(e) => setUsername(e.target.value)} fullWidth/>
                        </MDBox>
                        <MDBox mb={2}>
                            <MDInput type="password" autoComplete="current-password" label="Hasło" value={password}
                                     onChange={(e) => setPassword(e.target.value)} fullWidth/>
                        </MDBox>
                        <MDBox sx={{justifyContent: 'space-around', display: 'flex'}}>
                            <Toggle inlineLabel={true} label={'Zapamiętaj Mnie'} disabled/>
                            <Link to={`/reset`}><MDButton variant={'text'} color="dark" sx={{textAlign: 'right'}} size={'small'}>Nie pamiętasz hasła?</MDButton></Link>
                        </MDBox>
                        <MDBox mt={2} mb={1}>
                            <MDButton disabled={loading} variant="text" size={'large'} color="info" variant={'gradient'} fullWidth type={'submit'}>
                                Zaloguj
                            </MDButton>
                        </MDBox>
                        <MDBox sx={{textAlign: 'center'}}>
                            <MDTypography variant={'h6'} fontWeight="light" color="dark">Nie masz konta? <Link to={`/register`}><MDButton variant={'text'} color="info" size={'small'}>Zarejestruj się!</MDButton></Link></MDTypography>
                        </MDBox>
                    </MDBox>
                </MDBox>
            </Card>


        </SplashScreen>
    );
}

import React from "react";
import {Grid} from "@mui/material";
import Title from "../../Component/Title";
import {Outlet} from "react-router-dom";
import Subnavigation from "../../Component/Subnavigation";

function Report() {
    const subtitle = (<Grid container justifyContent={'space-between'} mb={3}>
        <Subnavigation items={[
            {to: 'participation', name: 'Obłożenie', color: 'info'}
        ]} defaultActive={'participation'} />
    </Grid>);

    return (<>
            <Title title={'Raporty'} subtitle={subtitle}/>
            <Outlet />
        </>
    )
}
export default Report;
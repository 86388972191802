import React from "react";
import MDDatePicker from "../components/MDDatePicker";


export default function DateRangeFlatpicker({label, pickerOptions, ...props}) {
    return (
            <MDDatePicker options={{
                mode: "range",
                dateFormat: "Y-m-d",
                ...pickerOptions
            }} {...props} input={{fullWidth: true, label: label, sx: {marginTop: '8px', marginBottom: '8px'}}}/>
    );
}
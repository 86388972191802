import React, {useContext, useEffect, useState} from "react";
// core components
import AuthContext from "../../services/AuthContext";
import {Add, Cancel, Clear, Delete, Remove} from "@mui/icons-material";
import {Button, Grid} from "@mui/material";
import {DetailsList, SelectionMode} from "@fluentui/react";
import IconButton from "@mui/material/IconButton";
import Title from "../../Component/Title";
import AddMealType from "../../Panels/Meal/AddMealType";
import AddAbsence from "../../Panels/Member/AddAbsence";
import {useParams} from "react-router-dom";
import dbMonth from "../../Utilities/dbMonth";
import MonthPicker from "../../Component/MonthPicker";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";

function Presence() {
    const [user] = useContext(AuthContext);
    const params = useParams();
    const [attendance, setAttendance] = useState([]);
    const [addAbsence, setAddAbsence] = useState(false);
    const [refresh, setRefresh] = useState(0);
    const [month, setMonth] = useState(new Date());
    const [timeframe, setTimeframe] = useState(0);

    const member = user.members.filter(item => {
        return item.id == params.id
    })[0];

    function _delete(id) {
        fetch(`/api/user/member/absence/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1)
            })
            .catch(err => {
                console.log(err)
            })
    }

    function _makeButtons({id}) {
        return (<IconButton color={'error'} onDoubleClick={() => _delete(id)}><Clear/></IconButton>);
    }

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchData() {
            await fetch(`/api/user/member/${params.id}/presence/${dbMonth(month)}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setAttendance(data);
                    setTimeframe(data[0]['absence_timeframe']);
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user, refresh, month, params.id]);

    const columns = [
        {
            key: 'date',
            name: 'Data',
            ariaLabel: 'Data',
            fieldName: 'date',
            minWidth: 80,
            isResizable: true,
            onRender: (item) => {return item.in.substring(0, 10);}
        },
        {
            key: 'attendance',
            name: 'Obecność',
            ariaLabel: 'Obecność',
            fieldName: 'attendance',
            minWidth: 140,
            isResizable: true,
            onRender: (item) => {
                const inFuture = new Date(item.in).getTime() > new Date().getTime();
                if (item.id) {
                    const out = item.out !== null ? item.out?.substring(11, 16) : '';
                    return `${item.in?.substring(11, 16)} -> ${out}`;
                } else if (item.absence !== null) {
                    if (inFuture) {
                        return <MDButton color={'warning'} onClick={() => _delete(item.absence)}><Clear /> Nieobecny</MDButton>
                    }
                    return <MDButton color={'warning'} disabled>Nieobecny</MDButton>
                } else if (inFuture) {
                    return null;
                }
                return <MDButton color={'error'} disabled>Niezgłoszono</MDButton>

            }
        },
        {
            key: 'allowed_out',
            name: 'Odbierający',
            ariaLabel: 'Odbierający',
            fieldName: 'allowed_out',
            minWidth: 120,
            isResizable: true,
        },
        {
            key: 'stay',
            name: 'Pobyt',
            ariaLabel: 'Pobyt',
            fieldName: 'stay',
            minWidth: 60,
            isResizable: true,
        },
        {
            key: 'food',
            name: 'Wyżywienie',
            ariaLabel: 'Wyżywienie',
            fieldName: 'food',
            minWidth: 80,
            isResizable: true,
        },
        {
            key: 'other',
            name: 'Inne',
            ariaLabel: 'Inne',
            fieldName: 'other',
            minWidth: 50,
            isResizable: true,
        }
    ];

    return (
        <>
            <Title title=''
                   subtitle={<Grid container justifyContent={'space-around'} alignItems={'center'} mb={2}>
                       <Grid item>Obecności</Grid>
                       <Grid item><MonthPicker value={month} onChange={(e) => setMonth(e)}/></Grid>
            </Grid>} memberMode={true}/>
            <Button onClick={() => setAddAbsence({id: params.id, timeframe: timeframe})}><Add/> Zgłoś Nieobecność</Button>
            <AddAbsence data={addAbsence} dismiss={() => setAddAbsence(false)}
                        onSuccess={() => setRefresh(refresh + 1)} isSelf={true}/>
            <DetailsList items={attendance} columns={columns} selectionMode={SelectionMode.none}/>
        </>
    );
}

export default Presence;
import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import {useParams} from "react-router-dom";
import StyledModal from "../../StyledComponents/StyledModal";
import StyledSelect from "../../StyledComponents/StyledSelect";

function AddTemplate(props) {
    const title = 'Dodaj Wzór Dokumentu';
    const description = 'Tutaj możesz dodać wzór dokumentu do generowania i wybrać metodę jego obróbki przy generowaniu.';

    const [user] = useContext(AuthContext);
    const params = useParams();
    const [file, setFile] = useState(null);
    const [type, setType] = useState(null);
    const [types, setTypes] = useState([]);
    const [processor, setProcessor] = useState(null);
    const [processors, setProcessors] = useState([]);
    const [refresh, setRefresh] = useState(0);

    function _send() {
        const data = new FormData();
        data.append('document_type_id', type.id);
        data.append('processor', processor.id);
        data.append('file', file);

        fetch(`/api/facility/${params.id}/template`, {
            method: 'POST',
            body: data,
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        setType(null);
        setProcessor(null);
        const abortController = new AbortController();
        if (props.add) {
            async function fetchTypes() {
                await fetch(`/api/document`, {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                        'Authorization': 'Bearer ' + user.token
                    }
                }).then(res => res.json())
                    .then(data => {
                        setTypes(data.map((item) => {
                            return {id: item.id, name: item.type};
                        }))
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            async function fetchProcessors() {
                await fetch(`/api/template/processor`, {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                        'Authorization': 'Bearer ' + user.token
                    }
                }).then(res => res.json())
                    .then(data => {
                        setProcessors(data.map((item) => {
                            return {id: item, name: item};
                        }))
                    })
                    .catch(err => {
                        console.log(err)
                    })
            }
            fetchTypes();
            fetchProcessors();
            return () => abortController.abort();
        }
    }, [props.add])

    return (
        <StyledModal isOpen={props.add} title={title} description={description} send={_send} dismiss={props.dismiss}>
            <StyledSelect
                name='type'
                label='Typ Dokumentu'
                value={type}
                onChange={(e, v) => setType(v)}
                items={types}
                required
            />
            <StyledSelect
                name='processor'
                label='Processor'
                value={processor}
                onChange={(e, v) => setProcessor(v)}
                items={processors}
                required
            />
            <input type="file" name="file" onChange={(e) => setFile(e.target.files[0])}/>
        </StyledModal>
    );
}

export default AddTemplate;
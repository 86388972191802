import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../services/AuthContext";
import {useParams} from "react-router-dom";
import AddCost from "../../../Panels/Settlement/AddCost";
import ActionButton from "../../../StyledComponents/ActionButton";
import {Delete, Edit} from "@mui/icons-material";
import {Button, Grid} from "@mui/material";
import MDBox from "../../../components/MDBox";
import {DetailsList, SelectionMode} from "@fluentui/react";
import Title from "../../../Component/Title";

function FacilityCost(props) {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [facilityCost, setFacilityCost] = useState({});
    const [costs, setCosts] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [addCost, setAddCost] = useState(false);

    function _delete(id) {
        fetch(`/api/cost/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1);
            })
            .catch(err => { console.log(err) })
    }
    function _makeButtons(id) {
            return (<div>
                <ActionButton disabled icon={<Edit />}/>
                <ActionButton color={'error'} onDoubleClick={() => _delete(id)} icon={<Delete />} />
            </div>);
    }

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/cost/${params.cost}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => { setFacilityCost(data.header); setCosts(data.costs); })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, params.cost, refresh]);

    const done = Math.ceil(costs.reduce((pv, cv) => pv + parseFloat(cv.value), 0) / parseFloat(facilityCost.value) * 100) + '%'
    const left = parseFloat(facilityCost.value) - costs.reduce((pv, cv) => pv + parseFloat(cv.value), 0);
    const isDone = done === '100%' ? 'green' : 'orangered';
    const columns = [
        {
            key: 'income_type',
            name: 'Typ',
            ariaLabel: 'Typ',
            fieldName: 'income_type',
            minWidth: 200,
            maxWidth: 600,
            isResizable: true,
        },
        {
            key: 'tag',
            name: 'Tag',
            ariaLabel: 'Tag',
            fieldName: 'tag',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'value',
            name: 'Wartość',
            ariaLabel: 'Wartość',
            fieldName: 'value',
            minWidth: 200,
            isResizable: true,
        },

        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 120,
            onRender: (item) => _makeButtons(item.id)
        }
    ];
    const subtitle = (<Grid container justifyContent={'space-between'} alignItems={'center'}>
        <MDBox>{facilityCost.date}</MDBox>
        <MDBox>{facilityCost.contractor}</MDBox>
        <MDBox sx={{ fontSize: 32, textAlign: 'right', color: isDone }}>{done} (Pozostało: {left})</MDBox>
    </Grid>);
    return (
        <>
            <Title title={facilityCost.invoice_number} subtitle={subtitle} />
            <Button onClick={() => setAddCost(true)}> Rozlicz Koszt</Button>

            <AddCost add={addCost} dismiss={() => setAddCost(false)} onSuccess={() => setRefresh(refresh + 1)} />
            <DetailsList selectionMode={SelectionMode.none} items={costs} columns={columns} />
        </>
    );
}

export default FacilityCost;
import React, {useContext, useEffect, useState} from "react";
import {Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import AuthContext from "../../../services/AuthContext";
import {DetailsList, SelectionMode} from "@fluentui/react";
import dbDate from "../../../Utilities/dbDate";
import DatePickerPL from "../../../Component/DatePickerPL";
import subtractDays from "../../../Utilities/subtractDays";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js'
import { Line } from 'react-chartjs-2';
import Colors from "../../../assets/theme/base/colors";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function ParticipationChart() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [from, setFrom] = useState(subtractDays(7));
    const [to, setTo] = useState(new Date());
    const [values, setValues] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/chart/participation/${dbDate(from)}/${dbDate(to)}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => { setValues(data) })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, from, to, params.id]);

    const subtitle = (<Grid container justifyContent={'space-between'} mb={3}>
        <Grid item lg={2}>
            <DatePickerPL borderless value={from} onSelectDate={(e) => setFrom(e)}/>
            <DatePickerPL borderless value={to} onSelectDate={(e) => setTo(e)}/>
        </Grid>
    </Grid>);

    const columns = [
        {
            key: 'time',
            name: 'Godzina',
            ariaLabel: 'Godzina',
            fieldName: 'time',
            minWidth: 100,
            isRowHeader: true
        },
        {
            key: 'min',
            name: 'Min',
            ariaLabel: 'Min',
            fieldName: 'min',
            minWidth: 80
        },
        {
            key: 'max',
            name: 'Max',
            ariaLabel: 'Max',
            fieldName: 'max',
            minWidth: 80
        },
        {
            key: 'avg',
            name: 'Średnio',
            ariaLabel: 'Średnio',
            fieldName: 'avg',
            minWidth: 100
        },
        {
            key: 'declared',
            name: 'Deklarowane',
            ariaLabel: 'Deklarowane',
            fieldName: 'declared',
            minWidth: 130
        }
    ];

    return (<>
            {subtitle}
            <Line
                datasetIdKey='id'
                data={{
                    labels: values.map(i => i.time),
                    datasets: [
                        {
                            id: 1,
                            label: 'Średnia',
                            data: values.map(i => i.avg),
                            borderColor: Colors.info.main,
                            backgroundColor: Colors.info.focus,
                        },
                        {
                            id: 2,
                            label: 'Min',
                            data: values.map(i => i.min),
                            borderColor: Colors.secondary.main,
                            backgroundColor: Colors.secondary.focus,
                        },
                        {
                            id: 3,
                            label: 'Max',
                            data: values.map(i => i.max),
                            borderColor: Colors.warning.main,
                            backgroundColor: Colors.warning.focus,
                        },
                        {
                            id: 4,
                            label: 'Deklarowane',
                            data: values.map(i => i.declared),
                            borderColor: Colors.primary.main,
                            backgroundColor: Colors.primary.focus,
                        },
                    ],
                }}
            />
            <DetailsList selectionMode={SelectionMode.none} items={values} columns={columns} />
        </>
    )
}
export default ParticipationChart;
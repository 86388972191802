import React, {useContext, useEffect, useState} from "react";
// core components
import AuthContext from "../../services/AuthContext";
import AddContractor from "../../Panels/Contractor/AddContractor";
import EditContractor from "../../Panels/Contractor/EditContractor";
import {Button } from "@mui/material";
import {Add, Delete, Edit} from "@mui/icons-material";
import {DetailsList, SelectionMode} from "@fluentui/react";
import Title from "../../Component/Title";

function Contractor() {
    const [user] = useContext(AuthContext);
    const [contractor, setContractor] = useState([]);
    const [canModify, setCanModify] = useState(false);
    const [addContractor, setAddContractor] = useState(false);
    const [editContractor, setEditContractor] = useState(false);
    const [refresh, setRefresh] = useState(0);

    function _delete(id) {
        fetch(`/api/contractor/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setContractor( contractor.filter(item => item.id !== id));
            })
            .catch(err => { console.log(err) })
    }
    function _makeButtons(item) {
        if (canModify) {
            return (<div>
                <Button color="success" onClick={() => {setEditContractor(item);}}>
                    <Edit />
                </Button>
                <Button color={"error"} onDoubleClick={() => _delete(item.id)}>
                    <Delete />
                </Button>
            </div>);
        }
        return ('');
    }

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/contractor`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => { setCanModify(data.canModify); setContractor(data.contractors) })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, refresh]);

    const columns = [
        {
            key: 'name',
            name: 'Kontrahent',
            ariaLabel: 'Kontrahent',
            fieldName: 'name',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'city',
            name: 'Miasto',
            ariaLabel: 'Miasto',
            fieldName: 'city',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'country',
            name: 'Kraj',
            ariaLabel: 'Kraj',
            fieldName: 'country',
            minWidth: 80,
            isResizable: true,
        },
        {
            key: 'vat_identification',
            name: 'NIP',
            ariaLabel: 'NIP',
            fieldName: 'vat_identification',
            minWidth: 70,
            isResizable: true,
        },
        {
            key: 'tag',
            name: 'Domyślny Tag',
            ariaLabel: 'Domyślny Tag',
            fieldName: 'tag',
            minWidth: 70,
            isResizable: true,
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 80,
            onRender: (item) => _makeButtons(item)
        }
    ];

  return (
      <>
        <Title title={'Kontrahenci'} />
        {canModify && <Button onClick={() => setAddContractor(true)}><Add/> Dodaj Kontrahenta</Button>}
        <AddContractor isOpen={addContractor} dismiss={() => setAddContractor(false)} onSuccess={() => setRefresh(refresh + 1)} />
        <EditContractor isOpen={editContractor} dismiss={() => setEditContractor(false)} onSuccess={() => setRefresh(refresh + 1)} />
        <DetailsList selectionMode={SelectionMode.none}
            columns={columns}
            items={contractor}
        />
    </>
    );
}
export default Contractor;
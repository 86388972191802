import React, {useContext, useEffect, useState} from "react";
// core components
import {Link, useNavigate, useParams} from 'react-router-dom';
import AuthContext from "../../services/AuthContext";
import EditMember from "../../Panels/Member/EditMember";
import IconButton from "@mui/material/IconButton";
import {Add, CalendarViewWeek, Clear, Delete, Edit, FileDownload, Timer} from "@mui/icons-material";
import {DatePicker, DetailsList, SelectionMode} from "@fluentui/react";
import {Button} from "@mui/material";
import MDBox from "../../components/MDBox";
import AddMember from "../../Panels/Member/AddMember";
import Title from "../../Component/Title";
import DatePickerPL from "../../Component/DatePickerPL";
import dbDate from "../../Utilities/dbDate";
import MDButton from "../../components/MDButton";
import EditDepartment from "../../Panels/Department/EditDepartment";
import MDTypography from "../../components/MDTypography";
import ForceEntrance from "../../Panels/Member/ForceEntrance";
import dbTime from "../../Utilities/dbTime";

function Department() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [refresh, setRefresh] = useState(0);
    const [date, setDate] = useState(new Date());
    const navigate = useNavigate();

    function _delete() {
        fetch(`/api/facility/${params.id}/department/${params.department}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                navigate(`/facility/${params.id}/dashboard`, {replace: true})
            })
            .catch(err => {
                console.log(err)
            })
    }

    function _deleteMember(id) {
        fetch(`/api/member/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                setRefresh(refresh + 1);
            })
            .catch(err => {
                console.log(err)
            })
    }

    const [editMember, setEditMember] = useState(false);
    const [addMember, setAddMember] = useState(false);
    const [editDepartment, setEditDepartment] = useState(false);
    const [forceEntrance, setForceEntrance] = useState(false);

    function _makeButtons(item) {
        const entranceChange = department.changeEntrance ? <IconButton color={'warning'} onClick={() => setForceEntrance(item)}><Timer /></IconButton> : '';
        const edit = department.canDelete ? <>
            <IconButton color={'success'} onClick={() => setEditMember(item)}><Edit /></IconButton>
            <IconButton color={'error'} onDoubleClick={() => _deleteMember(item.member_id)}><Delete /></IconButton>
        </> : '';
            return (<>
                {entranceChange}
                {edit}
            </>);
        }

    const [department, setDepatment] = useState({canDelete: false, department: {}, members: []});

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/department/${params.department}/${dbDate(date)}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setDepatment(data)
                })
                .catch(err => {
                    console.log(err)
                })
        }
        fetchData();
        return () => abortController.abort();
    }, [user, params.id, params.department, refresh, date]);

    const _memberLink = (item) => {
        const color = item.absence !== null ? 'warning' : item.active_to === null ? 'info' : 'secondary';
        return (<Link to={`member/${item.id}`}><MDButton color={color} fullWidth>{item.surname} {item.name}</MDButton></Link>);
    }

    const _formatEntrance = (date, modified) => {
        const time = date !== null ? dbTime(new Date(date)) : '';
        return modified !== null ? <MDTypography title={`Zmieniono ${modified}`} sx={{fontSize: '12px'}} color={'warning'}>{time}</MDTypography> : time;
    }

    const columns = [
        {
            key: 'name',
            name: 'Imię i Nazwisko',
            ariaLabel: 'Imię i Nazwisko',
            fieldName: 'name',
            minWidth: 120,
            isResizable: true,
            onRender: _memberLink
        },
        {
            key: 'in',
            name: 'Wejście',
            ariaLabel: 'Wejście',
            fieldName: 'in',
            minWidth: 120,
            onRender: (item) => _formatEntrance(item.in, item.modified)
        },
        {
            key: 'out',
            name: 'Wyjście',
            ariaLabel: 'Wyjście',
            fieldName: 'out',
            minWidth: 120,
            onRender: (item) => _formatEntrance(item.out, item.modified)
        },
        {
            key: 'allowed_out',
            name: 'Odbierający',
            ariaLabel: 'Odbierający',
            fieldName: 'allowed_out',
            minWidth: 180,
            isResizable: true
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 120,
            onRender: _makeButtons
        }
    ];

    const count = department.members.reduce((pv, cv) => {
        return pv + (cv.in !== null ? 1 : 0);
    }, 0);

    const absent = department.members.reduce((pv, cv) => {
        return pv + (cv.absence !== null ? 1 : 0);
    }, 0);
    const all = department.members.length;
    const expected = all - absent;
    const unplanned = all - count - absent;
    let closeDepartment = <MDButton variant={'text'} color={'dark'}>Archiwalny</MDButton>;
    if (department.department.active) {
        closeDepartment = department.canDelete
            ? (<MDBox><MDButton variant={'text'} color="error" onDoubleClick={_delete}> Zamknij oddział</MDButton></MDBox>)
            : <MDButton variant={'text'} color={'warning'}>Aktywny</MDButton>;
    }

    return (
        <div>
            <Title title={department.department.name}/>
            <MDBox sx={{display: 'flex', alignItems: 'center'}}>
                <MDBox sx={{borderBottom: '1px solid rgb(210, 214, 218)'}} flexGrow={1}><DatePickerPL borderless value={date} onSelectDate={(e) => setDate(e) } /></MDBox>
                {closeDepartment}
            </MDBox>
            <Button onClick={() => setAddMember(params.department)}><Add /> Dodaj Członka</Button>
            <Button onClick={() => setEditDepartment(department.department)}><Edit /> Edytuj Wydział</Button>
            <Button title='Raport' href={`/api/report/facility/${params.id}/department/${department.department.id}/${dbDate(date)}?auth=${user.token}`} target={'_blank'}>
                <CalendarViewWeek /> Raport </Button>
            <MDButton color={'dark'} variant={"text"} sx={{float: 'right'}}>Razem: {all}</MDButton>
            <MDButton color={'dark'} variant={"text"} sx={{float: 'right'}}>Obecni: {count}/{expected}</MDButton>
            <MDButton color={'dark'} variant={"text"} sx={{float: 'right'}}>Nieobecności: {absent}</MDButton>
            <MDButton color={'dark'} variant={"text"} sx={{float: 'right'}}>Nieplanowane: {unplanned}</MDButton>
            <DetailsList items={department.members} columns={columns} selectionMode={SelectionMode.none} className={'clear-both'} />
            <AddMember data={addMember} dismiss={() => setAddMember(false)}
                        onSuccess={() => setRefresh(refresh + 1)}/>
            <EditMember data={editMember} dismiss={() => setEditMember(false)}
                        onSuccess={() => setRefresh(refresh + 1)}/>
            <EditDepartment data={editDepartment} dismiss={() => setEditDepartment(false)}
                        onSuccess={() => setRefresh(refresh + 1)}/>
            <ForceEntrance data={forceEntrance} date={date} dismiss={() => setForceEntrance(false)}
                            onSuccess={() => setRefresh(refresh + 1)}/>
        </div>
    );
}

export default Department;
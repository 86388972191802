import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import {
    Dialog,
    DialogContent,
    DialogTitle,
    List,
    ListItem,
    ListItemAvatar,
    ListItemText,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import MDTypography from "../../components/MDTypography";
import Avatar from "@mui/material/Avatar";
import {blue} from "@mui/material/colors";
import PersonIcon from "@mui/icons-material/Person";
import {useParams} from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import {Close} from "@mui/icons-material";

function MemberOut(props) {
    const title = 'Opuszczenie placówki';
    const description = `${props.data.surname} ${props.data.name}`;

    const [user] = useContext(AuthContext);
    const params = useParams();
    const [allowed, setAllowed] = useState([]);
    const [mode, setMode] = useState('std');

    useEffect(() => {
        setAllowed([]);
        setMode('std');
        if (props.data.id !== undefined) {
            const abortController = new AbortController();
            async function fetchData() {
                await fetch(`/api/facility/${params.id}/member/${props.data.id}/collect`, {
                    method: 'GET',
                    signal: abortController.signal,
                    headers: {
                        'Authorization': 'Bearer ' + user.token
                    }
                }).then(res => res.json())
                    .then(setAllowed)
                    .catch(err => { console.log(err) })
            }
            fetchData();
            return () => abortController.abort();
        }
    },  [user, params.id, props.data.id]);
    const closeCss = {
        position: 'absolute',
        right: 8,
        top: 8
    };
    return (
        <Dialog open={props.data} onClose={props.dismiss} fullWidth maxWidth={'md'}>
            <DialogTitle>

                <IconButton variant={'text'} onClick={props.dismiss} sx={closeCss}><Close /></IconButton>

                <MDTypography variant={'h5'} fontWeight={'light'}>{title} <ToggleButtonGroup
                    color="warning"
                    value={mode}
                    exclusive
                    onChange={(e, v) => setMode(v)}
                    style={{marginLeft: '20px'}}
                >
                    <ToggleButton value="std">Rzeczywisty</ToggleButton>
                    <ToggleButton value="10m">-10 min</ToggleButton>
                    <ToggleButton value="err">Deklarowany</ToggleButton>
                </ToggleButtonGroup></MDTypography>

                <MDTypography variant={'p'} fontSize={'small'} fontWeight={'regular'}>{description} </MDTypography>

            </DialogTitle>

            <DialogContent>

            <List sx={{ pt: 0 }}>
                {allowed.map((person) => (
                    <ListItem button onClick={() => props.onSuccess(props.data.participation_id, props.data.department, props.data.id, person.id, props.dismiss, mode)} key={person.id}>
                        <ListItemAvatar>
                            <Avatar sx={{ bgcolor: blue[100], color: blue[600], margin: '8px' }}>
                                <PersonIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={`${person.fullname} (${person.document})`} secondary={`${person.kinship} (${person.contact})`}/>
                    </ListItem>
                ))}
            </List>
            </DialogContent>
        </Dialog>
    );
}

export default MemberOut;
import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import {useParams} from "react-router-dom";
import StyledModal from "../../StyledComponents/StyledModal";
import MDInput from "../../components/MDInput";
import TimeFlatpicker from "../../Component/TimeFlatpicker";
import dbTime from "../../Utilities/dbTime";
import {FormControlLabel, InputAdornment, Stack, Switch} from "@mui/material";
import StyledSelect from "../../StyledComponents/StyledSelect";


function EditFacility(props) {
    const title = 'Edytuj Ustawienia Placówki';
    const description = 'Tutaj możesz edytować dane i ustawienia placówki.';

    const [user] = useContext(AuthContext);
    const params = useParams();

    const [users, setUsers] = useState([]);

    const [name, setName] = useState('');
    const [headId, setHeadId] = useState(null);
    const [address, setAddress] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [city, setCity] = useState('');
    const [country, setCountry] = useState('');
    const [community, setCommunity] = useState('');
    const [vatId, setVatId] = useState('');
    const [account, setAccount] = useState('');
    const [contactPhone, setContactPhone] = useState('');
    const [contactEmail, setContactEmail] = useState('');
    const [governor, setGovernor] = useState('');

    const [openFrom, setOpenFrom] = useState('06:30');
    const [openTo, setOpenTo] = useState('17:00');
    const [freeFrom, setFreeFrom] = useState('08:00');
    const [freeTo, setFreeTo] = useState('13:00');

    const [price, setPrice] = useState(1);
    const [priceInterval, setPriceInterval] = useState(60);
    const [tolerance, setTolerance] = useState(15);
    const [preTolerance, setPreTolerance] = useState(15);
    const [absenceTimeframe, setAbsenceTimeframe] = useState(0);
    const [overtime, setOvertime] = useState(50);
    const [realTimeExtras, setRealTimeExtras] = useState(false);
    const [timeFeeOnAbsence, setTimeFeeOnAbsence] = useState(true);
    const [firstHourSplit, setFirstHourSplit] = useState(false);
    const [massPayments, setMassPayments] = useState('');

    function timeframeFormat() {
        const hours = Math.floor(absenceTimeframe / 60);
        const mins = absenceTimeframe - (hours * 60);
        const formatTime = (val) => val <= 9 ? '0' + (val) : (val);
        if (absenceTimeframe == '0') {
            return 'Zgłoszenia do północy dnia poprzedniego';
        } else if (absenceTimeframe > 0) {
            return `Zgloszenia do  ${formatTime(hours)}:${formatTime(mins)} w dniu zgłoszenia`;
        } else if (hours < -24) {
            return `Zgłoszenia do ${formatTime(hours*-1)}:${formatTime(mins)} przed zgłaszanym dniem`;
        } else {
            return `Zgłoszenia do ${formatTime(24 + hours)}:${formatTime(mins)} dnia poprzedniego`;
        }
    }
    function _send() {
        const formData = {
            name: name,
            head_id: headId.id ?? headId,
            address: address,
            postal_code: postalCode,
            city: city,
            country: country,
            community: community,
            vat_identification: vatId,
            account: account,
            contact_phone: contactPhone,
            contact_email: contactEmail,
            governor: governor,

            open_from: dbTime(openFrom),
            open_to: dbTime(openTo),
            unpaid_from: dbTime(freeFrom),
            unpaid_to: dbTime(freeTo),

            price: price,
            price_interval: priceInterval,
            tolerance: tolerance,
            pre_tolerance: preTolerance,
            overtime: overtime,
            real_time_extras: realTimeExtras,
            time_fee_on_absence: timeFeeOnAbsence,
            first_hour_split: firstHourSplit,
            mass_payments: massPayments,
            absence_timeframe: absenceTimeframe,
        };
        fetch(`/api/facility/${params.id}`, {
            method: 'PUT',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        setName(props.data.name);
        setHeadId(props.data.head_id);
        setAddress(props.data.address);
        setPostalCode(props.data.postal_code);
        setCity(props.data.city);
        setCountry(props.data.country);
        setCommunity(props.data.community);
        setVatId(props.data.vat_identification);
        setContactPhone(props.data.contact_phone);
        setContactEmail(props.data.contact_email);
        setGovernor(props.data.governor);
        setAccount(props.data.account);
        setOpenFrom(props.data.open_from);
        setOpenTo(props.data.open_to);
        setFreeFrom(props.data.unpaid_from);
        setFreeTo(props.data.unpaid_to);
        setPrice(props.data.price);
        setPriceInterval(props.data.price_interval);
        setTolerance(props.data.tolerance);
        setPreTolerance(props.data.pre_tolerance);
        setOvertime(props.data.overtime);
        setRealTimeExtras(props.data.real_time_extras);
        setTimeFeeOnAbsence(props.data.time_fee_on_absence);
        setFirstHourSplit(props.data.first_hour_split);
        setMassPayments(props.data.mass_payments);
        setAbsenceTimeframe(props.data.absence_timeframe);

        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/users`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setUsers(data
                        .filter((item) => { return item.roles.includes('ADMIN') || item.roles.includes('MANAGER');})
                        .map((item) => { return {id: item.id, name: `${item.name} ${item.surname} (${item.email})`}; }));
                })
                .catch(err => {
                    setUsers([]);
                    console.log(err)
                })
        }
        fetchData();
        return () => abortController.abort();
    }, [props.data])
    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss} actionLabel={'Edytuj'} fullWidth={true} maxWidth={'lg'}>
            <Stack direction={'row'} spacing={1}>
                <Stack spacing={2} flexGrow={2}>
                    <MDInput
                        onChange={(e) => setName(e.target.value)}
                        label='Nazwa'
                        fullWidth
                        value={name}
                    />
                    <StyledSelect
                        name='head_id'
                        label='Dyrektor'
                        value={headId}
                        onChange={(e, v) => setHeadId(v)}
                        items={users}
                    />
            <MDInput
                onChange={(e) => setAddress(e.target.value)}
                label='Adres'
                fullWidth
                value={address}
            />
                    <Stack direction={'row'} spacing={1}>
                        <MDInput
                            onChange={(e) => setPostalCode(e.target.value)}
                            label='Kod Pocztowy'
                            fullWidth
                            value={postalCode}
                        />
                        <MDInput
                            onChange={(e) => setCity(e.target.value)}
                            label='Miasto'
                            fullWidth
                            value={city}
                        />
                    </Stack>
                    <Stack direction={'row'} spacing={1}>
            <MDInput
                onChange={(e) => setCountry(e.target.value)}
                label='Kraj'
                fullWidth
                value={country}
            />
                    <MDInput
                        onChange={(e) => setCommunity(e.target.value)}
                        label='Gmina/Miasto'
                        fullWidth
                        value={community}
                    />
                    </Stack>
                    <MDInput
                        onChange={(e) => setVatId(e.target.value)}
                        label='NIP'
                        fullWidth
                        value={vatId}
                    />
                    <MDInput
                        onChange={(e) => setAccount(e.target.value)}
                        label='Konto'
                        fullWidth
                        value={account}
                    />
                    <MDInput
                        onChange={(e) => setContactPhone(e.target.value)}
                        label='Telefon Kontaktowy'
                        fullWidth
                        value={contactPhone}
                    />
                    <MDInput
                        onChange={(e) => setContactEmail(e.target.value)}
                        label='Email Kontaktowy'
                        fullWidth
                        value={contactEmail}
                    />
                    <MDInput
                        onChange={(e) => setGovernor(e.target.value)}
                        label='Organ Prowadzący'
                        fullWidth
                        value={governor}
                    />
                </Stack>
            <Stack spacing={2}>
                <Stack direction={'row'} spacing={1}>
                <TimeFlatpicker label={'Otwarte od'} value={openFrom} onChange={(e) => setOpenFrom(e)} sx={''}/>
                <TimeFlatpicker label={'Otwarte do'} value={openTo} onChange={(e) => setOpenTo(e)} sx={''}/>
                </Stack>
                <Stack direction={'row'} spacing={1}>
                <TimeFlatpicker label={'Godziny darmowe od'} value={freeFrom} onChange={(e) => setFreeFrom(e)} sx={''}/>
                <TimeFlatpicker label={'Godziny darmowe do'} value={freeTo} onChange={(e) => setFreeTo(e)} sx={''}/>
                </Stack>
                Tolerancja [min]
                <Stack direction={'row'} spacing={1}>
                    <MDInput
                        onChange={(e) => setPreTolerance(e.target.value)}
                        label='Wejścia'
                        fullWidth
                        value={preTolerance}
                        type={'number'}
                        min={0}
                    />
                    <MDInput
                        onChange={(e) => setTolerance(e.target.value)}
                        label='Wyjścia'
                        fullWidth
                        value={tolerance}
                        type={'number'}
                        min={0}
                    />
                </Stack>
                    <MDInput
                        onChange={(e) => setAbsenceTimeframe(e.target.value)}
                        label='Zgłaszanie nieobecności do [min]'
                        fullWidth
                        value={absenceTimeframe}
                        type={'number'}
                    />
                {timeframeFormat()}
                    <MDInput
                        onChange={(e) => setPrice(e.target.value)}
                        label='Opłata za pobyt [zł]'
                        fullWidth
                        value={price}
                        type={'number'}
                        min={0}
                        step={0.01}
                    />
                    <MDInput
                        onChange={(e) => setPriceInterval(e.target.value)}
                        label='Opłata co [min]'
                        fullWidth
                        value={priceInterval}
                        type={'number'}
                        min={0}
                    />
                    <MDInput
                        onChange={(e) => setOvertime(e.target.value)}
                        label='Kara umowna po zamknięciu [zł]'
                        fullWidth
                        value={overtime}
                        type={'number'}
                        min={0}
                        step={0.01}
                    />
                <MDInput
                    onChange={(e) => setMassPayments(e.target.value)}
                    label='Płatności Masowe'
                    fullWidth
                    helperText={'Proszę podać N w miejscu liczb generowanych automatycznie. Min. 8'}
                    InputProps={{startAdornment: <InputAdornment position="start">CC</InputAdornment>}}
                    value={massPayments}
                />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={timeFeeOnAbsence}
                                onChange={(event) => setTimeFeeOnAbsence(event.target.checked)}
                                value="timeFeeOnAbsence"
                            />
                        }
                        label="Opłata za pobyt przy nieobecności nieplanowanej"
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={realTimeExtras}
                                onChange={(event) => setRealTimeExtras(event.target.checked)}
                                value="paidOnAbsence"
                            />
                        }
                        label="Rozliczanie rzeczywiste"
                    />
                <FormControlLabel
                    control={
                        <Switch
                            checked={firstHourSplit}
                            onChange={(event) => setFirstHourSplit(event.target.checked)}
                            value="firstHourSplit"
                        />
                    }
                    label="Pierwsza godzina za 50% ceny pobytu"
                />
            </Stack></Stack>
        </StyledModal>
    );
}

export default EditFacility;
import React, {useState} from "react";
import {Link as RouterLink, useParams} from "react-router-dom";
import {
    CalendarMonth,
    Logout,
    MoreVert,
    Newspaper,
    Payments,
    Dashboard,
    Message,
    People,
    AccountBox, Groups, Description, HolidayVillage, Help
} from "@mui/icons-material";
import {BottomNavigation, BottomNavigationAction, Menu, MenuItem, Paper} from "@mui/material";
import Badge from "@mui/material/Badge";

function FooterMobile({logout}) {
    const params = useParams();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (<Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation showLabels>
            <BottomNavigationAction label="Obecności" icon={<CalendarMonth fontSize={'medium'} color={'warning'} />} to={'dashboard'} component={RouterLink}/>
            <BottomNavigationAction label="Finanse" icon={<Badge badgeContent={0} color="error"><Payments fontSize={'medium'} color={'warning'} /></Badge>} to={'finances'} component={RouterLink}/>
            <BottomNavigationAction disabled label="Aktualności" icon={<Newspaper fontSize={'medium'} color={'disabled'} />} to={'news'} component={RouterLink}/>
            <BottomNavigationAction disabled label="Wiadomości" icon={<Message fontSize={'medium'} color={'disabled'}/>} to={'messaging'} component={RouterLink}/>
            <BottomNavigationAction label="Więcej" icon={<MoreVert fontSize={'medium'} color={'warning'}  />} onClick={handleClick}/>
            <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >

                <MenuItem to={'/dashboard'} component={RouterLink}><Dashboard fontSize={'large'} color={'warning'}  sx={{marginRight: '10px'}}/>Start</MenuItem>
                <MenuItem disabled to={'allowed'} component={RouterLink}><Groups fontSize={'large'} color={'disabled'}  sx={{marginRight: '10px'}}/>Upoważnienia</MenuItem>
                <MenuItem disabled to={'documents'} component={RouterLink}><Description fontSize={'large'} color={'disabled'}  sx={{marginRight: '10px'}}/>Dokumenty</MenuItem>
                <MenuItem disabled to={'facility'} component={RouterLink}><HolidayVillage fontSize={'large'} color={'disabled'}  sx={{marginRight: '10px'}}/>Placówka</MenuItem>
                <MenuItem disabled to={'help'} component={RouterLink}><Help fontSize={'large'} color={'disabled'}  sx={{marginRight: '10px'}}/>Pomoc</MenuItem>
                <MenuItem disabled to={'/profile'} component={RouterLink}><AccountBox fontSize={'large'} color={'disabled'}  sx={{marginRight: '10px'}}/>Mój Profil</MenuItem>
                <MenuItem onClick={logout}><Logout fontSize={'large'} color={'warning'}  sx={{marginRight: '10px'}}/> Wyloguj</MenuItem>
            </Menu>
        </BottomNavigation>
        </Paper>
    )

}

export default FooterMobile;

import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import {useParams} from "react-router-dom";
import StyledModal from "../../StyledComponents/StyledModal";
import StyledSelect from "../../StyledComponents/StyledSelect";
import MDInput from "../../components/MDInput";
import TimeFlatpicker from "../../Component/TimeFlatpicker";
import dbTime from "../../Utilities/dbTime";
import {FormControlLabel, Switch} from "@mui/material";


function AddFacilityMeal(props) {
    const title = 'Dodaj Posiłek Placówki';
    const description = 'Tutaj możesz dołączyć posiłek do placówki wraz z jego kosztem.';

    const [user] = useContext(AuthContext);
    const params = useParams();

    const [cost, setCost] = useState('');
    const [mealType, setMealType] = useState('');
    const [paidOnAbsence, setPaidOnAbsence] = useState(true);
    const [time, setTime] = useState('');

    function _send() {
        const formData = {
            expected_cost: cost,
            meal_type_id: mealType.id,
            paid_on_absence: paidOnAbsence,
            time: dbTime(time)
        };
        fetch(`/api/facility/${params.id}/meal`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    const [tagList, setTagList] = useState([]);
    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/meal`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setTagList(data.meals);
                })
                .catch(err => {
                    console.log(err)
                })
        }
        fetchData();
        return () => abortController.abort();
    }, [user]);

    return (
        <StyledModal isOpen={props.add} title={title} description={description} send={_send} dismiss={props.dismiss}>
            <StyledSelect
                name='meal_type'
                label='Posiłek'
                value={mealType}
                onChange={(e, v) => setMealType(v)}
                items={tagList}
            />
            <MDInput
                onChange={(e) => setCost(e.target.value)}
                label='Koszt posiłku'
                fullWidth
                sx={{marginBottom: 2}}
                value={cost}
                type={'number'}
                min={0}
            />
            <TimeFlatpicker label={'Godzina'} value={time} onChange={(e) => setTime(e)} />
            <FormControlLabel
                control={
                    <Switch
                        checked={paidOnAbsence}
                        onChange={(event) => setPaidOnAbsence(event.target.checked)}
                        value="paidOnAbsence"
                    />
                }
                label="Płatny przy nieobecności nieplanowanej"
            />
        </StyledModal>
    );
}

export default AddFacilityMeal;
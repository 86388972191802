import React from "react";
import MDDatePicker from "../components/MDDatePicker";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect";
import {BrowserView, MobileView} from 'react-device-detect';
import dbMonth from "../Utilities/dbMonth";

export default function MonthPicker(props) {
    const value = typeof props.value === 'object' ? dbMonth(props.value) : props.value;
    return (
        <>
            <BrowserView>
                <MDDatePicker fullWidth {...props} options={{dateFormat: "Y-m", disableMobile: "true", plugins: [
                        new monthSelectPlugin({
                            shorthand: true, //defaults to false
                            dateFormat: "Y-m", //defaults to "F Y"
                            altFormat: "F Y", //defaults to "F Y"
                            theme: "light" // defaults to "light"
                        })
                    ]}}/>
            </BrowserView>
            <MobileView>
                <input type={'month'} value={value} onChange={e => props.onChange(e.target.value)} />
            </MobileView>
        </>
    );
}
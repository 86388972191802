import React, {useContext, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import MDInput from "../../components/MDInput";


function AddDiet(props) {
    const title = 'Dodaj Dietę';
    const description = 'Tutaj możesz dodać typ diety szeregujący receptury i wymagania dzieci';

    const [user] = useContext(AuthContext);
    const [diet, setDiet] = useState('');

    function _send() {
        const formData = {
            name: diet,
        };
        fetch(`/api/diet`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess(data);
                props.dismiss();
            })
            .catch(err => { console.log(err) })
    }

    return (<StyledModal isOpen={props.isOpen} title={title} description={description} send={_send} dismiss={props.dismiss}>
                <MDInput
                    onChange={(e) => setDiet(e.target.value)}
                    label='Nazwa'
                    value={diet}
                    variant="standard"
                    fullWidth
                    sx={{marginBottom: 2}}
                />
        </StyledModal>);
}
export default AddDiet;
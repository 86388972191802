import React, {useContext, useEffect, useState} from "react";
import Listing from "./Member/Listing";
import AuthContext from "../../services/AuthContext";
import {useParams} from "react-router-dom";
import {FormControlLabel, Grid, Switch, ToggleButton, ToggleButtonGroup} from "@mui/material";
import Title from "../../Component/Title";
import MDButton from "../../components/MDButton";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import ChangeRoom from "../../Panels/Room/ChangeRoom";


export default function In(props) {
    const [user] = useContext(AuthContext);
    const params = useParams();
    const [showIn, setShowIn] = React.useState(true);
    const [showAbsence, setShowAbsence] = React.useState(false);
    const [members, setMembers] = useState({});
    const [departmentLocations, setDepartmentLocations] = useState([]);
    const [locationFilters, setLocationFilters] = useState([1]);
    const [filter, setFilter] = useState("all");
    const [filteredMembers, setFilteredMembers] = useState({});
    const [departmentRoomChange, setDepartmentRoomChange] = useState(false);
    const [memberRoomChange, setMemberRoomChange] = useState(false);
    const [refresh, setRefresh] = useState(0);


    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/entrance`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    let memberDepartments = {};
                    let locations = {};
                    let locationFilter = new Set();
                    data.forEach(item => {
                        memberDepartments[item.department] = memberDepartments[item.department] || [];
                        memberDepartments[item.department].push(item);
                        locations[item.department] = item.location;
                        locationFilter.add(item.location);
                    });
                    setMembers(memberDepartments);
                    setDepartmentLocations(locations);
                    setLocationFilters([...locationFilter].sort());
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user, params.id, refresh]);

    useEffect(() => {
        var timer = setInterval(() => setRefresh((p) => p + 1), 120*1000 )
        return function cleanup() {
            clearInterval(timer)
        }

    });

    useEffect(() => {
        if (filter === 'all') {
            setFilteredMembers(members);
        } else {
            let filtered = {};
            Object.keys(members).forEach(department => {
                if (filter === departmentLocations[department]) {
                    filtered[department] = members[department];
                }
            });
            setFilteredMembers(filtered);
        }
    }, [members, filter]);

    function _in(id, department) {
        fetch(`/api/facility/entrance/${id}`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => updateMemberState(data, department, id))
            .catch(err => {
                console.log(err)
            })
    }

    function updateMemberState(data, department, id) {
        let updated = {...members};
        updated[department] = updated[department].map(item => {
            if (item.id === id) {
                item.in = data.in || null;
                item.out = data.out || null;
                item.participation_id = data.id || null;
            }
            return item;
        });
        setMembers(updated);
    }

    function _cancel(id, department, member) {
        fetch(`/api/facility/entrance/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => updateMemberState(data, department, member))
            .catch(err => {
                console.log(err)
            })
    }

    let current = 0;
    let absent = 0;
    let all = 0;

    let departments = [];
    Object.keys(filteredMembers).forEach(department => {
        if (members[department] !== undefined) {
            const inPlace = members[department].reduce((acc, item) => {
                return acc + (item.out === null && item.in !== null ? 1 : 0)
            }, 0);
            departments.push(<Listing key={department} name={department} id={members[department][0].department_id} color={members[department][0].color} in={_in} cancel={_cancel}
                                      department={members[department]}
                                      setDepartmentRoomChange={setDepartmentRoomChange}
                                      setMemberRoomChange={setMemberRoomChange}
                                      showAbsence={showAbsence}
                                      showIn={showIn}
                                      inPlace={inPlace}
            />);
            current += inPlace;
            absent += members[department].reduce((acc, item) => {
                return acc + (item.absence !== null ? 1 : 0)
            }, 0);
            all += members[department].length;
        }

    });
    const expected = all - absent;
    const subtitle = (<Grid container justifyContent={'space-between'} alignItems={'center'}>
        <FormControlLabel
            control={
                <Switch
                    checked={showIn}
                    onChange={(event) => setShowIn(event.target.checked)}
                    value="showIn"
                />
            }
            label="Obecni"
        />
        <FormControlLabel
            control={
                <Switch
                    checked={showAbsence}
                    onChange={(event) => setShowAbsence(event.target.checked)}
                    value="checkedA"
                />
            }
            label="Planowane nieobecności"
        />
        {locationFilters.length > 1 &&
            <MDTypography variant={'h5'} fontWeight={'light'}>Szatnia <ToggleButtonGroup
                color="info"
                value={filter}
                exclusive
                onChange={(e, v) => setFilter(v)}
                style={{marginLeft: '20px'}}
            >
                <ToggleButton value="all">Wszystkie</ToggleButton>
                {locationFilters.map(item => (<ToggleButton value={item} key={item}>{item}</ToggleButton>))}
            </ToggleButtonGroup></MDTypography>
        }
        <MDBox>
            <MDButton color={'dark'} variant={"text"}>Nieobecności: {absent}</MDButton>
            <MDButton color={'dark'} variant={"text"}>Obecni: {current}/{expected}</MDButton>
            <MDButton color={'dark'} variant={"text"}>Razem: {all}</MDButton>
        </MDBox>
    </Grid>);

    return (
        <>
            <Title title={'Wejścia'} subtitle={subtitle} />
            <Grid container justifyContent={'space-evenly'}>
                {departments}
            </Grid>
            <ChangeRoom isOpen={departmentRoomChange || memberRoomChange}
                        department={departmentRoomChange} participant={memberRoomChange}
                        dismiss={() => {setDepartmentRoomChange(false); setMemberRoomChange(false);}}
                        onSuccess={() => {setRefresh(refresh + 1)}} />
            </>
            );
            }

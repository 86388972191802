import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledSelect from "../../StyledComponents/StyledSelect";
import StyledModal from "../../StyledComponents/StyledModal";
import MDBox from "../../components/MDBox";
import MDInput from "../../components/MDInput";

function AddContractor(props) {
    const title = 'Dodaj Kontrahenta';
    const description = 'Tutaj możesz zdefiniować nowego kontrahenta dla wszystkich swoich placówek. Wykorzystując domyślny tag możesz automatycznie podpowiadać znakowanie kosztów od tego kontrahenta.';


    const [user] = useContext(AuthContext);
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [postalCode, setPostalCode] = useState('');
    const [country, setCountry] = useState('');
    const [vatId, setVatId] = useState('');
    const [defaultTag, setDefaultTag] = useState('');

    function _send() {
        const formData = {
            name: name,
            address: address,
            city: city,
            postal_code: postalCode,
            country: country,
            vat_identification: vatId,
            default_tag: defaultTag.id
        };
        fetch(`/api/contractor`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess(data);
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    const [tagList, setTagList] = useState([]);

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/tag`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setTagList(data.tags.map(item => {
                        item.name = `${item.tag} -> ${item.income_target}`;
                        return item;
                    }))
                })
                .catch(err => {
                    console.log(err)
                })
        }
        fetchData();
        return () => abortController.abort();
    }, [user]);

    return (
        <StyledModal isOpen={props.isOpen} title={title} description={description} send={_send} dismiss={props.dismiss}>
            <MDInput
                onChange={(e) => setName(e.target.value)}
                label='Kontrahent'
                fullWidth
                sx={{marginBottom: 2}}
                value={name}

            />
            <MDInput
                value={vatId}
                onChange={(e) => setVatId(e.target.value)}
                label='NIP'
                fullWidth
                sx={{marginBottom: 2}}
            />
            <MDBox justify="space-between">
                <MDBox flexGrow={1}>
                    <MDInput
                        value={city}
                        onChange={(e) => setCity(e.target.value)}
                        label='Miasto'
                        fullWidth
                        sx={{marginBottom: 2}}

                    />
                    <MDInput
                        value={address}
                        onChange={(e) => setAddress(e.target.value)}
                        label='Adres'
                        fullWidth
                        sx={{marginBottom: 2}}
                    />

                </MDBox>
                <MDBox flexGrow={1}>
                    <MDInput
                        value={postalCode}
                        onChange={(e) => setPostalCode(e.target.value)}
                        label='Kod Pocztowy'
                        fullWidth
                        sx={{marginBottom: 2}}
                    />
                    <MDInput
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                        label='Kraj'
                        fullWidth
                        sx={{marginBottom: 2}}
                    />
                </MDBox>
            </MDBox>
            <StyledSelect
                name='default_tag'
                label='Domyślny Tag'
                value={defaultTag}
                onChange={(e, v) => setDefaultTag(v)}
                items={tagList}
            />
        </StyledModal>
    );
}

export default AddContractor;
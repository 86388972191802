import {NavLink} from "react-router-dom";
import MDButton from "../components/MDButton";
import {Grid} from "@mui/material";
import React, {useState} from "react";

function Subnavigation({items, defaultActive}) {
    const [active, setActive] = useState(defaultActive)

    const activate = (isActive, path, activeStyle, nonActiveStyle) => {

        if (isActive) {
            setActive(path)
            return activeStyle
        }
        return nonActiveStyle
    }

    return (<Grid item lg={8}>
        <div>
            {items && items.map(item => {
                if (item.disabled) {
                    return null;
                }
                return (<NavLink to={item.to} style={(activeNav) => {
                    activate(activeNav.isActive, item.to);
                    document.activeElement.blur();
                }} key={item.to} target={item.target !== undefined ? item.target : '_self'}>
                <MDButton color={active === item.to ? item.color : "secondary"} variant={active === item.to ? 'contained' : 'text'}>{item.name}</MDButton>
            </NavLink>)
            })}
        </div>
    </Grid>);
}
export default Subnavigation;
import React, {useContext, useEffect, useState} from "react";
// core components
import AuthContext from "../../services/AuthContext";
import AddTag from "../../Panels/Tag/AddTag";
import {Add, Clear, Close, Delete, Edit} from "@mui/icons-material";
import {Button} from "@mui/material";
import {DetailsList, SelectionMode} from "@fluentui/react";
import IconButton from "@mui/material/IconButton";
import Title from "../../Component/Title";

function Tags() {
    const [user] = useContext(AuthContext);
    const [tags, setTags] = useState([]);
    const [canModify, setCanModify] = useState(false);
    const [addTag, setAddTag] = useState(false);

    function _delete(id) {
        fetch(`/api/tag/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setTags( tags.filter(item => item.id !== id));
            })
            .catch(err => { console.log(err) })
    }
    function _makeButtons(id) {
        if (canModify) {
            return (<div>
                <IconButton disabled><Edit /></IconButton>
                <IconButton color={'error'} onDoubleClick={() => _delete(id)}><Clear /></IconButton>
            </div>);
        }
        return ('');
    }

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/tag`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => { setCanModify(data.canModify); setTags(data.tags) })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user]);

    const columns = [
        {
            key: 'tag',
            name: 'Tag',
            ariaLabel: 'Tag',
            fieldName: 'tag',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'income',
            name: 'Użycie Przychodu',
            ariaLabel: 'Tytuł przelewu',
            fieldName: 'income_target',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 80,
            onRender: (item) => _makeButtons(item.id)
        }
    ];

  return (
      <>
          <Title title={'Tagi'} />
        {canModify && <Button onClick={() => setAddTag(true)}><Add /> Dodaj Tag</Button>}
        <AddTag isOpen={addTag} dismiss={() => setAddTag(false)} onSuccess={(data) => {setTags(data); setAddTag(false)}} />
        <DetailsList items={tags} columns={columns} selectionMode={SelectionMode.none}/>
      </>
    );
}
export default Tags;
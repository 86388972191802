import React, {useContext, useEffect, useState} from "react";
import {Button, Grid} from "@mui/material";
import {useParams} from "react-router-dom";
import AuthContext from "../../../services/AuthContext";
import {EditAttributes} from "@mui/icons-material";
import MDTypography from "../../../components/MDTypography";
import EditFacility from "../../../Panels/Facility/EditFacility";

function General() {
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [facility, setFacility] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [editSettings, setEditSettings] = useState(false);

    function timeframeFormat(absenceTimeframe) {
        const hours = Math.floor(absenceTimeframe / 60);
        const mins = absenceTimeframe - (hours * 60);
        const formatTime = (val) => val <= 9 ? '0' + (val) : (val);
        if (absenceTimeframe == '0') {
            return 'Zgłoszenia do północy dnia poprzedniego';
        } else if (absenceTimeframe > 0) {
            return `Zgloszenia do  ${formatTime(hours)}:${formatTime(mins)} w dniu zgłoszenia`;
        } else if (hours < -24) {
            return `Zgłoszenia do ${formatTime(hours*-1)}:${formatTime(mins)} przed zgłaszanym dniem`;
        } else {
            return `Zgłoszenia do ${formatTime(24 + hours)}:${formatTime(mins)} dnia poprzedniego`;
        }
    }

    useEffect(() => {
        const abortController = new AbortController();

        async function fetchData() {
            await fetch(`/api/facility/${params.id}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setFacility(data)
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user, params.id, refresh]);

    const subtitle = (<Grid container justifyContent={'space-between'} mb={3}>
        {facility.canModify &&
            <Button onClick={() => setEditSettings(facility)}><EditAttributes/> Edytuj Ustawienia</Button>}
    </Grid>);

    return (<div>
            {subtitle}
            <Grid container justifyContent={'space-evenly'} mb={3}>
                <Grid item lg={3}>
                    <MDTypography fontWeight={'light'} variant={'h4'}>{facility.name}</MDTypography>
                    <MDTypography fontWeight={'light'}
                                  variant={'h5'}>{facility.address}, {facility.postal_code} {facility.city}, {facility.country}</MDTypography>
                    <dl className={'inline-flex'}>
                        <dt>Organ Prowadzący</dt>
                        <dd>{facility.governor}</dd>
                        <dt>NIP</dt>
                        <dd>{facility.vat_identification}</dd>
                        <dt>Konto</dt>
                        <dd>{facility.account}</dd>
                        <dt>Otwarte</dt>
                        <dd>{facility.open_from} - {facility.open_to}</dd>
                        <dt>Godziny Darmowe</dt>
                        <dd>{facility.unpaid_from} - {facility.unpaid_to}</dd>
                        <dt>Telefon</dt>
                        <dd>{facility.contact_phone}</dd>
                        <dt>Email</dt>
                        <dd>{facility.contact_email}</dd>

                    </dl>
                </Grid>
                <Grid item lg={3}>
                    <MDTypography fontWeight={'light'} variant={'h4'}>Sposób rozliczenia:</MDTypography>
                    <ul>
                        <li>Rozliczanie {facility.real_time_extras
                            ? ' według rzeczywistego pobytu'
                            : 'według deklaracji i przekroczeń deklaracji'}</li>
                        <li>Nieobecności nieusprawiedliwone {facility.time_fee_on_absence
                            ? ' naliczają opłatę za pobyt zgodnie z deklaracją'
                            : 'nie powodują naliczania opłaty za pobyt'}</li>
                        <li>{facility.first_hour_split
                            ? ' Pierwsza godzina za 50% ceny pobytu'
                            : 'Rozliczanie za każdą rozpoczętą godzinę'}</li>
                        <li>Tolerancja czasu: Wejścia: {facility.pre_tolerance} min, Wyjścia: {facility.tolerance} min</li>
                        <li>Rozliczanie co rozpoczęty interwał: {facility.price_interval} min</li>
                        <li>Cena za interwał: {facility.price} zł</li>
                        <li>Kara za przekroczenie czasu pracy placówki: {facility.overtime} zł</li>
                        <li>{timeframeFormat(facility.absence_timeframe)}</li>
                        {facility.mass_payments !== null &&
                            <li>Płatności Masowe (CC {facility.mass_payments})</li>
                        }
                    </ul>
                </Grid>
            </Grid>
            <EditFacility data={editSettings} dismiss={() => setEditSettings(false)} onSuccess={() => setRefresh(refresh + 1)} />
        </div>
    )
}

export default General;
import {ChildCare, Euro, MeetingRoom} from "@mui/icons-material";

var memberRoutes = [
  {
    type: "header",
    route: "/",
    key: "member",
    name: "Dzieci",
    icon: <ChildCare />,
  },
  {
    route: "dashboard",
    key: "dashboard",
    name: "Obecności",
    icon: <MeetingRoom />,
    type: 'header'
  },
  {
    type: "header",
    route: "billing",
    key: "billing",
    name: "Rachunki",
    icon: <Euro />
  },
];
export default memberRoutes;

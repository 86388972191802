function dbTime(date) {
    if (date === '' || date === null || (Array.isArray(date) && date.length === 0)) {
        return '';
    }
    if (typeof date === 'string' || date instanceof String) {
        return date;
    }
    const dateObj = new Date(date);
    const hour = dateObj.getHours() <= 9 ? '0' + (dateObj.getHours()) : (dateObj.getHours());
    const minutes = dateObj.getMinutes() <= 9 ? '0' + (dateObj.getMinutes()) : (dateObj.getMinutes());
    return `${hour}:${minutes}`
}

export default dbTime;
import React, {useContext} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import {useParams} from "react-router-dom";

function GenerateMassPayments(props) {
    const title = 'Generuj Rachunki Indywidualne';
    const description = 'Tutaj możesz wygenerować rachunki indywidualne dla swojej placówki.';


    const params = useParams();
    const [user] = useContext(AuthContext);

    function _send() {
        fetch(`/api/facility/${params.id}/mass_payments`, {
            method: 'PUT',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess(data);
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <StyledModal isOpen={props.isOpen} title={title} description={description}
                     send={_send} dismiss={props.dismiss} actionLabel={'Generuj'} actionColor={'primary'} />
    );
}

export default GenerateMassPayments;
import MDTypography from "../components/MDTypography";
import Divider from "@mui/material/Divider";
import React from "react";
import FacilitySelect from "../views/Navbar/FacilitySelect";
import {Grid, Icon} from "@mui/material";

import {setMiniSidenav, useMaterialUIController,} from "context";
import IconButton from "@mui/material/IconButton";

function Title({title, subtitle, memberMode}) {
    const [controller, dispatch] = useMaterialUIController();
    const { miniSidenav } = controller;
    const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
    const iconButton = memberMode ? '' : (<IconButton onClick={handleMiniSidenav} size="small" disableRipple>
        <Icon fontSize="medium">
            {miniSidenav ? "menu_open" : "menu"}
        </Icon>
    </IconButton>);
    const titleGrid = title !== '' ? (                <Grid item flexGrow={1}>
        <MDTypography fontWeight={'light'} variant={'h3'}>{title}{iconButton}</MDTypography>

    </Grid>) : ''
    return (<>
        <Grid container justifyContent={'space-between'} mb={2}>
                {titleGrid}
                <Grid item flexGrow={2}>
                    <FacilitySelect memberMode={memberMode}/>
                </Grid>

            </Grid>
    {subtitle && <>
        <MDTypography fontWeight={'light'} variant={'h6'}>{subtitle}</MDTypography>
        <Divider />
    </>}
        </>
        )
}

Title.defaultProps = {
    memberMode: false
}
export default Title;
import {DatePicker} from "@fluentui/react";
import dbDate from "../Utilities/dbDate";

function DatePickerPL(props) {
    const DayPickerStrings = {
        months: [
            'Styczeń',
            'Luty',
            'Marzec',
            'Kwiecień',
            'Maj',
            'Czerwiec',
            'Lipiec',
            'Sierpień',
            'Wrzesień',
            'Październik',
            'Listopad',
            'Grudzień'
        ],

        shortMonths: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],

        days: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],

        shortDays: ['Nd', 'Pn', 'Wt', 'Śr', 'Cz', 'Pt', 'So'],

        goToToday: 'Dziś',
        prevMonthAriaLabel: 'Poprzedni miesiąc',
        nextMonthAriaLabel: 'Następny miesiąc',
        prevYearAriaLabel: 'Poprzedni rok',
        nextYearAriaLabel: 'Następny rok'
    };
    return (<DatePicker sx={{marginBottom: '16px'}} {...props} formatDate={dbDate} strings={DayPickerStrings}  firstDayOfWeek={1}/>)
}
export default DatePickerPL;
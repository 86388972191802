import React, {useContext, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import MDInput from "../../components/MDInput";
import {FormControlLabel, InputAdornment, Switch} from "@mui/material";


function AddBillingSplitTag(props) {
    const title = 'Podziel rozliczenia po nazwie';
    const description = 'Tutaj możesz dodać regułę podzialu rozliczenia rachunku dziecka po nazwie, np. Pobyt, Posiłki, Inne';

    const [user] = useContext(AuthContext);
    const [tag, setTag] = useState('');
    const [discount, setDiscount] = useState(0);

    function _send() {
        const formData = {
            tag: tag,
            discount: discount
        };
        fetch(`/api/member/billing/split/${props.data.id}/tag`, {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess(data);
                props.dismiss();
            })
            .catch(err => { console.log(err) })
    }

    return (<StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss}>
                <MDInput
                    onChange={(e) => setTag(e.target.value)}
                    label='Nazwa'
                    value={tag}
                    variant="standard"
                    fullWidth
                    sx={{marginBottom: 2}}
                />
                <MDInput
                    onChange={(e) => setDiscount(e.target.value)}
                    label='Rabat'
                    fullWidth
                    value={discount}
                    type={'number'}
                    inputProps={{min: 0, max: 100}}
                    InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
                    disabled
                />
        </StyledModal>);
}
export default AddBillingSplitTag;
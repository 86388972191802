import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../services/AuthContext";
import {useOutletContext, useParams} from "react-router-dom";
import ActionButton from "../../../StyledComponents/ActionButton";
import {Add, Email, FileDownload, MoreTime, Refresh, RequestPage, Snooze, TextSnippet} from "@mui/icons-material";
import {DetailsList, SelectionMode} from "@fluentui/react";
import {Button} from "@mui/material";
import GenerateBilling from "../../../Panels/Settlement/GenerateBilling";
import GenerateExpectedBilling from "../../../Panels/Settlement/GenerateExpectedBilling";
import MDTypography from "../../../components/MDTypography";
import ChangeDeadline from "../../../Panels/Settlement/ChangeDeadline";
import currency from "currency.js";
import IconButton from "@mui/material/IconButton";
import {CSVDownloader} from "react-papaparse";

function Billings() {
    const month = useOutletContext();
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [billings, setBillings] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [summary, setSummary] = useState('');
    const [generateBilling, setGenerateBilling] = useState(false);
    const [generateExpectedBilling, setGenerateExpectedBilling] = useState(false);
    const [changeDeadline, setChangeDeadline] = useState(false);

    function _recalculate(id) {
        fetch(`/api/facility/billing/${id}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1);
            })
            .catch(err => { console.log(err) })
    }

    function _makeButtons(item) {
        const email = item.email !== null ? <ActionButton color='info' title='Email' icon={<Email />} href={`mailto:${item.email}`}/> : '';
            return (<div>
                {email}
                <ActionButton color='success' title='Rachunek' icon={<RequestPage />} href={`/api/report/facility/${params.id}/billing/${item.id}?auth=${user.token}`} target={'_blank'} />
                <ActionButton color='info' title='Rachunek Krótki' icon={<TextSnippet />} href={`/api/report/facility/${params.id}/billing/${item.id}/short?auth=${user.token}`} target={'_blank'} />
                <ActionButton color='warning' icon={<Refresh />} onClick={() => _recalculate(item.id)} />
            </div>);
    }

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/billing/${month}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setBillings(data);
                    const saldo = data.reduce((c,v) => c.add(currency(v.real_value ?? v.value)), currency(0, {
                        pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
                    }));
                    setSummary(<MDTypography textAlign={'right'} fontWeight={'light'} variant={'h4'}>Razem: {saldo.format()} <CSVDownloader data={data} filename={`rachunki_${month}`} bom={true} config={{delimiter: ';'}}><IconButton variant={'text'}><FileDownload /></IconButton></CSVDownloader></MDTypography>);
                })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, month, params.id, refresh]);

    const columns = [
        {
            key: 'member',
            name: 'Dziecko',
            ariaLabel: 'Dziecko',
            fieldName: 'member',
            minWidth: 200,
            isResizable: true,
            isRowHeader: true,
            onRender: (item) => item.split !== null ? `${item.member} (${item.split})` : item.member
        },
        {
            key: 'department',
            name: 'Oddział',
            ariaLabel: 'Oddział',
            fieldName: 'department',
            minWidth: 140,
            isResizable: true,
        },
        {
            key: 'due',
            name: 'Termin',
            ariaLabel: 'Termin',
            fieldName: 'due',
            minWidth: 90,
            isResizable: true,
            isCollabsable: true
        },
        {
            key: 'absence',
            name: 'Nieobecny',
            ariaLabel: 'Nieobecny',
            fieldName: 'absence',
            minWidth: 70,
            isResizable: true,
        },
        {
            key: 'value',
            name: 'Wartość',
            ariaLabel: 'Wartość',
            fieldName: 'value',
            minWidth: 70,
            isResizable: true
        },
        {
            key: 'real_value',
            name: 'Obliczone',
            ariaLabel: 'Obliczone',
            fieldName: 'real_value',
            minWidth: 70,
            isResizable: true
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 150,
            onRender: _makeButtons
        }
    ];
    return (
        <div>
            <Button onClick={() => setGenerateBilling({facility: params.id, month: month})}>
                <Add /> Generuj Rachunki</Button>
            <Button onClick={() => setGenerateExpectedBilling({facility: params.id, month: month})}>
                <MoreTime /> Generuj Przewidywane Rachunki</Button>
            <Button onClick={() => setChangeDeadline({facility: params.id, month: month})}>
                <Snooze /> Zmień Termin Płatności</Button>
            <Button title='PDF' href={`/api/report/facility/${params.id}/billing/${month}?auth=${user.token}`} target={'_blank'}>
                <FileDownload /> Raport </Button>
            {summary}
            <GenerateBilling data={generateBilling} dismiss={() => setGenerateBilling(false)} onSuccess={() => setRefresh(refresh + 1)} />
            <GenerateExpectedBilling data={generateExpectedBilling} dismiss={() => setGenerateExpectedBilling(false)} onSuccess={() => setRefresh(refresh + 1)} />
            <ChangeDeadline data={changeDeadline} dismiss={() => setChangeDeadline(false)} onSuccess={() => setRefresh(refresh + 1)} />
            <DetailsList selectionMode={SelectionMode.none} items={billings} columns={columns} />
        </div>
    );
}

export default Billings;
import {
  Bookmark,
  Business,
  ChildCare,
  FamilyRestroom,
  Dashboard,
  FoodBank,
  Label,
  Restaurant,
  SetMeal,
  Tag, UploadFile
} from "@mui/icons-material";

var dashRoutes = [
  {
    route: "dashboard",
    key: "dashboard",
    name: "Kokpit",
    icon: <Dashboard />,
    type: 'header'
  },
  {
    type: "header",
    route: "diet",
    key: "diet",
    name: "Diety",
    icon: <SetMeal />,
    requirement: ['MANAGER','ADMIN']
  },
  {
    type: "header",
    route: "meal",
    key: "meal",
    name: "Posiłki",
    icon: <Restaurant />,
    requirement: ['ADMIN']
  },
  {
    type: "header",
    route: "tags",
    key: "tags",
    name: "Tagi",
    icon: <Bookmark />,
    requirement: ['ADMIN']
  },
  {
    type: "header",
    route: "contractor",
    key: "contractor",
    name: "Kontrahenci",
    icon: <Business />,
    requirement: ['ADMIN']
  },
  {
    type: "header",
    route: "import",
    key: "import",
    name: "Import",
    icon: <UploadFile />,
    requirement: ['ADMIN']
  },
];
export default dashRoutes;

import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../../services/AuthContext";
import {Link, useOutletContext, useParams} from "react-router-dom";
import ActionButton from "../../../StyledComponents/ActionButton";
import AddFacilityCost from "../../../Panels/Settlement/AddFacilityCost";
import {Add, Check, CheckBoxOutlined, Delete, Edit, PictureAsPdf} from "@mui/icons-material";
import MDButton from "../../../components/MDButton";
import {DetailsList, SelectionMode} from "@fluentui/react";
import {Button} from "@mui/material";

function Costs() {
    const month = useOutletContext();
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [costs, setCosts] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [addCost, setAddCost] = useState(false);

    function _delete(id) {
        fetch(`/api/facility/cost/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setCosts( costs.filter(item => item.id !== id));
            })
            .catch(err => { console.log(err) })
    }
    function _makeButtons(id) {
            return (<div>
                <ActionButton disabled icon={<Edit />}/>
                <ActionButton color={'error'} onDoubleClick={() => _delete(id)} icon={<Delete />} />
            </div>);
    }

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/cost/${month}`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => { setCosts(data) })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, month, params.id, refresh]);

    function _updatePaid(id, val) {
        let updated = [...costs];
        updated.find(item => item.id === id).paid = val;
        setCosts(updated);
        //TODO: update DB with API CALL
    }

    const _details = (item) => {
        let button = (<MDButton fullWidth>{item.invoice_number}</MDButton>);
        if (item.done === '100.00%') {
            button = (<MDButton fullWidth color={'success'} variant={'contained'}>{item.invoice_number}</MDButton>);
        } else if (item.done !== '0.00%') {
            button = (<MDButton fullWidth color={'warning'} variant={'contained'}>{item.invoice_number}</MDButton>);
        }
        return (<Link to={`${item.id}`}>{button}</Link>);
    }

    function _isPaid(paid, id) {
        if (paid === 0) {
            return (<ActionButton onClick={() => _updatePaid(id, 1)} icon={<CheckBoxOutlined />} />);
        }
        return (<ActionButton color={"success"} onDoubleClick={() => _updatePaid(id, 0)} icon={<Check />} />);
    }

    function _file(item) {
        if (item.filename !== null) {
            return (<ActionButton onClick={() => _downloadFile(item.id)} icon={<PictureAsPdf />} />);
        }
        return null;
    }
    const _downloadFile = (id) => {
        let form = document.createElement('form')
        form.method = 'post'
        form.target = '_blank'
        form.action = `/file/facility/cost/${id}`
        form.innerHTML = '<input type="hidden" name="bearer" value="' + user.token + '">'
        document.body.appendChild(form)
        form.submit()
        document.body.removeChild(form)
    }

    const columns = [
        {
            key: 'invoice_number',
            name: 'Numer Faktury',
            ariaLabel: 'Numer Faktury',
            fieldName: 'invoice_number',
            minWidth: 150,
            maxWidth: 300,
            isResizable: true,
            isRowHeader: true,
            onRender: _details
        },
        {
            key: 'date',
            name: 'Data',
            ariaLabel: 'Data',
            fieldName: 'date',
            maxWidth: 60,
            isResizable: true,
        },
        {
            key: 'contractor',
            name: 'Kontrahent',
            ariaLabel: 'Kontrahent',
            fieldName: 'contractor',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'value',
            name: 'Wartość',
            ariaLabel: 'Wartość',
            fieldName: 'value',
            minWidth: 150,
            isResizable: true,
        },
        {
            key: 'note',
            name: 'Opis',
            ariaLabel: 'Opis',
            fieldName: 'note',
            minWidth: 150,
            isResizable: true,
        },
        {
            key: 'done',
            name: 'Rozliczono',
            ariaLabel: 'Rozliczono',
            fieldName: 'done',
            minWidth: 80,
            isResizable: true,
        },
        {
            key: 'paid',
            name: 'Opłacono',
            ariaLabel: 'Opłacono',
            fieldName: 'paid',
            minWidth: 80,
            isResizable: true,
            onRender: (item) => _isPaid(item.paid, item.id)
        },
        {
            key: 'file',
            name: 'Plik',
            ariaLabel: 'Plik',
            fieldName: 'paid',
            minWidth: 60,
            isResizable: true,
            onRender: _file
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 120,
            onRender: (item) => _makeButtons(item.id)
        }
    ];

    return (
        <div>
            <Button onClick={() => setAddCost(true)}><Add/> Dodaj Fakturę</Button>
            <AddFacilityCost add={addCost} dismiss={() => setAddCost(false)} onSuccess={() => setRefresh(refresh + 1)} />
            <DetailsList selectionMode={SelectionMode.none} items={costs} columns={columns}/>
        </div>
    );
}

export default Costs;
import {Grid} from "@mui/material";
import {NavLink, useParams} from "react-router-dom";
import MDBox from "../../components/MDBox";
import logo from "../../logo.png";
import React, {useEffect, useState} from "react";

function Food({slides}) {
    const params = useParams();

    return (<div className={'slider'} style={{width: `calc(100%/${slides})`}}>
        <Grid container height="100%" justifyContent="center" alignItems="center">
            <Grid container justifyContent="center" alignItems="center" sx={{zoom: '2'}}>
                <Grid container justifyContent="center" alignItems="center">
                    <h1 style={{height: '10vh', textAlign: 'center'}}>Jadłospis</h1>
                </Grid>
                <Grid item sx={{zIndex: 100, textAlign: 'center'}}>
                    {/*<h2>Śniadanie</h2>
                    <h4>Kanapka: pieczywo mieszane, masło, pasta rybna, biały ser, rzodkiewka, szczypiorek</h4>
                    <h4>Napój: herbata rumiankowa</h4>
                    <h2>II Śniadanie</h2>
                    <h4>Sok marchewkowy, herbatniki Be Be</h4>
                    <h2>Obiad</h2>
                    <h4>Zupa jarzynowa z fasolką szparagową</h4>
                    <h4>Ryż słodko-kwaśny</h4>*/}
                </Grid>
            </Grid>
        </Grid>
    </div>);
}

export default Food;
import React, {useContext, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

import AuthContext from "../services/AuthContext";
import {Stack, ThemeProvider} from "@fluentui/react";
import FooterMobile from "../views/Navbar/FooterMobile";

function MemberLayout() {
  const [user, setUser] = useContext(AuthContext);
  const [margin, setMargin] = useState('0');
  const navigate = useNavigate();
  const appTheme = {
  };
  useEffect(() => {
    if (user.token === "") {
      navigate("/login");
    }
  }, [user.token]);

  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);



  return (<ThemeProvider theme={appTheme}>
          <Stack vertical style={{marginLeft: margin, width: 'calc(100vw - ' + margin + ')', boxSizing: 'border-box', padding: '2vh 2vw 52px 2vw', minHeight: '100vh', transition: 'margin 0.25s ease 0s, width 0.25s ease 0s'}}>
            <Outlet />

          </Stack>
        <FooterMobile logout={()=> setUser({username: "", token: "", facilities: [], members: []})}/>
      </ThemeProvider>
  );
}

export default MemberLayout;

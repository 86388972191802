import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../services/AuthContext";
import Title from "../../Component/Title";
import {NavLink, Outlet, useLocation, useNavigate, useParams} from "react-router-dom";
import {ButtonGroup, Grid, Tab, Tabs} from "@mui/material";
import currency from "currency.js";
import MDTypography from "../../components/MDTypography";
import MDButton from "../../components/MDButton";
import {Bolt, ContentCopy, ExpandLess, ExpandMore, Payment} from "@mui/icons-material";
import Snackbar from "@mui/material/Snackbar";

function Finance() {
    const [user] = useContext(AuthContext);
    const params = useParams();
    const navigate = useNavigate();
    const [billing, setBilling] = useState([]);
    const [payments, setPayments] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [showBillingInfo, setShowBillingInfo] = useState(false);
    const [snack, setSnack] = useState(false);
    const {pathname} = useLocation();
    const selectedTab = pathname.substring(pathname.lastIndexOf('/') + 1) === 'finances' ? 'billings' : pathname.substring(pathname.lastIndexOf('/') + 1);
    useEffect(() => {
        const abortController = new AbortController();

        async function fetchData() {
            await fetch(`/api/user/member/${params.id}/finances`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setBilling(data['billings']);
                    setPayments(data['payments']);
                })
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [user, params.id, refresh]);
    const bills = billing.reduce((c, v) => c.add(currency(v.real_value ?? v.value)), currency(0, {
        pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
    }));
    const paid = payments.reduce((c, v) => c.add(currency(v.value)), currency(0, {
        pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
    }));
    const left = bills.subtract(paid);

    const _autopay = () => {
        return fetch(`/api/user/member/${params.id}/autopay`, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.text())
            .then(data => {
                localStorage.setItem('auth', JSON.stringify(user));
                location.href = data;
            })
            .catch(err => {
                console.log(err)
            })
    }

    let paymentBox = '';
    if (left.value == 0) {
        paymentBox = (<MDTypography color={'secondary'}>Opłacono</MDTypography>);
    } else if (left < 0) {
        paymentBox = (<>
            <Grid item><MDTypography color={'success'}>Nadpłata</MDTypography></Grid>
            <Grid item><MDTypography color={'success'}>{left.format({
                decimal: ',',
                negativePattern: '# !'
            })}</MDTypography></Grid>
        </>);
    } else {
        paymentBox = (<>
            <Grid item><MDTypography color={'error'}>Do zapłaty </MDTypography></Grid>
            <Grid item><MDTypography color={'error'}>{left.format({decimal: ','})}</MDTypography> </Grid>
                    <ButtonGroup>
                        <MDButton variant={'contained'} color={'info'} startIcon={<Bolt/>} onClick={_autopay}>Szybki
                            Przelew</MDButton>
                        <MDButton variant={'contained'} color={'info'} disabled startIcon={<Payment/>}>Płatość
                            Cykliczna</MDButton>
                    </ButtonGroup>
                    <MDButton variant={'text'} onClick={() => setShowBillingInfo(p => !p)}
                              color={showBillingInfo ? 'info' : 'secondary'}
                              endIcon={showBillingInfo ? <ExpandLess/> : <ExpandMore/>}>Dane do przelewu</MDButton>
        </>);
    }
    const _copyAccount = () => {
        navigator.clipboard.writeText(billing[0]['account']);
        setSnack(true);
    }
    const billingInfo = showBillingInfo ? (<dl>
        <dt><strong>{billing[0]['facility']}</strong></dt>
        <dd><strong>{billing[0]['facility_address']}</strong></dd>
        <dt>Numer Konta</dt>
        <dd>{billing[0]['account']} <MDButton variant={'text'} color={'info'} iconOnly={true}
                                              onClick={_copyAccount}><ContentCopy/></MDButton></dd>
    </dl>) : '';

    return (
        <>
            <Title title=''
                   subtitle={<Grid container rowGap={2} justifyContent={'space-around'} alignItems={'center'} mb={2}>
                       {paymentBox}
                       {billingInfo}
                   </Grid>} memberMode={true}/>
            <Tabs value={selectedTab} role="navigation">
                <Tab label="Rachunki" component={NavLink} to={'billings'} value={'billings'}/>
                <Tab label="Wpłaty" component={NavLink} to={'payments'} value={'payments'}/>
                <Tab label="Raport" component={'a'} href={`/api/report/member/${params.id}/balance?auth=${user.token}`}
                     target={'_blank'}/>
            </Tabs>
            <Outlet context={{refresh: () => setRefresh((p) => p + 1), billings: billing, payments: payments}}/>
            <Snackbar
                open={snack}
                autoHideDuration={2000}
                onClose={() => setSnack(false)}
                message="Skopiowano numer konta!"
            />
        </>
    );
}

export default Finance;
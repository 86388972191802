/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import {Link, useNavigate} from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Images
import bgImage from "splash2.jpg";
import React, {useContext, useEffect, useState} from "react";
import SplashScreen from "../../Layout/SplashScreen";
import {Alert, FormControlLabel} from "@mui/material";
import AuthContext from "../../services/AuthContext";
import MDAlert from "../../components/MDAlert";

export default function RegisterPage() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordAgain, setPasswordAgain] = useState("");
  const [check, setCheck] = useState(false);
  const [flashMessage, setFlashMessage] = useState("");
  const [flash, setFlash] = useState("");

  useEffect(() => {
    if (flashMessage !== '') {
      if(flashMessage === 'success') {
        setFlash(<Alert severity="success">Utworzono konto w systemie. Sprawdź swój email by dokończyć jego aktywację.</Alert>);
      } else if (flashMessage === 'warning') {
        setFlash(<Alert severity={'warning'}>Podane konto już istnieje. <br />Jeśli nie pamiętasz hasła <Link to={'/reset'}>zresetuj je</Link>.</Alert>);
      } else if (flashMessage === 'quota') {
        setFlash(<Alert severity={'warning'}>Przekroczono limit wysłanych maili aktywacyjnych. Sprawdź swoją skrzynkę pocztową.</Alert>);
      } else {
        setFlash(<Alert severity={'error'}>Żadna z obsługiwanych przez nas placówek nie posiada twoich danych. Skontaktuj się ze swoją placówką by uzupełniono twoje dane.</Alert>);
      }
    } else {
      setFlash('');
    }
  }, [flashMessage]);
  const registerUser = (e) => {
    e.preventDefault();
    const sentData = {username: username, password: password};
    if (password !== passwordAgain) {
      setFlash(<Alert severity={'warning'}>Podane hasła się nie zgadzają.</Alert>);
      return null;
    }
    if (!check) {
      setFlash(<Alert severity={'warning'}>Musisz zaakceptować politykę prywatności i regulamin.</Alert>);
      return null;
    }
    fetch("/api/user/register", {
      cache: "no-cache",
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sentData),
    })
        .then((response) => {
          if (!response.ok) {
            throw Error(response.statusText);
          }
          response.json().then(function (data) {
            setFlashMessage(data);
          });
        })
        .catch((error) => {
          console.log(error.message);
        });
  };

  const privacy = (<>
      <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
      >
        &nbsp;&nbsp;Zapoznałem się i akceptuję&nbsp;
      </MDTypography>
  <MDTypography
      component="a"
      href="https://kidplace.pl/polityka-prywatnosci"
      target={'_blank'}
      variant="button"
      fontWeight="bold"
      color="info"
      textGradient
  >
    Politykę prywatności
  </MDTypography>
  <MDTypography
      variant="button"
      fontWeight="regular"
      color="text"
      sx={{ cursor: "pointer", userSelect: "none"}}
  >
    &nbsp;i&nbsp;
  </MDTypography>
  <MDTypography
      component="a"
      href="https://kidplace.pl/regulamin"
      target={'_blank'}
      variant="button"
      fontWeight="bold"
      color="info"
      textGradient
  >
    Regulamin
  </MDTypography>
      </>
  );
  return (
    <SplashScreen background={bgImage} title={'Kidplace'}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h5" fontWeight="light" color="white">
            Załóż konto rodzica
          </MDTypography>
        </MDBox>
        {flash}
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={registerUser}>
            <MDBox mb={2}>
              <MDInput type="email" required label="Email" variant="standard" fullWidth autoComplete={'email'}
                       value={username} onChange={(e) => setUsername(e.target.value)}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" required label="Hasło" variant="standard" fullWidth autoComplete={'new-password'}
                       value={password} onChange={(e) => setPassword(e.target.value)}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" required label="Powtórz Hasło" variant="standard" fullWidth autoComplete={'off'}
                       value={passwordAgain} onChange={(e) => setPasswordAgain(e.target.value)}/>
            </MDBox>
            <MDBox display="flex" alignItems="center" ml={0}>
              <FormControlLabel control={<Checkbox required checked={check}
                                                   onChange={(e) => setCheck(event.target.checked)}
              />} label={privacy} value={check} />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="info" fullWidth type={'submit'}>
                Załóż konto
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              <MDTypography variant="button" color="text">
                Masz już konto?{" "}
                <MDTypography
                  component={Link}
                  to="/login"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Zaloguj się
                </MDTypography>
              </MDTypography>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </SplashScreen>
  );
}

import React, {useContext, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import StyledSelect from "../../StyledComponents/StyledSelect";
import AuthContext from "../../services/AuthContext";

function FacilitySelect({memberMode}) {
    const params = useParams();
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const [user] = useContext(AuthContext);
    let urlPost = pathname.replace("/facility/", "");
    urlPost = urlPost.indexOf('/') === 0 || urlPost.substring(urlPost.indexOf('/')).indexOf('/department') === 0 ? '/dashboard' : urlPost.substring(urlPost.indexOf('/'));

    const [facility, setFacility] = useState(params.id);
    const [url, setUrl] = useState('/facility/');
    const [facilities, setFacilities] = useState([]);
    const facilityExtractor = (item) => {return {id: item?.id, name: `${item?.city} - ${item?.name}`}};
    const memberExtractor = (item) => {return {id: item?.id, name: `${item?.name} ${item?.surname}`}};
    const [extractor, setExtractor] = useState(facilityExtractor);

    useEffect(() => {
        if (memberMode) {
            setUrl('/member/');
            setFacilities(user.members.map(memberExtractor));
        } else {
            setUrl('/facility/');
            setFacilities(user.facilities.map(facilityExtractor));
        }
    }, [memberMode]);
    if (facilities.length > 0) {
        return (
            <StyledSelect
                value={facility}
                items={facilities}
                onChange={(e, v) => {
                    setFacility(v);
                    navigate(`${url}${v.id}${urlPost}`);
                }}
            />);
    } else {
        return null;
    }

}

export default FacilitySelect;

import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import {useParams} from "react-router-dom";
import StyledModal from "../../StyledComponents/StyledModal";
import MDInput from "../../components/MDInput";
import TimeFlatpicker from "../../Component/TimeFlatpicker";
import dbTime from "../../Utilities/dbTime";
import {FormControlLabel, Switch} from "@mui/material";
import DateInput from "../../StyledComponents/DateInput";
import dbDate from "../../Utilities/dbDate";


function EditFacilityMeal(props) {
    const title = 'Edytuj Posiłek Placówki';
    const description = 'Tutaj możesz edytować dane posiłku placówki.';

    const [user] = useContext(AuthContext);
    const params = useParams();

    const [cost, setCost] = useState('');
    const [futureCost, setFutureCost] = useState('');
    const [costUpdate, setCostUpdate] = useState(null);
    const [paidOnAbsence, setPaidOnAbsence] = useState(true);
    const [time, setTime] = useState('');

    function _send() {
        const formData = {
            expected_cost: cost,
            future_cost: futureCost,
            cost_update: dbDate(costUpdate),
            paid_on_absence: paidOnAbsence,
            time: dbTime(time)
        };
        fetch(`/api/facility/${params.id}/meal/${props.data.id}`, {
            method: 'PUT',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        setPaidOnAbsence(props.data.paid_on_absence);
        setTime(props.data.time);
        setCost(props.data.expected_cost);
        setFutureCost(props.data.future_cost);
        setCostUpdate(props.data.cost_update);
    }, [props.data])
    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss} actionLabel={'Edytuj'}>
            <MDInput
                onChange={(e) => setCost(e.target.value)}
                label='Koszt posiłku'
                fullWidth
                sx={{marginBottom: 2}}
                value={cost}
                type={'number'}
                min={0}
            />
            <TimeFlatpicker label={'Godzina'} value={time} onChange={(e) => setTime(e)} />
            <FormControlLabel
                control={
                    <Switch
                        checked={paidOnAbsence}
                        onChange={(event) => setPaidOnAbsence(event.target.checked)}
                        value="paidOnAbsence"
                    />
                }
                label="Płatny przy nieobecności nieplanowanej"
            />
            <MDInput
                onChange={(e) => setFutureCost(e.target.value)}
                label='Przyszły Koszt'
                fullWidth
                sx={{marginBottom: 2}}
                value={futureCost}
                type={'number'}
                min={0}
            />
            <DateInput label={'Nowa cena od'} value={costUpdate} onChange={(e) => setCostUpdate(e)} sx={''}/>
        </StyledModal>
    );
}

export default EditFacilityMeal;
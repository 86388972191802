import React, {useContext, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import MonthPicker from "../../Component/MonthPicker";
import dbMonth from "../../Utilities/dbMonth";

function GenerateExpectedBillingForMember(props) {
    const title = 'Generowanie Przewidywanych Rachunków';
    const description = 'Czy na pewno chcesz wygenerować rachunek przewidywany dla wybranego dziecka? ' +
        'Ponowne generowanie rachunku przewidywanego zastąpi istniejące rachunki w danym miesiącu.';

    const [user] = useContext(AuthContext);
    const [month, setMonth] = useState(null);

    function _send() {
        fetch(`/api/facility/${props.data.facility}/billing/${dbMonth(month)}/expected/${props.data.member}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss} actionLabel={'Generuj'} actionColor={'primary'}>
            <MonthPicker value={month} onChange={(e) => setMonth(e)}/>
        </StyledModal>
    );
}

export default GenerateExpectedBillingForMember;
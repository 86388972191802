import MDTypography from "../../../../components/MDTypography";
import React, {useContext, useEffect, useState} from "react";
import {useOutletContext, useParams} from "react-router-dom";
import AuthContext from "../../../../services/AuthContext";
import {DetailsList, SelectionMode, StackItem, Stack} from "@fluentui/react";
import {Button} from "@mui/material";
import {
    Add,
    Edit,
    Email,
    Refresh,
    RequestPage,
    TextSnippet,
    MoreTime,
    Delete,
    FileDownload,
    OpenWith
} from "@mui/icons-material";
import EditExtras from "../../../../Panels/Member/EditExtras";
import AddIncome from "../../../../Panels/Settlement/AddIncome";
import ActionButton from "../../../../StyledComponents/ActionButton";
import MDButton from "../../../../components/MDButton";
import GenerateExpectedBillingForMember from "../../../../Panels/Settlement/GenerateExpectedBillingForMember";
import BillingSplit from "./Finance/BillingSplit";
import currency from "currency.js";
import MoveIncome from "../../../../Panels/Settlement/MoveIncome";

function MemberFinances() {
    const {setSummary} = useOutletContext();
    const params = useParams();
    const [user] = useContext(AuthContext);
    const [payments, setPayments] = useState([]);
    const [billings, setBillings] = useState([]);
    const [extras, setExtras] = useState([]);
    const [refresh, setRefresh] = useState(0);
    const [canModify, setCanModify] = useState(false);
    const [editExtras, setEditExtras] = useState(false);
    const [addPayment, setAddPayment] = useState(false);
    const [moveIncome, setMoveIncome] = useState(false);
    const [mode, setMode] = useState("billings");
    const [generateExpectedBilling, setGenerateExpectedBilling] = useState(false);

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/facility/${params.id}/member/${params.member}/finance`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => {
                    setPayments(data.payments);
                    setBillings(data.billing);
                    setExtras(data.unasigned);
                    setCanModify(data.canModify);
                    const saldo = data.payments.reduce((c, v) => c.add(currency(v.value)), currency(0, {
                        pattern: `# !`, negativePattern: `-# !`, symbol: 'zł', separator: ' '
                    }))
                        .subtract(data.billing.reduce((c,v) => c.add(currency(v.real_value ?? v.value)), currency(0)));
                    const color = saldo >= 0 ? 'success' : 'error';
                    setSummary(<MDTypography color={color} textAlign={'right'} fontWeight={'light'} variant={'h4'}>Saldo: {saldo.format()}</MDTypography>);
                })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, params.id, params.member, refresh]);

    function _recalculate(id) {
        fetch(`/api/facility/billing/${id}`, {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1);
            })
            .catch(err => { console.log(err) })
    }

    function _deletePayment(id) {
        fetch(`/api/facility/income/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1);
            })
            .catch(err => { console.log(err) })
    }

    function _deleteBilling(id) {
        fetch(`/api/facility/billing/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1);
            })
            .catch(err => { console.log(err) })
    }

    function _makeButtons(item) {
        if (canModify) {
            return (<div>
                <Button color="success" onClick={() => {setEditExtras(item);}}>
                    <Edit />
                </Button>
            </div>);
        }
        return null;
    }

    function _makePaymentButtons(item) {
        if (canModify) {
            if (item.hash === null) {
                return (<div>
                    <ActionButton color='error' icon={<Delete />} onDoubleClick={() => _deletePayment(item.id)} />
                </div>);
            } else {
                return (<div>
                    <ActionButton color='warning' icon={<OpenWith/>} onClick={() => setMoveIncome(item.id)}/>
                </div>);
            }
        }
        return null;
    }

    function _makeBillingsButtons(item) {
        const email = item.email !== null ? <ActionButton color='info' title='Email' icon={<Email />} href={`mailto:${item.email}`}/> : '';
        const billing = item.real_value !== null
            ? <>
                <ActionButton color='success' title='Rachunek' icon={<RequestPage />} href={`/api/report/facility/${params.id}/billing/${item.id}?auth=${user.token}`} target={'_blank'} />
                <ActionButton color='info' title='Rachunek Krótki' icon={<TextSnippet />} href={`/api/report/facility/${params.id}/billing/${item.id}/short?auth=${user.token}`} target={'_blank'} />
            </> :
            <>
                <ActionButton color='info' title='Rachunek Krótki' icon={<TextSnippet />} href={`/api/report/facility/${params.id}/billing/${item.id}/short?auth=${user.token}`} target={'_blank'} />
                <ActionButton color='error' title='Usuń' icon={<Delete />} onDoubleClick={() => _deleteBilling(item.id)} />
            </>
        return (<div>
            {email}
            {billing}
            <ActionButton color='warning' icon={<Refresh />} onClick={() => _recalculate(item.id)} />
        </div>);
    }

    function _extrasModified(item) {
        if (item.modified !== null) {
            return (<MDTypography sx={{fontSize: '12px'}} color={'warning'} title={`${item.modified} ${item.user}`}>{item.timestamp}</MDTypography>);
        }
        return item.timestamp;
    }

    const columnsPayments = [
        {
            key: 'date',
            name: 'Data',
            ariaLabel: 'Data',
            fieldName: 'date',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'value',
            name: 'Wpłata',
            ariaLabel: 'Wpłata',
            fieldName: 'value',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'note',
            name: 'Opis',
            ariaLabel: 'Opis',
            fieldName: 'note',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 90,
            isResizable: false,
            onRender: _makePaymentButtons
        }
    ];

    const columnsBilling = [

        {
            key: 'date',
            name: 'Data',
            ariaLabel: 'Data',
            fieldName: 'date',
            minWidth: 100,
            isResizable: true,
            isRowHeader: true
        },
        {
            key: 'split',
            name: 'Podział',
            ariaLabel: 'Podział',
            fieldName: 'split',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'due',
            name: 'Termin Płatności',
            ariaLabel: 'Termin Płatności',
            fieldName: 'due',
            minWidth: 140,
            isResizable: true,
        },

        {
            key: 'value',
            name: 'Wartość',
            ariaLabel: 'Wartość',
            fieldName: 'value',
            minWidth: 200,
            isResizable: true
        },
        {
            key: 'real_value',
            name: 'Obliczono',
            ariaLabel: 'Obliczono',
            fieldName: 'real_value',
            minWidth: 200,
            isResizable: true
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 150,
            isResizable: false,
            onRender: _makeBillingsButtons
        }
    ];

    const columnsExtras = [
        {
            key: 'timestamp',
            name: 'Data',
            ariaLabel: 'Data',
            fieldName: 'timestamp',
            minWidth: 120,
            maxWidth: 120,
            isResizable: false,
            onRender: _extrasModified
        },
        {
            key: 'name',
            name: 'Opis',
            ariaLabel: 'Opis',
            fieldName: 'name',
            minWidth: 100,
            isResizable: true,
        },
        {
            key: 'note',
            name: 'Notatka',
            ariaLabel: 'Notatka',
            fieldName: 'note',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'cost',
            name: 'Wartość',
            ariaLabel: 'Wartość',
            fieldName: 'cost',
            minWidth: 70,
            isResizable: true,
        },
        {
            key: 'action',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'action',
            minWidth: 45,
            onRender: _makeButtons
        }
    ];

    const internalNav = () => {
        const items = [
            {name: "Rachunki", target: 'billings'},
            {name: "Naliczenia", target: 'extras'},
            {name: "Płatności", target: 'payments'},
            {name: "Podział Rozliczeń", target: 'splits'},
        ];
        return items.map((item) => {
            return <MDButton color={mode === item.target ? "dark" : "secondary"} variant={mode === item.target ? 'contained' : 'text'} onClick={() => {
                setMode(item.target);
                document.activeElement.blur();
            }}>
                {item.name}</MDButton>
        });
    }

    const internalOutlet = () => {
        switch (mode) {
            case "billings":
                return (
                    <StackItem grow={2}>
                        <GenerateExpectedBillingForMember data={generateExpectedBilling} dismiss={() => setGenerateExpectedBilling(false)} onSuccess={() => setRefresh(refresh + 1)} />
                        <Button onClick={() => setGenerateExpectedBilling({facility: params.id, member: params.member})}>
                            <MoreTime /> Generuj Przewidywany Rachunek</Button>
                        <Button title='PDF' href={`/api/report/member/${params.member}/balance?auth=${user.token}`} target={'_blank'}>
                            <FileDownload /> Raport Salda </Button>
                        <DetailsList selectionMode={SelectionMode.none} items={billings} columns={columnsBilling} />
                    </StackItem>
                );
            case "extras":
                return (
                    <StackItem grow={2}>
                        <DetailsList selectionMode={SelectionMode.none} items={extras} columns={columnsExtras} />
                    </StackItem>
                );
            case "payments":
                return (
                    <StackItem grow={2}>
                        <Button onClick={() => setAddPayment(params.member)}><Add /> Dodaj Płatność</Button>
                        <DetailsList selectionMode={SelectionMode.none} items={payments} columns={columnsPayments} />
                    </StackItem>
                );
            case "splits":
                return (
                    <BillingSplit grow={2}/>
                );
        }
    }

    return (<Stack horizontal justifyContent={'space-around'} tokens={{childrenGap: 10}}>
        <Stack vertical>
            {internalNav()}
        </Stack>
        {internalOutlet()}
        <EditExtras data={editExtras} dismiss={() => setEditExtras(false)} onSuccess={() => setRefresh(refresh + 1)} />
        <AddIncome isOpen={addPayment} dismiss={() => setAddPayment(false)} onSuccess={() => setRefresh(refresh + 1)} />
        <MoveIncome isOpen={moveIncome} dismiss={() => setMoveIncome(false)} onSuccess={() => setRefresh(refresh + 1)} />
    </Stack>);
}

export default MemberFinances;
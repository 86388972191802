import React, {useContext, useEffect, useState} from "react";
// core components
import AuthContext from "../../services/AuthContext";
import AddDiet from "../../Panels/Diet/AddDiet";
import {Add, Clear} from "@mui/icons-material";
import {Button} from "@mui/material";
import {DetailsList, SelectionMode} from "@fluentui/react";
import IconButton from "@mui/material/IconButton";
import Title from "../../Component/Title";

function Diet() {
    const [user] = useContext(AuthContext);
    const [diet, setDiet] = useState([]);
    const [canModify, setCanModify] = useState(false);
    const [addDiet, setAddDiet] = useState(false);
    const [refresh, setRefresh] = useState(0);

    function _delete(id) {
        fetch(`/api/diet/${id}`, {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(() => {
                setRefresh(refresh + 1)
            })
            .catch(err => { console.log(err) })
    }
    function _makeButtons({id}) {
        return canModify ? (<IconButton color={'error'} onDoubleClick={() => _delete(id)}><Clear /></IconButton>) : null;
    }

    useEffect(() => {
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/diet`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => { setCanModify(data.canModify); setDiet(data.diets) })
                .catch(err => { console.log(err) })
        }
        fetchData();
        return () => abortController.abort();
    },  [user, refresh]);

    const columns = [
        {
            key: 'name',
            name: 'Dieta',
            ariaLabel: 'Dieta',
            fieldName: 'name',
            minWidth: 200,
            isResizable: true,
        },
        {
            key: 'actions',
            name: 'Akcje',
            ariaLabel: 'Akcje',
            fieldName: 'actions',
            minWidth: 80,
            onRender: _makeButtons
        }
    ];

  return (
      <>
          <Title title={'Diety'} />
        {canModify && <Button onClick={() => setAddDiet(true)}><Add /> Dodaj Dietę</Button>}
        <AddDiet isOpen={addDiet} dismiss={() => setAddDiet(false)} onSuccess={() => setRefresh(refresh + 1)} />
        <DetailsList items={diet} columns={columns} selectionMode={SelectionMode.none}/>
      </>
    );
}
export default Diet;
import React, {useContext, useEffect, useState} from 'react';
import AuthContext from "../../services/AuthContext";
import StyledModal from "../../StyledComponents/StyledModal";
import MDInput from "../../components/MDInput";
import {FormControlLabel, InputAdornment, Stack, Switch, ToggleButton, ToggleButtonGroup} from "@mui/material";
import TimeFlatpicker from "../../Component/TimeFlatpicker";
import DateInput from "../../StyledComponents/DateInput";
import StyledSelect from "../../StyledComponents/StyledSelect";
import dbTime from "../../Utilities/dbTime";
import dbDate from "../../Utilities/dbDate";
import {useParams} from "react-router-dom";

function EditMember(props) {
    const title = 'Edytuj Uczestnika';
    const description = 'Tutaj możesz edytować dane uczestnika w grupie.';

    const params = useParams();
    const [user] = useContext(AuthContext);
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [birthday, setBirthday] = useState(new Date());
    const [gender, setGender] = useState('M');
    const [nationality, setNationality] = useState('Polska');
    const [personalId, setPersonalId] = useState('');
    const [note, setNote] = useState('');
    const [discount, setDiscount] = useState(0);
    const [personalTuition, setPersonalTuition] = useState(null);
    const [diet, setDiet] = useState(null);
    const [diets, setDiets] = useState([]);
    const [attendFrom, setAttendFrom] = useState('08:00');
    const [attendTo, setAttendTo] = useState('15:00');
    const [activeFrom, setActiveFrom] = useState(new Date());
    const [activeTo, setActiveTo] = useState(null);
    const [department, setDepartment] = useState(null);
    const [departments, setDepartments] = useState([]);
    const [reassign, setReassign] = useState(false);


    useEffect(() => {
        setName(props.data.name);
        setSurname(props.data.surname);
        setBirthday(props.data.birthday);
        setGender(props.data.gender);
        setNationality(props.data.nationality);
        setPersonalId(props.data.personal_id);
        setNote(props.data.note);
        setDiscount(props.data.discount);
        setDiet(props.data.diet_id);
        setAttendFrom(props.data.attend_from);
        setAttendTo(props.data.attend_to);
        setActiveFrom(props.data.active_from);
        setActiveTo(props.data.active_to);
        setDepartment(props.data.department_id);
        setPersonalTuition(props.data.personal_tuition);
        const abortController = new AbortController();
        async function fetchData() {
            await fetch(`/api/diet`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(data => setDiets(data.diets))
                .catch(err => {
                    console.log(err)
                })
            await fetch(`/api/facility/${params.id}/departments`, {
                method: 'GET',
                signal: abortController.signal,
                headers: {
                    'Authorization': 'Bearer ' + user.token
                }
            }).then(res => res.json())
                .then(setDepartments)
                .catch(err => {
                    console.log(err)
                })
        }

        fetchData();
        return () => abortController.abort();
    }, [props.data])

    useEffect(() => {
        if (dbDate(activeTo) !== '') {
            setReassign(false);
        }
    }, [activeTo])

    function _send() {
        const formData = {
            name: name,
            surname: surname,
            birthday: dbDate(birthday),
            gender: gender,
            nationality: nationality,
            personal_id: personalId,
            note: note,
            discount: discount,
            diet_id: diet.id ?? diet,
            department_id: department.id ?? department,
            personal_tuition: personalTuition,
            attend_from: dbTime(attendFrom),
            attend_to: dbTime(attendTo),
            active_from: dbDate(activeFrom),
            active_to: dbDate(activeTo)
        };
        const edit = props.data.department_member === null || reassign ? 'reassign' : props.data.department_member;
        fetch(`/api/member/${props.data.id}/${edit}`, {
            method: edit === 'reassign' ? 'POST' : 'PUT',
            body: JSON.stringify(formData),
            headers: {
                'Authorization': 'Bearer ' + user.token
            }
        }).then(res => res.json())
            .then(data => {
                props.onSuccess();
                props.dismiss();
            })
            .catch(err => {
                console.log(err)
            })
    }

    return (
        <StyledModal isOpen={props.data} title={title} description={description} send={_send} dismiss={props.dismiss}
                     actionLabel='Edytuj' fullWidth={true} maxWidth={'lg'}>
            {props.data.active_to !== null && <FormControlLabel
                control={
                    <Switch
                        checked={reassign}
                        onChange={(event) => setReassign(event.target.checked)}
                        value="reassign"
                        disabled={dbDate(activeTo) !== ''}
                    />
                }
                label="Nowy Wpis"
            />}
            <StyledSelect
                name='department'
                label='Wydział'
                value={department}
                onChange={(e, v) => setDepartment(v)}
                items={departments}
            />
            <Stack direction={'row'} spacing={1}>

                <Stack spacing={2} flexGrow={1}>
                    <MDInput
                        onChange={(e) => setName(e.target.value)}
                        value={name}
                        label='Imię'
                        fullWidth
                        autoFocus={true}
                    />
                    <MDInput
                        onChange={(e) => setSurname(e.target.value)}
                        value={surname}
                        label='Nazwisko'
                        fullWidth
                    />

                    <MDInput
                        onChange={(e) => setNationality(e.target.value)}
                        value={nationality}
                        label='Narodowość'
                        fullWidth
                    />
                    <DateInput label={'Data Urodzenia'} value={birthday} onChange={(e) => setBirthday(e)}/>
                    <ToggleButtonGroup
                        color="info"
                        value={gender}
                        exclusive
                        onChange={(e, v) => setGender(v)}
                        size={'small'}
                    >
                        <ToggleButton value="M">Chłopiec</ToggleButton>
                        <ToggleButton value="F">Dziewczynka</ToggleButton>
                    </ToggleButtonGroup>
                    <MDInput
                        onChange={(e) => setPersonalId(e.target.value)}
                        value={personalId}
                        label='Pesel'
                        fullWidth
                    />
                </Stack>
                <Stack spacing={2} flexGrow={1}>
                    <Stack direction={'row'} spacing={1}>
                        <TimeFlatpicker sx={''} label={'Deklarowany od'} value={attendFrom}
                                        onChange={(e) => setAttendFrom(e)}/>
                        <TimeFlatpicker sx={''} label={'Deklarowany do'} value={attendTo} onChange={(e) => setAttendTo(e)}/>
                    </Stack>
                    <MDInput
                        onChange={(e) => setDiscount(e.target.value)}
                        label='Rabat za pobyt'
                        fullWidth
                        value={discount}
                        type={'number'}
                        inputProps={{min: 0, max: 100}}
                        InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
                    />
                    <MDInput
                        onChange={(e) => setPersonalTuition(e.target.value)}
                        label='Czesne'
                        fullWidth
                        value={personalTuition}
                        type={'number'}
                        inputProps={{min: 0, step: 0.01}}
                        InputProps={{endAdornment: <InputAdornment position="end">zł</InputAdornment>}}
                    />
                    <DateInput label={'Od dnia'} value={activeFrom} onChange={(e) => setActiveFrom(e)}/>
                    {activeTo !== null && <DateInput label={'Do dnia'} value={activeTo} onChange={(e) => setActiveTo(e)}/>}
                    <StyledSelect
                        name='diet'
                        label='Dieta'
                        value={diet}
                        onChange={(e, v) => setDiet(v)}
                        items={diets}
                        sx={''}
                    />
                </Stack>
            </Stack>
            <MDInput
                onChange={(e) => setNote(e.target.value)}
                label='Notatka'
                fullWidth
                value={note}
                multiline
                rows={3}
                inputProps={{maxlength: 255}}
                sx={{marginTop: '16px'}}
            />
        </StyledModal>
    );
}

export default EditMember;
function dbDate(date) {
    if (date === '' || date === null || (Array.isArray(date) && date.length === 0)) {
        return '';
    }
    const dateObj = new Date(date);
    const month = dateObj.getMonth() < 9 ? '0' + (dateObj.getMonth()+1) : (dateObj.getMonth()+1);
    const day = dateObj.getDate() <= 9 ? '0' + (dateObj.getDate()) : (dateObj.getDate());
    return `${dateObj.getFullYear()}-${month}-${day}`
}

export default dbDate;
import React, {useContext, useEffect, useState} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";

import AuthContext from "../services/AuthContext";
import Sidenav from "Component/Sidenav/Sidenav";

import {setMiniSidenav, useMaterialUIController} from "context";
import logo from "logo.png"
import {Stack, ThemeProvider} from "@fluentui/react";
import jwtDecode from "jwt-decode";
import FooterMobile from "../views/Navbar/FooterMobile";

function App({routes}) {
  const [user, setUser] = useContext(AuthContext);
  const [margin, setMargin] = useState('250px');
  const navigate = useNavigate();
  const appTheme = {
  };
  useEffect(() => {
    if (user.token === "") {
      navigate("/login");
    }
  }, [user.token])

  const [controller, dispatch] = useMaterialUIController();
  const {
    miniSidenav, whiteSidenav, transparentSidenav
  } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const { pathname } = useLocation();

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getActiveRoute = routes => {
    let activeRoute = "Default Brand Text";
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse) {
        let collapseActiveRoute = getActiveRoute(routes[i].views);
        if (collapseActiveRoute !== activeRoute) {
          return collapseActiveRoute;
        }
      } else {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return routes[i].name;
        }
      }
    }
    return activeRoute;
  };
  useEffect(() => {
    setMargin(!whiteSidenav ? '0px' : miniSidenav ? '100px' : '250px');
  }, [whiteSidenav, miniSidenav, transparentSidenav]);

  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
  let filteredRoutes = routes;
  if (user.token !== '') {
    const jwt = jwtDecode(user.token);
    const roles = jwt?.roles.map(item => {
      let role = item.split('_');
      return role.splice(1, role.length - 2).join('_');
    }).filter(onlyUnique);

    filteredRoutes = routes.filter((value, index, array) => {
      if (value?.requirement !== undefined) {
        let passed = 0;
        value?.requirement.forEach(item => {
          if (roles.includes(item)) {
            passed = 1;
            return 1;
          }
        });
        return passed;
      }
      return 1;
    });
  }



  return (<ThemeProvider theme={appTheme}>
          <Sidenav
              brand={logo}
              brandName="Kidplace"
              routes={filteredRoutes}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
              logout={()=> setUser({username: "", token: "", facilities: [], members: []})}
          />
          <Stack vertical style={{marginLeft: margin, width: 'calc(100vw - ' + margin + ')', boxSizing: 'border-box', padding: '2vh 2vw', minHeight: '100vh', transition: 'margin 0.25s ease 0s, width 0.25s ease 0s'}}>
            <Outlet />
            {/*<Footer fluid />*/}
          </Stack>
      </ThemeProvider>
  );
}

export default App;
